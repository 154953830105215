import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router
} from '@angular/router';
import { LoginService } from '@services/login.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService) { }

  canActivate() {
    if (this.loginService.IsLoggedIn()) {
      return true;
    }
    else {
      this.router.navigateByUrl("/login");
      return false;
    }
  }
}
