import { Category } from '@/Model/category';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '@/AddCategory/category.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareimageService } from '@services/shareimage.service';
import { ProfileService } from '@services/profile.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-editCategory',
  templateUrl: './editCategory.component.html',
  styleUrls: ['./editCategory.component.css']
})

export class EditCategoryComponent implements OnInit {
  id!: number;
  categoryId!: any;
  post!: Category;
  isActive: boolean = true;
  categoryform!: FormGroup;
  loading = false;
  selectedUserId: any;
  public isFormSubmitted: boolean = false;
  languageName: any;
  selectedcategoryId:any;
  constructor(public categoryService: CategoryService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private SpinnerService: NgxSpinnerService,
    private shareimageService: ShareimageService,
    private profileservice: ProfileService,
    private translate: TranslateService,
  ) {
    this.translate.setDefaultLang('en');
    this.InitializeCategoryForm();
  }

  ngOnInit(): void {
    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    this.profileservice.getUserById().subscribe((data: any) => {
      if (data.isSuperAdmin == true) {
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
        });
      }
    }, (err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);

    });
    document.getElementById("tempid_Category")?.classList.add("highlight_sideMenu");
    this.shareimageService.setPageName("Categories");
    this.id = this.route.snapshot.params['postId'];
    this.categoryId = this.id;
    this.SpinnerService.show();
    this.categoryService.getCategoryById(this.id).subscribe((data: any) => {
      this.SpinnerService.hide();
      this.post = data;
      this.selectedcategoryId=this.post.categoryId;
      this.selectedUserId = data.userID;
      this.categoryform.controls['categoryName'].setValue(this.post.categoryName);
      this.categoryform.controls['description'].setValue(this.post.description);
      this.categoryform.controls['active'].setValue(this.post.active);
    })
  }

  private InitializeCategoryForm() {
    this.categoryform = new FormGroup({
      categoryName: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      description: new FormControl('', [Validators.maxLength(50)]),
      active: new FormControl(true),
      language: new FormControl('')
    });
  }

  get f() {
    return this.categoryform.controls;
  }
  submit() {

    this.loading = true;
    this.isFormSubmitted = true
    if (this.categoryform.status == 'INVALID') {
      this.loading = false;
      return
    }
    const addCategory = new FormData();
    addCategory.append("categoryName", this.categoryform.value.categoryName);
    addCategory.append("description", this.categoryform.value.description);
    addCategory.append("active", this.categoryform.value.active);
    addCategory.append("id", this.categoryId);
    addCategory.append("categoryId", this.selectedcategoryId);
    addCategory.append("language", this.languageName);
    this.categoryService.update(addCategory).subscribe((res: any) => {
      const translatedMessage = this.translate.instant('toastr.success.updatecategory');
      this.toastr.success(translatedMessage);
      this.redirectToCategory();

    }, (error: any) => {
      this.loading = false;
      const translatedMessage = this.translate.instant('toastr.error');
      this.toastr.error(translatedMessage);

    });
  }

  onCancelClick() {
    this.redirectToCategory();
  }

  redirectToCategory() {
    var uid = this.selectedUserId;
    this.router.navigate(
      ['dashboard/category'],
      { queryParams: { uid } }
    );
  }

  ngOnDestroy(): void {
    document.getElementById("tempid_Category").classList.remove("highlight_sideMenu");
  }

}
