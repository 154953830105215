import { ConfirmationService } from 'primeng/api';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '@services/profile.service';
import { HelperService } from '@/utils/Helper';
import { Table } from 'primeng/table';
import { ShareimageService } from '@services/shareimage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss'],
  providers: [ConfirmationService]
})
export class UserlistComponent implements OnInit {
  data: any;
  searchValue: string = '';
  noRecordMsgFlag: boolean;
  users: any[];
  selectedUser: any;
  isSuperAdmin: boolean = false;
  userId: any;
  searchText = '';
  eventid: string;
  id:any;
  userobject:any;
  pageSize = 10; // Number of items per page
  currentPage = 1; // Current page number
  totalItems: number; // Total number of items
  languageName:any;
  @ViewChild('customSearch') customSearchElement: ElementRef;
  constructor(
    private toastr: ToastrService,
    private confirmationservice: ConfirmationService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private profileservice: ProfileService,
    private helper: HelperService,
    private shareimageService: ShareimageService,
    private translate: TranslateService
    ) {this.translate.setDefaultLang('en'); }

  ngOnInit(): void {
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
      this.translate.use('en');
     }else{
      this.translate.use(this.languageName);
     }
    this.profileservice.getUserById().subscribe((data: any) => {},(err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);
    
  });
    this.shareimageService.setPageName("Users");
    this.profileservice.getNotification().subscribe((data: any) => {
      this.shareimageService.setnotification(data)
      localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
    });

    this.profileservice.getUserById().subscribe((data: any) => {
   
      this.userobject = data;
      this.isSuperAdmin = data.isSuperAdmin;
      if (this.isSuperAdmin === true) {
        this.loadUser();
      }
      else {
        this.router.navigateByUrl("/dashboard");
      }
    },(err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);
    
  });
  
  }
  loadUser() {
    this.SpinnerService.show();
      this.profileservice.GetAllUserList().subscribe((data: any) => {
        if (data == null || data.length == 0) {
          this.data = null;
          this.SpinnerService.hide();
          const translatedMessage = this.translate.instant('toastr.info.user');
          this.toastr.info(translatedMessage);
        } else {
          this.data = data;
          this.SpinnerService.hide();
        }
      }, (err: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
        this.SpinnerService.hide();
      })

  }
  onRefresh(table: Table) {
    table.clear();
    this.customSearchElement.nativeElement.value = "";
    this.loadUser();
  }
  isEmpty(event) {
    if (event.filteredValue.length == 0) {
      this.noRecordMsgFlag = true;
    }
    else {
      this.noRecordMsgFlag = false;
    }
  }
  onAddClick() {
    this.router.navigateByUrl("/dashboard/adduser");
  }

  oneditClick(UserId: any) {
    this.router.navigateByUrl("dashboard/users/" + UserId + "/edituser");
  }

  onRefreshmobile() {
    this.searchText = "";
    this.loadUser();
  }
  pageChanged(event: any) {
    this.currentPage = event;
}
}
