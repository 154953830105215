
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="">
        <h5 class="modal-title" id="exampleModalLongTitle"> 
            
          <div class=" pt-3 pb-3">
          <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
        
        </div>
      </h5>
        <button type="button" class="close btn-close-modal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="header-border  w-100"></div>
      <div class="modal-body text-position">
        <div class="card-body pt-2 term-body">
          <div class="signin-header mb-3">
            <span class="signin">Terms & Conditions</span>
          </div>
          <div>
            <ol start="1">
                <li>
                  Greetings from MartsUp! You agree to be bound by the following terms and conditions (the "Terms of Service") by signing up for a MartsUp.
                </li>
            </ol>
        </div>
        <div>
            <ol start="2">
                <li>
                  If you register for or use a MartsUp service as an individual, "we," "us," "our," and "MartsUp" refer to the relevant MartsUp Contracting Party. If you register for or use a MartsUp service as a business, "you" refers to the company employing the MartsUp User and any of its affiliates. 
                </li>
            </ol>
        </div>
        <div>
            <ol start="3">
                <li>
                  MartsUp is a platform for commerce that helps businesses to streamline their transactions. This platform offers a variety of tools for retailers to create and customise their online stores, sell products across multiple channels (including web, mobile, social media, online marketplaces, and other online locations ("Online Services") and in-person ("POS Services"), manage their products, inventory, and business operations, as well as their marketing and advertising campaigns and interact with their current and potential customers. In these Terms of Service, the term "Service(s)" refers to any such service or services provided by MartsUp. The Terms of Service will also apply to any new features or tools that are included with the present Services.   
                </li>
            </ol>
        </div>
        <div>
            <ol start="4">
                <li>
                  Before creating a MartsUp Account or using any MartsUp Service, you must read, concur with, and accept all the terms and conditions contained in or specifically mentioned in these Terms of Service, including MartsUp's Privacy Policy.  
                </li>
            </ol>
        </div>
        <!-- <div>
            <ol start="5">
                <li>
                    Summaries are provided for convenience only and appear in bold near each section, but these summaries are not legally binding. Please read the Terms of Service, including any document referred to in these Terms of Service, for the complete picture of your legal requirements. By using MartsUp or any MartsUp services, you are agreeing to these terms. Be sure to occasionally check back for updates.&nbsp;
                </li>
            </ol>
        </div> -->
        <div>
          <!-- <ol> -->
            <h6>
              <!-- <b> -->
                Account Terms and Conditions: 
              <!-- </b> -->
            </h6>
          <!-- </ol> -->
      </div>
      <!-- <div>
          <p>
            You are responsible for your Account, the Materials you upload to the MartsUp Service and the operation of your MartsUp Store. If you violate MartsUp’s terms of service we may cancel your service access. If we need to reach you, we will communicate via the Primary Email Address. 
           </p>
    </div> -->
    <div>
      <!-- <ol> -->
        <ol start="1">
          <li>
            You must create a MartsUp account (an "Account") in order to access and utilise the Services. You must give us your full legal name, business address, phone number, a working email address, and any other information specified as necessary in order to complete the registration process for your Account. MartsUp reserves the right to refuse your application for an account or to terminate an active account for any reason. 
          </li>
        </ol>
      <!-- </ol> -->
  </div>
  <div>
      <ol start="2">
        <li>
          To open an Account, you must be older than (i) 18 years, or (ii) at least the age of majority in the country from where you use the Services. 
        </li>
      </ol>
  </div>
  <div>
    <ol start="3">
      <li>
        You attest that you are using any services offered by MartsUp exclusively for business purposes and not for domestic or personal requirements. 
      </li>
  </ol>
  </div>
  <div>
      <ol start="4">
        <li>
          You agree that MartsUp will contact you primarily using the email address you specify when registering an account, or as modified by you from time to time ("Primary Email Address"). You are responsible for keeping track of the Primary Email Address you give MartsUp, and it must be able to send and receive emails. MartsUp can only authenticate your email conversations if they originate from your Primary Email Address. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="5">
        <li>
          You are responsible for keeping your password secure. MartsUp cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password. We may request additional security measures at any time and reserve the right to adjust these requirements at our discretion.
        </li>
    </ol>
  </div>
  <div>
      <ol start="6">
        <li>
          Only MartsUp Users are given technical support with regard to the Services. Contact MartsUp Support with any inquiries you may have regarding the Terms of Service. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="7">
        <li>
          Without MartsUp's prior written consent, you undertake not to use, duplicate, replicate, sell, resell, or exploit any part of the service, use of the service, or access to the service. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="8">
        <li>
          You hereby undertake not to use any tool to activate features or capabilities that are otherwise disabled in the Services, work around, bypass, or circumvent any technical limits imposed by the Services, nor decompile, disassemble, or otherwise reverse engineer the Services.
        </li>
    </ol>
  </div>
  <div>
      <ol start="9">
        <li>
          You consent to not using any robot, crawler, scraper, or other automated method to access the Services or monitor any content or information from the Services. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="10">
        <li>
          You are aware that your Materials may be transmitted without encryption and that doing so may result in (a) transmissions over a variety of networks; and (b) adjustments to conform and adapt to the technological requirements of connecting networks or devices. The term "Materials" refers to Your Trademarks, Copyright Content, any products or services (including their descriptions and prices) that you sell through the Services, as well as any photos, images, videos, graphics, written content, audio files, code, information, and other data that you or your affiliates provide to MartsUp or its affiliates. 
        </li>
    </ol>
  </div>
  <div>
      <h6>
          Store Owner:
      </h6>
  </div>
  
  <div>
      <ol start="1">
        <li>
          The person who registers for the MartsUp Service is often the sole "Store Owner" for that store. The Store Owner is in charge of the Account, is obligated to abide by these Terms of Service, and is in charge of what other people do when they access the Account. Your employer is the Store Owner in charge of your Account if you register on their behalf. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="2">
        <li>
          For the purposes of our Terms of Service, the person opening an Account and registering for the Service will be deemed to be the contractual party ("Store Owner"). This person will also be the only one with permission to use any matching Account we may issue to the Store Owner in connection with the Service. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="3">
        <li>
          Your employer, if you register for the Services on their behalf, will be the Store Owner. If you are registering for the Services on behalf of your employer, you must provide an email address that is provided by your company, and you guarantee that you are authorised to bind your employer to these terms. 
        </li>
    </ol>
  </div>
  <div>
      <h6>
          MartsUp Rights:
      </h6>
  </div>
  <div>
      <ol start="1">
        <li>
          MartsUp has the authority to decide who has access to our services, and we reserve the right to change those services at any moment. Additionally, we have the right to reject or remove Materials from your Store or from any other area of the Services. We might provide your rivals with our services, but we'll never give them access to any of your private information. If there is a disagreement regarding who the true owner of a MartsUp Account is, we reserve the right to either freeze the account or transfer it to that person. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="2">
        <li>
          There are several features and functionalities available with the Services. We are not required to make any Services or Features available in any jurisdiction, and not all Services or Features will be offered to all Merchants at all times. We reserve the right to modify the Services or any portion of them at any time, for any reason, without prior notice, and unless where prohibited by these Terms of Service or by applicable legislation. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="3">
        <li>
          MartsUp does not pre-screen Materials, and we reserve the right to reject or remove any Materials from the Services at any time for any reason, including if we feel that the products or services you offer through the Services, or the Materials uploaded or posted to the Services, violate our terms. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="4">
        <li>
          Any verbal or written abuse (including threats of abuse or retaliation) directed against any MartsUp admin, member, or employee will result in the instant deletion of your account. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="5">
        <li>
          We don't guarantee exclusivity and reserve the right to provide our Services to other businesses. Furthermore, you acknowledge and accept that MartsUp workers and contractors might also be MartsUp clients or merchants and might engage in business competition, even if they are not permitted to use your confidential information to do so. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="6">
        <li>
          We reserve the right to ask for documents to establish or confirm Account ownership in the case of a dispute regarding ownership. A scanned copy of your company licence, a photo ID from the government, the last four digits of the credit card on file, or proof that you are an employee of an organisation are all acceptable forms of identification. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="7">
        <li>
          MartsUp maintains the right to identify the legitimate Account owner and transfer an Account to the legitimate Store Owner at our sole discretion. Without limiting any other rights and remedies, MartsUp reserves the right to temporarily suspend or disable an Account until a settlement has been reached between the disputed parties if we are unable to reasonably identify the legitimate Store Owner. 
        </li>
    </ol>
  </div>
  <div>
      <h6>
          Your Responsibilities:
      </h6>
  </div>
  <div>
      <ol start="1">
        <li>
          We are not responsible for the operations of your MartsUp Store, the products or services you sell, or your interactions with customers. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="2">
        <li>
          You understand and consent to making public information such as contact details, a refund policy, and order fulfilment schedules available on your MartsUp Store. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="3">
        <li>
          The Services are not a marketplace, and any sales agreements established through the Services are solely between you and the consumer. This is something you accept and agree with. For any products you sell through the Services, you are the legal vendor. You are in charge of setting up and running your MartsUp Store, your Materials, the products and services you might sell using the Services, as well as all facets of the business dealings you have with your client(s). This includes, but is not limited to, authorising the charge to the customer with regard to the customer's purchase, refunds, returns, fulfilling any sales or customer service, preventing fraudulent transactions, complying with legal disclosure requirements, regulatory compliance, alleged or actual violations of applicable laws (including, but not limited to, consumer protection laws in any jurisdiction where you offer products or services for sale), or your violation of these Terms of Service. Your Store, your Materials, and the products and services you sell through the Services will all be genuine, accurate, and in compliance with all applicable laws, rules, and third-party rights, you represent and warrant. For the avoidance of doubt, MartsUp will not be the seller, merchant, record, and will not be liable for your Store or any products that are purchased by users using the Services. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="4">
        <li>
          The products or services that you may sell through the Services are solely your responsibility, including adherence to all applicable laws and regulations. This includes description, price, fees, tax that you calculate, defects, required legal disclosures, and offers or promotional content. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="5">
        <li>
          You may not violate any laws in your jurisdiction (including but not limited to copyright laws), the laws that apply to you in your customer's jurisdiction, by using the MartsUp Services for any unlawful or unauthorised purposes. In using the Service and carrying out your obligations under the Terms of Service, you will abide by all applicable laws, rules, and regulations, including but not limited to obtaining and adhering to the conditions of any licence or permit that may be required for you to run your store or that you may hold. 
        </li>
    </ol>
  </div>
  <div>
      <h6>
          Confidentiality: 
      </h6>
  </div>
  <div>
      <p>
        You and MartsUp concur that you will only use each other's Confidential Information to carry out your obligations under these Terms of Service. Information that is confidential must be safeguarded and regarded. 
      </p>
  </div>
  <div>
      <ol start="1">
        <li>
          Any and all information related to a party's business that is not generally known as "Confidential Information" will be considered to be Confidential Information, including but not limited to: specific business information, technical processes and formulas, software, customer lists, prospective customer lists, names, addresses, and other details about current and potential customers, product designs, sales, costs (including any applicable processing fees), price lists, and other unpublished financial information. All information you receive from MartsUp about us or the Services that is not known to the general public is considered confidential information, including details on our security programme and procedures. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="2">
        <li>
          Each party acknowledges that its use of the other's confidential information shall be limited to that which is strictly necessary to carry out its obligations under these Terms of Service and in line with any other obligations therein. To prevent the duplication, disclosure, or use of any such Confidential Information, each Party agrees to take all reasonable measures, at least substantially equivalent to the measures it takes to protect its own proprietary information, with the exception of (i) by or to its employees, agents, and subcontractors who must have access to such Confidential Information to perform such Party's obligations hereunder, each of whom will treat such Confidential Information as provided herein. Information that the receiving party can demonstrate was either (A) in the public domain, already known to or in the possession of the receiving party, or (B) was independently developed by the receiving party without using or referencing the other party's confidential information and without violating any of the terms of these terms of service is not considered confidential information. 
        </li>
    </ol>
  </div>
  <div>
      <h6>
          Limitation of Liability and Indemnification: 
      </h6>
  </div>
  
  <div>
      <ol start="1">
        <li>
          You expressly understand and agree that, to the extent permitted by applicable laws, MartsUp and its suppliers will not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or relating to the use of or inability to use the Service or these Terms of Service (however arising, including negligence). 
        </li>
    </ol>
  </div>
  <div>
      <ol start="2">
        <li>
          You hereby acknowledge and agree that MartsUp and its suppliers shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data or other intangible losses arising out of or related to the use or inability to use the Service or these Terms of Service (however arising, including negligence, breach of contract, tort, strict liability or otherwise). 
        </li>
    </ol>
  </div>
  <div>
      <ol start="3">
        <li>
          You consent to defend, indemnify, and hold harmless us and (as applicable) our parent, subsidiaries, affiliates, MartsUp partners, officers, directors, agents, employees, and suppliers from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of (a) your violation of these Terms of Service; (b) or your violation of any law or the rights of a third party; or (c) any element of the deal between you and your customer, including but not but not limited to refunds, fraudulent transactions, alleged or actual violation of applicable laws 
        </li>
    </ol>
  </div>
  <div>
      <ol start="4">
        <li>
          You shall be held accountable as if it were your own violation of the Terms of Service for any violation committed by your affiliates, agents, or subcontractors. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="5">
      <li>
        Your entire risk arises from using the Services. The Services are offered "as is" and "as available" with no express, implied, or statutory warranties or conditions. 
      </li>
  </ol>
  </div>
  <div>
      <ol start="6">
      <li>
        MartsUp makes no guarantees on the continuity, promptness, security, or error-free operation of the Services. 
      </li>
  </ol>
  </div>
  <div>
      <ol start="7">
        <li>
          MartsUp disclaims all responsibility for the accuracy or dependability of any outcomes that may be attained via the use of the Services. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="8">
        <li>
          Any tax duties or obligations you may have because of using MartsUp's services are not MartsUp's responsibility. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="9">
        <li>
          MartsUp does not guarantee that any services will be error-free or that the quality of any goods, information, or other things you buy or get through the services will match your expectations. 
        </li>
    </ol>
  </div>
  <div>
       <h6>
        Intellectual Property and Your Materials: 
    </h6>
  </div>
  <div>
    <ol start="1">
      <li>
        Any content you upload can be used and published by MartsUp, but it always remains your property (if it ever was). Everything that is uploaded to MartsUp is still the owner's duty and property. 
      </li>
  </ol>
  </div>
  <div>
      <ol start="2">
        <li>
          The materials that you give to MartsUp are not our property, however we do need a licence to use them. You give MartsUp a non-exclusive, transferable, sub-licensable, worldwide right and licence to host, use, distribute, expose, modify, run, copy, store, publicly perform, communicate to the public (including through telecommunication), broadcast, reproduce, make available, display, and translate any Materials you provide in connection with the Services, as well as a right and licence to make derivative works of such Materials. We are allowed to utilise the license's rights to run, provide, and advertise the Services as well as to carry out our duties and make use of our privileges granted by the Terms of Service. You guarantee that you have all essential rights in the Materials to grant this licence, and you represent that you do. You hereby expressly waive, in favour of MartsUp, any and all moral rights that you may have in the Materials. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="3">
        <li>
          If you already held the Materials before giving them to MartsUp, they still remain your property, subject to any rights or licences stated in the Terms of Service or elsewhere, even after being uploaded to your MartsUp Store. By cancelling your Account, you can always take down your MartsUp Store. Any rights or licences granted to the Materials that MartsUp needs to exercise any rights or carry out any obligations that surfaced during the Term are not terminated by removing your MartsUp Store. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="4">
        <li>
          You acknowledge that MartsUp is free to review and remove any or all materials provided to the Services at any time but is under no obligation to do so. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="5">
        <li>
          You hereby grant MartsUp a non-exclusive, transferable, sub-licensable, worldwide right and licence to use your Store's names, trademarks, service marks, and logos ("Your Trademarks") in connection with the operation, provision, and promotion of the Services and in the performance of our obligations and exercise of our rights under the Terms of Service. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="6">
        <li>
          As long as MartsUp needs the licence to exercise any rights or fulfil any obligations that surfaced during the Term, this licence will continue to be in effect even if the Terms of Service are terminated. 
        </li>
    </ol>
  </div>
  <div>
      <h6>
          MartUp Email:  
      </h6>
  </div>
  <div>
      <p>
        The MartsUp email services (the "Email Services") allow you to create and send emails directly from your Account. You must adhere to the following rules in order to access and use MartsUp's email services, in addition to the restrictions that apply to the Services generally (such as the Acceptable Use Policy and Privacy Policy): 
      </p>
  </div>
  <div>
      <ol start="1">
        <li>
          Prior to delivery, MartsUp uses specific controls to check the emails you send using the Email Services for content ("Content Scanning"). Spam, phishing, and other harmful content that violates our terms of service or MartsUp's acceptable use policy are among the threats that are limited by this content scanning (collectively, "Threats"). You expressly permit MartsUp the right to use such content scanning by using the Email Services. Each MartsUp merchant is in charge of all content produced by their individual Stores and does not guarantee that the Email Services will be free of Threats. 
        </li>
    </ol>
  </div>
  <div>
      <ol start="2">
        <li>
          You agree to abide by the following conditions (the "email services requirements") when creating or sending email through the email services. If you don't follow the email services standards, Martsup or its third-party providers may suspend or stop letting you use the email services. 1. You must abide by all legal requirements when using the email services. Laws governing spam or unwelcome commercial email (or "UCE"), privacy, security, obscenity, defamation, intellectual property, pornography, terrorism, homeland security, gambling, child protection, and other applicable laws are a few examples of those that may apply. It is your duty to be aware of and understand the laws that are relevant to the way you use the email services and the emails you create or send using them. 2. You must abide by MartsUp's privacy policy when using the Email Services. You are responsible for reading and comprehending the Privacy Policy that relates to how you use the Email Services and the emails you create or send using those Services. 3. You will make a good faith effort not to send sensitive personal data (collectively referred to as "Sensitive Data") through the Email Services, such as details about someone's race, ethnic origin, political opinions, religious or philosophical beliefs, or other sensitive data. You are responsible for reading and comprehending your responsibilities regarding sensitive data. 4. You must abide by all applicable rules set forth by MartsUp when using the Email Services. When creating or sending emails through the Email Services, the following rules are examples of practises that can be in violation of the Email Services Requirements: 
        </li>
    </ol>
      <ol><ol start="1" style="list-style-type: lower-alpha;">
        <li>
          using non-permission based email lists (i.e., lists where each recipient has not expressly granted permission to receive emails from you by affirmatively opting-in to receive those emails);  
        </li>
      </ol>
    </ol>
    <ol><ol start="2" style="list-style-type: lower-alpha;">
      <li>
        purchasing or renting email lists; using third party email addresses, domain names, or mail servers without the proper authorization;  
      </li>
    </ol></ol>
    <ol><ol start="3" style="list-style-type: lower-alpha;">
      <li>
        sending emails to general addresses (such as webmaster@domain.com or info@domain.com);   
      </li>
    </ol></ol>
    <ol><ol start="4" style="list-style-type: lower-alpha;">
      <li>
        and sending emails that generate an unacceptable number of unsubscribes. 
      </li>
    </ol></ol>
    <ol><ol start="5" style="list-style-type: lower-alpha;">
      <li>
        failing to include a functional "unsubscribe" link in every email that enables the recipient to remove themselves from your mailing list;   
      </li>
    </ol></ol>
    <ol><ol start="6" style="list-style-type: lower-alpha;">
      <li>
        sending emails that cause an excessive amount of spam or UCE complaints (even if the emails themselves aren't spam or UCE);  
      </li>
    </ol></ol>
    <ol><ol start="7" style="list-style-type: lower-alpha;">
      <li>
        not honouring any request from a recipient to be removed from your mailing list within ten days of receiving the request;  
      </li>
    </ol></ol>
    <ol><ol start="8" style="list-style-type: lower-alpha;">
      <li>
        and not including in every email a link to the then-current privacy statement.  
      </li>
    </ol></ol>
    <ol><ol start="9" style="list-style-type: lower-alpha;">
      <li>
        failing to include in every email your correct physical address or a link to it;  
      </li>
    </ol></ol>
    <ol><ol start="10" style="list-style-type: lower-alpha;">
      <li>
        sending emails that contain "junk mail," "chain letters," "pyramid schemes," or other materials that entice recipients to forward them to other recipients, such as coupons, discounts, awards, or other incentives. 
      </li>
    </ol></ol>
  </div>
  <div>
      <ol start="3">
      <li>
        Please contact MartsUp at martsups@gmail.com or call +91 8956208790 if you are aware of any violations of the Email Services Requirements or have reason to believe that there are. MartsUp will arbitrarily decide whether or not the Email Services Requirements have been met. 
      </li>
  </ol>
  </div>
  <div>
      <h6>
          Beta Services:  
      </h6>
  </div>
  <div>
      <p>
        Pre-release or beta features that are under development and not yet accessible to all merchants ("Beta Services") may occasionally be invited for trial use by MartsUp at its sole discretion. Beta Services are not a component of the Services, and MartsUp will make you aware of any extra terms and conditions before you use the Beta Services. These Beta Services, along with all communications and documents related to them, will be regarded as MartsUp Confidential Information and be governed by the agreement's confidentiality clauses. Without limiting the scope of the aforementioned, you acknowledge that you will not, without MartsUp's prior written consent, make any public statements or otherwise divulge your participation in the Beta Services. MartsUp offers no guarantees or assurances regarding the functionality of the Beta Services. In its sole discretion, MartsUp has the right to end the Beta Services at any moment. MartsUp disclaims all responsibility for any harm or damage resulting from or related to a Beta Service. It's possible that the Beta Services won't perform exactly like the final product. In our sole discretion, MartsUp may alter or choose not to publish a final or commercial version of a Beta Service. 
      </p>
  </div>
  <div>
      <h6>
          Feedback and Reviews:  
      </h6>
  </div>
  <div>
      <p>
        Regarding changes or additions to the Services, MartsUp is open to any ideas and/or suggestions. Under no circumstances will MartsUp be subject to a confidentiality obligation or an expectation of payment for any ideas, suggestions, or related materials (collectively, "Feedback"). This also applies to any reviews of the Services, Third Party Services, or any Third Party Provider. By providing MartsUp with feedback (whether directly to MartsUp or posted on a forum or page hosted by MartsUp), you are waiving all claims to the feedback, and MartsUp is free to use the feedback as it sees fit, either as provided by you or as modified by MartsUp, without seeking authorization or licence from you or any other party. MartsUp retains the option but not the duty.
      </p>
  </div>
  <div>
      <h6>
          Term and Termination: 
      </h6>
  </div>
  <div>
      <!-- <ol> -->
          <!-- <li> -->
            <p>
              The period of these terms of service (the "Term") will start on the date that you successfully register to use a service and will last until terminated by either us or you as described below. 
            </p>
          <!-- </li> -->
      <!-- </ol> -->
  </div>
  <div>
      <!-- <ol> -->
          <!-- <li> -->
            <p>
              By contacting MartsUp Support and then adhering to the precise steps provided to you in the response you received from MartsUp, you may cancel your Account and terminate the Terms of Service at any time. 
            </p>
          <!-- </li> -->
      <!-- </ol> -->
  </div>
  <div>
      <!-- <ol> -->
          <!-- <li> -->
            <p>
              Without limiting our other remedies, we reserve the right to suspend or terminate the Terms of Service or your Account at any time and for any reason (except as otherwise required by law), including if we have reason to believe that you have used the Services fraudulently as evidenced by a conviction, settlement, insurance or escrow investigation, or other means.  Without limiting our other remedies, we reserve the right to suspend or terminate the Terms of Service or your Account at any time and for any reason (except as otherwise required by law), including if we have reason to believe that you have used the Services fraudulently as evidenced by a conviction, settlement, insurance or escrow investigation, or other means.  
            </p>
          <!-- </li> -->
      <!-- </ol> -->
  </div>
  <div>
      <!-- <ol> -->
          <!-- <li> -->
            <p>
              Any rights or responsibilities that existed before the date of termination will not be harmed by the termination of the Terms of Service. In the event that either party ends the Services for any reason: Any outstanding balance owed to MartsUp for your use of the Services up until the effective date of such termination will immediately become due and payable in full, and your MartsUp Store will be deactivated, unless otherwise specified in the Terms of Service. MartsUp will also stop providing you with the Services, and you won't be able to access your Account anymore. 
            </p>
          <!-- </li> -->
      <!-- </ol> -->
  </div>
  <div>
      <!-- <ol> -->
          <!-- <li> -->
            <p>
              If you bought a domain name through MartsUp, it won't be renewed by default after cancellation. After termination, it will be your exclusive duty to deal with the domain registrar for all issues relating to your domain. You will get one final invoice through email if there are still any unpaid Fees due from you at the time the Service is terminated. You won't be charged again until that invoice has been fully paid. 
            </p>
          <!-- </li> -->
      <!-- </ol> -->
  </div>
  
  <div>
      <h6>
          General Conditions:   
      </h6>
  </div>
  <div>
      <div>
        <ol start="1">
            <li>
              Any prior agreements between you and MartsUp (including, but not limited to, any earlier versions of the Terms of Service) are superseded by the Terms of Service, which together with the other documents it incorporates by reference, govern your use of the Services and your Account. 
            </li>
        </ol>
      </div>
      <div>
        <ol start="2">
            <li>
              Any right or requirement of the Terms of Service that is not exercised or enforced by MartsUp shall not be deemed to have been waived.  
            </li>
        </ol>
      </div>
      <div>
        <ol start="3">
            <li>
              The parties to the Terms of Service, as well as each of their respective heirs, successors, allowed assigns, and legal representatives, will be bound by and benefit from all of the terms and clauses of the Terms of Service. These Terms of Service may be assigned by MartsUp without your knowledge or permission. The Terms of Service and all of your rights and duties under them may not be assigned or otherwise transferred to a third party without MartsUp's prior written authorization, which MartsUp may grant or refuse in its sole discretion. 
            </li>
        </ol>
      </div>
      <div>
        <ol start="4">
            <li>
              Any provision or portion of a provision in these Terms of Service that is determined to be invalid, illegal, or unenforceable in any way will not affect any other provision of the Terms of Service (or the unaffected portion of the provision), and the Terms of Service will be interpreted as if the invalid, illegal, or unenforceable provision (or portion of the provision) had never been included in the Terms of Service. 
            </li>
        </ol>
      </div>
      <div>
        <ol start="5">
            <li>
              All associated rights and obligations under the Terms of Service are immediately terminated upon termination, with the exception that (a) you are still in charge of fulfilling all of your obligations related to any transactions you entered into prior to termination as well as any liabilities that arose before or as a result of termination. 
            </li>
        </ol>
      </div>
    <!-- </ol> -->
  
        </div>
      </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary button-color" data-dismiss="modal">Close</button>
       
      </div>
    </div>
  </div>
</div>