import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VerifyOTPService {

  constructor(private router: Router, private http: HttpClient) { }

  verifyOTP(OTP: string) {

    return this.http.post(`${environment.userApiUrl}/User/Verify/${OTP}`, OTP,{responseType: 'text'}).pipe(
      catchError(this.handleError)
    )
  }
  resendOTPForgotPassword(UserId:string){
    return this.http.get(`${environment.userApiUrl}/User/resendOTPForgotPassword/${UserId}`).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {

    var errorMessage =JSON.parse(error.error);
  return throwError( errorMessage.message);
  }
}
