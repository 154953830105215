<div class="container siginpage-container">
  <div class="row">
    <div class="card signin-page size-H">
      <div class="MartsUp-header">
        <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
      </div>
      <div class="header-border"></div>
      <div class="card-body pt-3">
        <div class="signin-header mb-4">
          <span class="signin"> {{'forgotpassword.title' | translate }}</span>
        </div>
        <div>
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" class="sigin-form">
            <div class="form-group">
              <img src="assets\svgimages\Email.svg" class="email-icon" />
              <label for="exampleInputEmail1 " class="email-text">{{'forgotpassword.username.label' | translate }}<span
                  class="required">*</span></label>
              <input type="text" class="form-control" formControlName="username" maxlength="50"
                placeholder="{{'forgotpassword.username.placeholder' | translate }}" [focusMe]="true">
              <div class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && f.username.errors?.required">
                {{ "validation.username.required" | translate }}
              </div>

            </div>
            <div>
              <button type="submit" class="btn btn-secondary btn-lg btn-block signin-btn mt-4" [type]="'submit'">
                <div class="submit-text">{{'forgotpassword.button.name' | translate }} <i class="fa fa-spinner fa-spin"
                    *ngIf="loading"></i></div>
              </button>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 text-red text-12 mt-1 font-weight-bold">
                <div *ngIf="errorMessage =='Please check with administrator'">
                  {{ "validation.forgotpassword.errormessage" | translate }}
                </div>
                  <div *ngIf="errorMessage =='Please check entered credentials'">
                    {{ "validation.forgotpassword.errormessage.false" | translate }}
                  </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 col-sm-12 mt-3 new-user-text">
                {{'forgotpassword.olduser' | translate }}
              </div>
            </div>
            <div class="row text-center">
              <a [routerLink]="['']" class="createaccount-text">
                {{'forgotpassword.signin.link' | translate }}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>