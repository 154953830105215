<div id="content">
  <div class="container-fluid main-content d-none d-md-block d-lg-block">
    <div class="row product-list-table mt-3">
      <p-table
        #dt
        [value]="data"
        (onFilter)="isEmpty($event)"
        [rows]="10"
        [paginator]="true"
        class="table-padding table-width"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        styleClass="p-datatable-customers category-comp-padd p-datatable-striped"
        [globalFilterFields]="[
          'firstName',
          'lastName',
          'email',
          'createdDate',
          'mobileNumber'
        ]"
        [rowHover]="true"
        dataKey="id"
        [showCurrentPageReport]="true"
        [scrollable]="true"
        scrollDirection="horizontal"
        scrollHeight="700px"
      >
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-6">
              <span class="p-input-icon-left" style="width: 100%">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  class="search_bar"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'userlist.search.placeholder' | translate }}"
                  #customSearch
                  [focusMe]="true"
                />
              </span>
            </div>

            <div class="col-3"></div>
            <div class="col-3" style="text-align: right">
              <button
                class="btn btn-lg btn-secondary btn_space"
                type="submit"
                (click)="onAddClick()"
                style="margin-right: 10px"
              >
                <img src="assets/svgimages/plus.svg" class="h-14" alt="" />    
                {{ "userlist.button.adduser" | translate }} 
              </button>
              <button
                class="btn btn-primary btn_space"
                type="submit"
                (click)="onRefresh(dt)"
                title="{{ 'userlist.title.refresh' | translate }}"
              >
                <img src="assets/svgimages/Refresh.svg" class="h-14" alt="" />
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header" class="store-list-border">
          <br />
          <tr>
            <th pSortableColumn="firstName">
              {{ "userlist.user" | translate }} <p-sortIcon field="firstName"></p-sortIcon>
            </th>
            <th pSortableColumn="mobileNumber">
              {{ "userlist.mobilenumber" | translate }} <p-sortIcon field="mobileNumber"></p-sortIcon>
            </th>
            <th pSortableColumn="email">
              {{ "userlist.email" | translate }} <p-sortIcon field="email"></p-sortIcon>
            </th>
            <th pSortableColumn="createdDate">
              {{ "userlist.createddate" | translate }} <p-sortIcon field="createdDate"></p-sortIcon>
            </th>
            <th pSortableColumn="active">
              {{ "userlist.status" | translate }} <p-sortIcon field="active"></p-sortIcon>
            </th>

            <th pSortableColumn="inventoryStatus">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr class="p-table">
            <td class="text-transform-capitalize">
              {{ data.firstName }} {{ data.lastName }}
            </td>
            <td class="text-transform-capitalize">{{ data.mobileNumber }}</td>
            <td class="email-id">{{ data.email }}</td>
            <td>{{ data.createdDate | date: "d/M/y" }}</td>
            <td>
              <div *ngIf="data.active == true">
                <span class="status text-success">•</span> {{ "userlist.active" | translate }}
              </div>
              <div *ngIf="data.active == false || data.active == null">
                <span class="status text-warning">•</span> {{ "userlist.inactive" | translate }}
              </div>
            </td>
            <td class="store-list-icons">
              <button
                class="btn btn-rounded-hash mr-1"
                (click)="oneditClick(data.id)"
                title="{{ 'userlist.title.edit' | translate }}"
              >
                <img
                  src="assets/svgimages/editred.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Mobile View -->
  <div class="d-block d-md-none d-lg-none container-fluid">
    <div class="mt-3">
      <div class="has-search position-relative">
        <i class="fa fa-search form-control-feedback"></i>
        <input
          type="text"
          class="form-control mobSearch"
          placeholder="{{ 'userlist.mobile.search' | translate }}"
          [(ngModel)]="searchText"
          autocomplete="off"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <button
          type="submit"
          class="refresh-button"
          (click)="onRefreshmobile()"
        >
          <img
            src="assets/svgimages/Refresh.svg"
            alt="Refresh"
            class="h-18"
          /><span class="marginleft-10px Buttontextsize">{{ "userlist.mobile.refresh" | translate }}</span>
        </button>
      </div>
      <div class="col-6">
        <button type="submit" class="add-store-btn" (click)="onAddClick()">
          <img src="assets/svgimages/plus.svg" alt="add" class="h-18" />
          <span class="marginleft-10px Buttontextsize">{{ "userlist.button.adduser" | translate }}</span>
        </button>
      </div>
    </div>

    <div
      class="mt-3"
      style="text-align: center"
      *ngIf="
        selectedUser != null &&
        (data === null || data === undefined || data.length === 0)
      "
    >
      Please create user
    </div>
    <div
      *ngFor="
        let obj of data
          | paginate
            : {
                itemsPerPage: pageSize,
                currentPage: currentPage,
                totalItems: totalItems
              }
          | userfilter: searchText
      "
    >
      <div class="card store-card mt-3">
        <div class="card-body padding-rem-75">
          <div class="row">
            <div class="col-md-7 col-7">
              <div class="d-flex align-items-center">
                <ngx-avatar
                  size="40"
                  name="{{ obj.firstName.substring(0, 15) }}"
                ></ngx-avatar>
                <div class="ml-1 lh-18">
                  <span
                    class="text-14 text-blue-color font-weight-600 text-transform-capitalize ml-2"
                    >{{ obj.firstName }} {{ obj.lastName }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-5 col-5" style="text-align: right">
              <span class="text-12 font-weight-600">
                <div *ngIf="obj.active == true">
                  <span class="status text-success mr-1 ml-1">•</span>{{ "userlist.active" | translate }}
                </div>
                <div *ngIf="obj.active == false || obj.active == null">
                  <span class="status text-warning">•</span>{{ "userlist.inactive" | translate }}
                </div>
              </span>
            </div>
          </div>
          <div class="row text-blue-color align-items-end mt-2">
            <div class="col-md-12 col-12 lh-22" *ngIf="obj.mobileNumber">
              <span class="text-9 font-weight-600"
                >{{ "userlist.mobile.mobilenumber" | translate }}
                <span class="text-blue-color text-13 font-weight-600">{{
                  obj.mobileNumber
                }}</span></span
              >
            </div>

            <div class="col-md-12 col-12 lh-22" *ngIf="obj.email">
              <span class="text-9 font-weight-600"
                >{{ "userlist.mobile.email" | translate }}
                <span class="text-blue-color text-13 font-weight-600">{{
                  obj.email
                }}</span></span
              >
            </div>
            <div class="col-md-8 col-8 lh-22">
              <span class="text-9 font-weight-600">
                {{ "userlist.mobile.createddate" | translate }}
                <span class="text-blue-color text-13 font-weight-600">{{
                  obj.createdDate | date: "d/M/y" 
                }}</span></span
              >
            </div>
            <div class="col-md-4 col-4 lh-22">
              <div class="d-flex" style="justify-content: right">
                <button
                  class="btn btn-rounded-hash mr-1"
                  (click)="oneditClick(obj.id)"
                  *ngIf="obj.userID == userId || isSuperAdmin"
                  title="{{ 'userlist.title.edit' | translate }}"
                >
                  <img
                    src="assets/svgimages/editred.svg "
                    alt=""
                    class="useredit-button"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-container">
    <pagination-controls
      (pageChange)="pageChanged($event)"
      [maxSize]="10"
      [directionLinks]="true"
      previousLabel="{{ 'pagination.previous' | translate }}"
        nextLabel="{{ 'pagination.next' | translate }}"
    ></pagination-controls></div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
<p-confirmDialog
  class="store-list-popup"
  [baseZIndex]="10000"
></p-confirmDialog>
