<div class="container siginpage-container">
  <div class="row">
    <div class="card signin-page size-H">
      <div class="MartsUp-header">
        <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
      </div>
      <div class="header-border"></div>
      <div class="card-body pt-2">
        <div class="signin-header mb-3">
          <span class="signin"> {{ "setpassword.title" | translate }}</span>
        </div>
        <div>
          <form
            [formGroup]="setPasswordForm"
            (ngSubmit)="setPassword()"
            class="sigin-form"
          >
            <div class="form-group">
              <img src="assets\svgimages\profile.svg" class="email-icon" />
              <label class="email-text"
                >{{ "setpassword.username.label" | translate
                }}<span class="required">*</span></label
              >
              <input
                class="form-control"
                type="email"
                placeholder="{{
                  'setpassword.username.placeholder' | translate
                }}"
                formControlName="username"
                [focusMe]="true"
                (keydown)="usernamechange($event)"
                maxlength="50"
              />
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="setPasswordForm.get('username').errors?.usernameTaken"
              >
                {{ "validation.username.duplicate" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.username.errors?.required"
              >
                {{ "validation.setpassword.username.required" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted"
              >
                <div *ngIf="messageUsername === 'Username already exists'">
                  {{ "validation.username.duplicate" | translate }}
                </div>
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="setPasswordForm.get('username').hasError('onlyNumbers')"
              >
                {{ "validation.username.notnumber" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted"
              >
                <div *ngIf="setPasswordError === 'Please enter valid username'">
                  {{ "validation.setpassword.error" | translate }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <img src="assets\svgimages\password svg.svg" class="pass-icon" />
              <label class="email-text"
                >{{ "setpassword.password.label" | translate
                }}<span class="required">*</span></label
              >
              <div class="password">
                <input
                  [type]="show_password ? 'text' : 'password'"
                  class="form-control password-box"
                  formControlName="password"
                  placeholder="{{
                    'setpassword.password.placeholder' | translate
                  }}"
                />
                <span class="eyeicon">
                  <i
                    [class]="show_eyepassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="showPassword()"
                  ></i
                ></span>
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.password.errors?.required"
              >
                {{ "validation.password.required" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="f.password.errors?.minlength"
              >
                {{ "validation.password.minlength" | translate }}
              </div>
            </div>
            <div class="form-group">
              <img src="assets\svgimages\password svg.svg" class="pass-icon" />
              <label for="exampleInputPassword1" class="email-text"
                >{{ "setpassword.confirmpassword.label" | translate
                }}<span class="required">*</span></label
              >
              <div class="password">
                <input
                  [type]="show_confirmpassword ? 'text' : 'password'"
                  class="form-control password-box"
                  id="exampleInputPassword1"
                  formControlName="confirmPassword"
                  placeholder="{{
                    'setpassword.confirmpassword.placeholder' | translate
                  }}"
                />
                <span class="eyeicon">
                  <i
                    [class]="
                      show_eyeconfirmpassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                    "
                    (click)="showConfirmPassword()"
                  ></i
                ></span>
              </div>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && f.confirmPassword.errors?.required"
              >
                {{ "validation.confirmpassword.required" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold mb-3"
                *ngIf="f.confirmPassword.errors?.confirmPasswordValidator"
              >
                {{
                  "validation.confirmpassword.confirmpasswordvalidator"
                    | translate
                }}
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="btn btn-secondary btn-lg btn-block signin-btn mt-4"
              >
                <div>
                  {{ "setpassword.button.name" | translate }}
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
