import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ShareimageService } from '@services/shareimage.service';
import { ProfileService } from '@services/profile.service';
import { ToastrService } from 'ngx-toastr';
import { Userprofile } from '@/Model/userprofile';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  providers: [ConfirmationService]
})
export class EditUserComponent implements OnInit {
  public userlistForm: FormGroup;
  public isFormSubmitted: boolean = false;
  UserId: any;
  pas: any;
  languageName:any;
  profileobj: Userprofile = new Userprofile();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shareimageService: ShareimageService,
    private formBuilder: FormBuilder,
    private profileservice: ProfileService,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private translate:TranslateService
  ) {this.translate.setDefaultLang('en') }

  ngOnInit(): void {
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
      this.translate.use('en');
     }else{
      this.translate.use(this.languageName);
     }
    this.profileservice.getUserById().subscribe((data: any) => {},(err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);
    
  });
   this.SpinnerService.show();
    this.shareimageService.setPageName("Users");
    this.profileservice.getNotification().subscribe((data: any) => {
      this.shareimageService.setnotification(data)
      localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
    });
    this.userlistForm = this.formBuilder.group({
      id: new FormControl(''),
      firstName: new FormControl('', [Validators.required,Validators.maxLength(15)]),
      lastName: new FormControl('', [Validators.required,Validators.maxLength(15)]),
      mobileNumber: new FormControl(''),
      username:new FormControl(''),
      email: new FormControl('',[Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      password: new FormControl(''),
      active: new FormControl(false),
      profileImage: new FormControl(''),
      profileImagePath: new FormControl(''),
      createdBy: new FormControl(''),
      createdDate: new FormControl(''),
      updatedBy: new FormControl(''),
      updatedDate: new FormControl(''),
    });
  
    this.UserId = this.route.snapshot.params['userid'];

    this.profileservice.getUserByUserId(this.UserId).subscribe((data: any) => {
      this.profileobj = data;
      this.SpinnerService.show();
      this.userlistForm.controls['id'].setValue(this.profileobj.id);
      this.userlistForm.controls['firstName'].setValue(this.profileobj.firstName);
      this.userlistForm.controls['lastName'].setValue(this.profileobj.lastName);
      this.userlistForm.controls['mobileNumber'].setValue(this.profileobj.mobileNumber);
      this.userlistForm.controls['email'].setValue(this.profileobj.email);
      this.userlistForm.controls['username'].setValue(this.profileobj.username);
      this.userlistForm.controls['password'].setValue(this.profileobj.password);
      this.userlistForm.controls['active'].setValue(this.profileobj.active);
      this.SpinnerService.hide();
    })
  
  }
  get f() {
    return this.userlistForm.controls;
  }
  onSubmit() {

    this.SpinnerService.show();
    this.isFormSubmitted = true;
    if (this.userlistForm.status == 'INVALID') {
      this.SpinnerService.hide();
      return
    }
    const updatedata = new FormData();
    
    if(this.userlistForm.value.email===null){
      this.userlistForm.value.email='';
    }
    updatedata.append("id", this.userlistForm.value.id);
    updatedata.append("firstName", this.userlistForm.value.firstName);
    updatedata.append("lastName", this.userlistForm.value.lastName);
    updatedata.append("mobileNumber", this.userlistForm.value.mobileNumber);
    updatedata.append("email", this.userlistForm.value.email);
    updatedata.append("active", this.userlistForm.value.active);

    this.profileservice.updateuser(updatedata).subscribe((data: any) => {
      this.router.navigateByUrl("dashboard/users");
      this.SpinnerService.hide();
      const translatedMessage = this.translate.instant('toastr.success.updateuser');
      this.toastr.success(translatedMessage);
    }, (error: any) => {
      const translatedMessage = this.translate.instant('toastr.error');
      this.toastr.error(translatedMessage);
      this.SpinnerService.hide();
    })

  }

  onCancelClick() {
    this.router.navigateByUrl("dashboard/users");
  }
 
}
