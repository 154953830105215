
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '@services/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@/utils/Helper';
import { ShareimageService } from '@services/shareimage.service';
import { ProfileService } from '@services/profile.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  Store: any;
  Categories: any;
  Product: any;
  options: any;
  loadingProduct: boolean = false;
  loadingCategory: boolean = false;
  loadingStore: boolean = false;
  lineData: boolean = false;
  pieData: boolean = false;
  lineChartData: any;
  basicOptions: any;
  basicOptionsbar: any;
  storeCountArray = [];
  productCountArray = [];
  categoryCountArray = [];
  labelsArray = [];
  storeBarChartName = [];
  storeBarChartUsageCount = [];
  PieChartStoreName = [];
  // storeBarchartLabel =[];
  PieChartStoreUsageCount = [];
  // storeBarChartDatashow: [];
  storeBarChartData: any;
  pieChartData: any;
  barChartData: any;
  lineChartDatashow: [];
  pieChartDatashow: [];
  CategoryUsageCountArray = [];
  categoryLabelsArray = [];
  isSuperAdmin:boolean;
  languageName: any;
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private dashboardService: DashboardService,
    private SpinnerService: NgxSpinnerService,
    private helper: HelperService,
    private shareimageService: ShareimageService,
     private profileservice: ProfileService,
     private translate: TranslateService
    ) { this.translate.setDefaultLang('en'); }
  ngOnInit(): void {
    document
      .getElementById('tempid_Dashboard')
      ?.classList.add('highlight_sideMenu');
    this.shareimageService.setPageName('Dashboard');
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
      this.translate.use('en');
     }else{
      this.translate.use(this.languageName);
     }

    this.SpinnerService.show();
    this.getCategoryUsageByStore();
    this.StoreCount();
    this.CategoryCount();
    this.ProductCount();
     this.GetDataForBarChartByUserId();
    this.GetDataForLineChartByUserId();
    this.GetDataForPieChartByUserId();
    this.loaduser();
    
  }
  loaduser(){
    this.profileservice.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
      if(this.isSuperAdmin==true){
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
              localStorage.removeItem('notificationDataUserName');
        });
      }

      });
  }
  
  CategoryCount() {
    this.loadingCategory = true;
    this.dashboardService.GetAllCategory().subscribe((data: any) => {
      this.Categories = data;
      this.loadingCategory = false;
    }),
      (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
      //  this.toastr.error('Error occured');
        this.loadingCategory = false;
        this.SpinnerService.hide();
      };
  }
  StoreCount() {
    this.loadingStore = true;
    this.dashboardService.GetAllStores().subscribe((data: any) => {
      this.Store = data;
      this.loadingStore = false;
    }),
      (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
        //this.toastr.error('Error occured');
        this.SpinnerService.hide();
      };
  }
  ProductCount() {
    this.loadingProduct = true;
    this.dashboardService.GetAllProduct().subscribe((data: any) => {
      this.Product = data;
      this.loadingProduct = false;
    }),
      (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
       // this.toastr.error('Error occured');
        this.SpinnerService.hide();
      };
  }

  GetDataForLineChartByUserId() {
   
    this.dashboardService
      .GetDataForLineChartByUserId()
      .subscribe((data: any) => {
        this.lineChartDatashow = data;
      
        if (this.lineChartDatashow.length == 0) {
          this.lineData = false;
          this.SpinnerService.hide();
        } else {
          this.lineData = true;
          for (var i = 0; i < data.length; i++) {
            this.storeCountArray.push(data[i].storeCount);
            this.categoryCountArray.push(data[i].categoryCount);
            this.productCountArray.push(data[i].productCount);
            this.labelsArray.push(data[i].monthName);
          }
          this.lineChartData = {
            labels: this.labelsArray,
            datasets: [
              {
                label: 'Store',
                data: this.storeCountArray,
                fill: false,
                borderColor: '#76c8c8 ',
                tension: 0.4,
              },
              {
                label: 'Category',
                data: this.categoryCountArray,
                fill: false,
                borderColor: '#f95d6a',
                tension: 0.4,
              },
              {
                label: 'Product',
                data: this.productCountArray,
                fill: false,
                borderColor: '#c80064',
                tension: 0.4,
              },
            ],
          };
          this.basicOptions = {
            responsive: false,
          }
        }
      }),
      (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
       // this.toastr.error('Error occured');
        this.SpinnerService.hide();
      };
  }

  getCategoryUsageByStore(){
    this.dashboardService.GetDataForStoreBarChartByUserId().subscribe((data: any) => {
        for (var i = 0; i < data.length; i++) {
          this.storeBarChartName.push(data[i].categoryName);
          this.storeBarChartUsageCount.push(data[i].usageCount);
      }
    });
  }

  GetDataForPieChartByUserId() {
    this.dashboardService.GetDataForStoreBarChartByUserId().subscribe((data: any) => {
      for (var i = 0; i < data.length; i++) {
        this.storeBarChartName.push(data[i].categoryName);
        this.storeBarChartUsageCount.push(data[i].usageCount);
    }
      this.dashboardService.GetDataForPieChartByUserId().subscribe((data: any) => {
        this.pieChartDatashow = data;
        if (this.pieChartDatashow.length == 0) {
          this.pieData = false;
          this.SpinnerService.hide();
        } else {
          this.pieData = true;
          for (var i = 0; i < data.length; i++) {
            this.PieChartStoreName.push(data[i].storeName);
            this.PieChartStoreUsageCount.push(data[i].storeUsageCount);
          }
          this.pieChartData = {
            labels: this.PieChartStoreName,
            datasets: [
              {
                label:'Category',
                data: this.storeBarChartUsageCount,
                tension: 0.8,
                backgroundColor: [
                  '#98d1d1',
                ],
                hoverBackgroundColor: ['#f95d6a'],
              },{
                label: 'Store',
                data: this.PieChartStoreUsageCount,
                tension: 0.4,
                backgroundColor: [
                  '#d7658b'
                ],
                hoverBackgroundColor: ['#f95d6a'],
              }
            ],
          };
          this.basicOptionsbar = {
            maintainAspectRatio: false,
            // aspectRatio: 0.8,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const datasetIndex = context.datasetIndex;
                    const dataIndex = context.dataIndex;

                    const datasetLabel = this.storeBarChartName[dataIndex];
                    const value = this.pieChartData.datasets[1].data[dataIndex];

                    const datasetLabel2 = this.PieChartStoreName[dataIndex];
                    const value2 = this.pieChartData.datasets[0].data[dataIndex];
                    
                    return [`Store-${datasetLabel2}: ${value}`, `Category-${datasetLabel}: ${value2}`]
                  }
                }
              }
          },
         
          };
          this.SpinnerService.hide();
        }
      }),
      (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
        //this.toastr.error('Error occured');
        this.SpinnerService.hide();
      };
    });
  }
  GetDataForBarChartByUserId() {
    this.dashboardService
      .GetDataForBarChartByUserId()
      .subscribe((data: any) => {
          for (var i = 0; i < data.length; i++) {
            this.CategoryUsageCountArray.push(data[i].usageCount);
            this.categoryLabelsArray.push(data[i].categoryName);
          }
          this.barChartData = {
            labels: this.categoryLabelsArray,
            
            datasets: [
              {},{
                data: this.CategoryUsageCountArray,
                fill: true,
                tension: 0.8,
                backgroundColor: [
                  '#d7658b',
                  '#df979e',
                  '#e4bcad',
                  '#dedad2',
                  '#badbdb',
                  '#98d1d1',
                  '#76c8c8',
                  '#54bebe',
                  '#00bfa0',
                ],
                hoverBackgroundColor: ['#f95d6a'],
              },{}
            ],
          }; 
          this.options = {
            responsive: false,
            maintainAspectRatio: false,
            aspectRatio: 1,
            plugins: {
                legend: {
                    display: false
                }
            },
           
            
        };

        // }
      }),
      (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
       // this.toastr.error('Error occured');
        this.SpinnerService.hide();
      };
  }
  ngOnDestroy() {
    document
      .getElementById('tempid_Dashboard')
      ?.classList.remove('highlight_sideMenu');
  }
}
