import { Injectable } from '@angular/core';
import { ShareimageService } from '@services/shareimage.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService
{
  constructor(private shareimageService: ShareimageService)
  {

  }
  SetPageName(pagename) {
   
    this.shareimageService.setPageName({
      data: pagename
    });
  }
}
