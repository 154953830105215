<div
  class="d-flex align-items-center justify-content-between w-100"
  (clickOutside)="onClickedOutside($event)"
>
  <div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" role="button"
          ><i class="fas fa-align-left" (click)="onToggleMenuSidebar()"></i>
           <span class="dashboard-name text-secondary font-weight-600">
            <!-- {{
            pagename
          }} -->
          <span *ngIf="pagename==='Dashboard'">
            {{"header.dashboardpage" | translate  }}
          </span>
          <span *ngIf="pagename==='Stores'">
            {{"header.storepage" | translate  }}
          </span>
          <span *ngIf="pagename==='Categories'">
            {{"header.categorypage" | translate  }}
          </span>
          <span *ngIf="pagename==='Products'">
            {{"header.productpage" | translate  }}
          </span>
          <span *ngIf="pagename==='Users'">
            {{"header.userpage" | translate  }}
          </span>
          <span *ngIf="pagename==='Report'">
            {{"header.reportpage" | translate  }}
          </span>
          <span *ngIf="pagename==='Change Password'">
            {{"header.changepasswordpage" | translate  }}
          </span>
        </span> 
        </a>
      </li>
    </ul>
  </div>

  <div class="d-flex header-padding align-items-center" style="cursor: pointer">
    <ng-select
      [items]="languages"
      bindLabel="language"
      bindValue="id"
      (change)="useLanguage($event)"
      [clearable]="false"
      placeholder="{{ 'login.selectlanguage.text' | translate }}"
      class="dropdown"
      [(ngModel)]="selectedLanguage"
      [searchable]="false"
    >
    </ng-select>
    <div class="mr-2 d-flex align-items-center mr-1">
      <div class="image profile-img">
        <div *ngIf="profileImage == true; else menutemplatename">
          <img
            [src]="imageSrc"
            [routerLink]="['/dashboard/userprofile']"
            class="img-circle img-circle-icon"
          />
          <br />
        </div>
        <ng-template #menutemplatename>
          <ngx-avatar
            [routerLink]="['/dashboard/userprofile']"
            name="{{ letters }}"
            size="40"
          ></ngx-avatar>
        </ng-template>
      </div>

      <div style="margin-right: 10px">
        <span
          class="dashboard-name user-name-mobile text-displayname font-weight-600"
          style="font-weight: bold"
          [routerLink]="['/dashboard/userprofile']"
          >
     
          {{ 'header.welcome' | translate: { firstname: Name } }}
          </span
        >
      </div>
      <div class="bell-icon" *ngIf="isSuperAdmin">
        <i class="fas fa-bell" (click)="toggleDisplayDiv()"></i>
        <span class="badge badge-warning navbar-badge">{{
          notificationcount
        }}</span>
      </div>
      <div
        *ngIf="isShowDiv == true && this.notificationcount > 0"
        class="notification-popup"
      >
        <div>
          <p-table
            [value]="notificationobject"
            selectionMode="single"
            [(selection)]="selectednotification"
            (onRowSelect)="onRowSelect($event, op)"
            [op]="false"
            [rows]="5"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="header">
              <tr></tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-notification>
              <tr [pSelectableRow]="rowData">
                <td>
                  Received store approval request for store
                  <b>{{ notification.storeName }}</b> from user
                  <b>{{ notification.userName }}</b>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <button class="close-poup" (click)="toggleDisplayDiv()">
          <i class="fa fa-times close-icon" aria-hidden="true"></i>
        </button>
      </div>
      <div class="card flex justify-content-center">
        <p-overlayPanel #op>
          <ng-template pTemplate="content">
            <h6>
              Received new Store approval request for user- and store name is-
            </h6>
          </ng-template>
        </p-overlayPanel>
     
      </div>
    </div>
  </div>
</div>
<form class="form-inline ml-3" [formGroup]="searchForm" *ngIf="false">
  <div class="input-group input-group-sm">
    <input
      class="form-control form-control-navbar"
      type="search"
      placeholder="Search"
      aria-label="Search"
    />
    <div class="input-group-append">
      <button class="btn btn-navbar" type="submit">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
</form>

<!-- <ul class="navbar-nav ml-auto">
   <app-messages></app-messages>
    <app-notifications></app-notifications>
    <app-language></app-language>
    <app-user></app-user>
    <li class="nav-item">
        <button class="nav-link" (click)="onToggleControlSidebar()">
            <i class="fas fa-th-large"></i>
        </button>
    </li>
</ul>-->
