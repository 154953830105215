<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid main-content">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-9 add-store">
        <span class="font-weight-600 text-23 text-16 add-store-font">{{
          "addstore.title" | translate
        }}</span>
      </div>
    </div>
    <div class="row mobile-view-border">
      <div class="col-md-1"></div>
      <div class="col-md-9">
        <form
          #form="ngForm"
          [formGroup]="storeform"
          (ngSubmit)="CreateNewStore()"
        >
          <div class="contact-form">
            <div class="form-group mb-0" *ngIf="isSuperAdmin">
              <label class="control-label col-sm-4 text-14"
                >{{ "addstore.selectusername.label" | translate
                }}<span class="required">*</span></label
              >
              <div class="col-sm-8">
                <ng-select
                  [items]="users"
                  autofocus
                  bindLabel="name"
                  bindValue="id"
                  placeholder="{{
                    'addstore.selectusername.placeholder' | translate
                  }}"
                  name="id"
                  [(ngModel)]="selectedUser"
                  (change)="onChange($event)"
                  formControlName="username"
                  [clearable]="false"
                >
                </ng-select>
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f.username.errors?.required"
                >
                  {{ "validation.store.username.required" | translate }}
                </div>
              </div>
              <br />
            </div>

            <div class="form-group mb-0">
              <label class="control-label col-sm-4 text-14"
                >{{ "addstore.storename.label" | translate }}
                <span class="required">*</span></label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'addstore.storename.placeholder' | translate
                  }}"
                  formControlName="name"
                  maxlength="20"
                  #nameInput
                  (blur)="createStoreUrl(nameInput.value)"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f.name?.errors?.required"
                >
                  {{ "validation.storename.required" | translate }}
                </div>
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="f.name?.errors?.pattern"
                >
                  {{ "validation.storename.pattern" | translate }}
                </div>
              </div>
            </div>
            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-4 text-14"
                >{{ "addstore.storeurl.label" | translate }}
                <span class="required">*</span></label
              ><br />
              <span
                class="control-label col-sm-4"
                style="font-size: 0.8rem; color: grey"
                >(Hint: https://www.my.martsup.com/storename)</span
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'addstore.storeurl.placeholder' | translate
                  }}"
                  formControlName="storeUrl"
                  [focusMe]="true"
                  (input)="onInput($event)"
                   (keydown)="disableBackspace($event)"
                />
             
                <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && f.storeUrl.errors?.required"
              >
                {{ "validation.storeUrl.required" | translate }}
              </div>
                <div class="text-red text-12 font-weight-bold mt-1">
                  <div *ngIf="urlmessage === 'Please create your store Url'">
                    {{ "validation.createstorerurl" | translate }}
                  </div>
                  <div *ngIf="urlmessage === 'Store url already exists'">
                    {{ "validation.duplicatestorerurl" | translate }}
                  </div>
                  <div *ngIf="urlmessage === 'true'">
                    {{ "validation.duplicatestorerurl" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-8 text-14"
                >{{ "addstore.whatsappnumber.label" | translate }}
                <span class="required">*</span></label
              >
              <div class="col-sm-8">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  formControlName="whatsAppNumber"
                  class="form-control"
                  placeholder="{{
                    'addstore.whatsappnumber.placeholder' | translate
                  }}"
                  (keypress)="onInputKeyPress($event)"
                  #phoneInput
                  (input)="onInputWhatsappNumber($event)"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f.whatsAppNumber.errors?.required"
                >
                  {{ "validation.whatsappnumber.required" | translate }}
                </div>

                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted"
                >
                  <div class="text-red text-12 font-weight-bold mt-1"
                    *ngIf="whatsappNumberError === 'Invalid whatsapp number'"
                  >
                    {{ "validation.whatsappnumber.pattern" | translate }}
                  </div>
                </div>
                  <div    class="text-red text-12 font-weight-bold"
                    *ngIf="whatsappNumberError === 'Invalid number'"
                  >
                    {{ "validation.whatsappnumber.pattern" | translate }}
                  </div>
                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="f.whatsAppNumber.errors?.pattern"
                >
                  {{ "validation.whatsappnumber.pattern" | translate }}
                </div>
              </div>
            </div>

            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-8 text-14"
                >{{ "addstore.contactnumber.lable" | translate }}
              </label>
              <div class="col-sm-8">
                <input
                  type="tel"
                  id="phone1"
                  name="phone1"
                  formControlName="contactNumber"
                  class="form-control"
                  (keypress)="onInputKeyPress($event)"
                  placeholder="{{
                    'addstore.contactnumber.placeholder' | translate
                  }}"
                  #phoneInput1
                  (input)="onInputContactNumber($event)"
                />
                <div   class="text-red text-12 font-weight-bold mt-1" *ngIf="contactNumberError === 'Invalid number'">
                  {{ "validation.contactnumber.pattern" | translate }}
                </div>
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted"
                >
                  <div *ngIf="contactNumberError === 'Invalid contact number'">
                    {{ "validation.contactnumber.pattern" | translate }}
                  </div>
                </div>
              
                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="f.contactNumber.errors?.pattern"
                >
                  {{ "validation.contactnumber.pattern" | translate }}
                </div>
                <div
                  *ngIf="
                    f.whatsAppNumber.value && !f.contactNumber.errors?.pattern
                  "
                >
                  <div
                    class="text-red text-12 font-weight-bold mb-3"
                    *ngIf="
                      f.contactNumber.value === f.whatsAppNumber.value &&
                      f.contactNumber.value
                    "
                  >
                    {{ "validation.mobilenumber.validation" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-4 text-14" for="email"
                >{{ "addstore.email.lable" | translate }}
              </label>
              <div class="col-sm-8">
                <input
                  type="email"
                  class="form-control"
                  placeholder="{{ 'addstore.email.placeholder' | translate }}"
                  formControlName="email"
                  maxlength="50"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f['email'].errors && f['email'].errors['pattern']"
                >
                  {{ "validation.email.pattern" | translate }}
                </div>
              </div>
            </div>

            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-4 text-14"
                >{{ "addstore.storeaddress.label" | translate }}
              </label>
              <div class="col-sm-8">
                <textarea
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'addstore.storeaddress.placeholder' | translate
                  }}"
                  formControlName="address"
                  rows="4"
                  maxlength="200"
                ></textarea>
              </div>
            </div>

            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-4 text-14"
                >{{ "addstore.facebookurl.label" | translate }} </label
              ><br />
              <span
                class="control-label col-sm-4"
                style="font-size: 0.8rem; color: grey"
                >(Hint: https://www.facebook.com/storename)</span
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'addstore.facebookurl.placeholder' | translate
                  }}"
                  formControlName="facebookUrl"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="
                    isFormSubmitted &&
                    f['facebookUrl'].errors &&
                    f['facebookUrl'].errors['pattern']
                  "
                >
                  {{ "validation.facebookurl.pattern" | translate }}
                </div>
              </div>
            </div>

            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-4 text-14"
                >{{ "addstore.instagramurl.label" | translate }} </label
              ><br />
              <span
                class="control-label col-sm-4"
                style="font-size: 0.8rem; color: grey"
                >(Hint: https://www.instagram.com/storename)</span
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'addstore.instagramurl.placeholder' | translate
                  }}"
                  formControlName="instagramUrl"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="
                    isFormSubmitted &&
                    f['instagramUrl'].errors &&
                    f['instagramUrl'].errors['pattern']
                  "
                >
                  {{ "validation.instagramurl.pattern" | translate }}
                </div>
              </div>
            </div>

            <br />
            <div class="form-group">
              <label class="control-label col-sm-4 text-14"
                >{{ "addstore.gstnumber.label" | translate }}
              </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'addstore.gstnumber.placeholder' | translate
                  }}"
                  formControlName="GSTNo"
                  maxlength="15"
                />
              </div>
              <div
                class="text-red text-12 font-weight-bold mt-1 margin-error"
                *ngIf="f.GSTNo.errors?.pattern"
              >
                {{ "validation.gstnumber.pattern" | translate }}
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-10">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked
                  formControlName="active"
                />&nbsp;
                <label
                  class="form-check-label text-14 ml-3 active-font-weight"
                  for="flexCheckChecked"
                >
                  {{ "addstore.active" | translate }}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="mb-4 mt-3">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-11 mobile-button-padding desktop-button-padding">
          <button
            type="cancel"
            class="btn btn-colour-cancel mobile-btn-save-cancel"
            (click)="onCancelClick()"
          >
            {{ "addstore.button.cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-colour mobile-btn-save"
            (click)="CreateNewStore()"
          >
            {{ "addstore.button.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
