import { Userprofile } from '@/Model/userprofile';
import { AppState } from '@/store/state';
import { ToggleControlSidebar, ToggleSidebarMenu } from '@/store/ui/actions';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { ProfileService } from '@services/profile.service';
import { ShareimageService } from '@services/shareimage.service';
import { Observable } from 'rxjs';
import { Notification } from '@/Model/notification';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';


const BASE_CLASSES = 'main-header navbar navbar-expand';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public searchForm: UntypedFormGroup;
  Name: string = '';
  id: any;
  imageSrc: any;
  notificationobject:Notification[]
  public selectednotification:Notification
  notificationcount:number;
  profileobject: Userprofile = new Userprofile();
  profileImage = false;
  initialsletters: any;
  letters: any;
  displayName:string;
  pagename:string;
  lastname:string;
  isShowDiv = false;  
  isSuperAdmin:boolean = false;   
  selectedLanguage: string;
  languageName:any;
  languages = [
      { id: 'en', language: 'English' },
      { id: 'mr', language: 'मराठी' },
      { id: 'hi', language: 'हिंदी' },
  ];
  constructor(
    private appService: AppService,
    private router: Router,
    private store: Store<AppState>,
    private profileService: ProfileService,
    private shareimageService: ShareimageService,
    private translate: TranslateService
  ) {this.selectedLanguage='en';
    this.translate.setDefaultLang('en');
    this.shareimageService.SharingData.subscribe((res: any) => {
   
      if (res.isImageChange) {
        this.imageSrc = res.imagePath;
        this.profileImage = true;
      }

      this.Name = res.data.firstName;
      this.lastname= res.data.lastName;
     
      
    });
    this.shareimageService.profilePageData.subscribe((res: any) => {
      this.pagename=res;
      //window.location.reload();
    });
    this.shareimageService.notificationData.subscribe((data: any) => {
        this.notificationobject = data;
        this.notificationcount= data.length;
        this.selectednotification = data[0];
        if(this.notificationcount<0 || this.notificationcount === undefined){
          localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
        }
      });
  }
  useLanguage(event: any) {
    localStorage.setItem('language', event.id);
    this.translate.use(event.id);
    location.reload();
  
}
  toggleDisplayDiv() {  
    this.isShowDiv = !this.isShowDiv;  
  } 
  getShortName(firstname, lastname) {

    const initials = firstname.charAt(0) + lastname.charAt(0);
    return initials.toUpperCase();
  }

  onClickedOutside(e: Event) {
    this.isShowDiv = false;
  }

  ngOnInit() {
 
    this.languageName = localStorage.getItem('language');
  
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      this.selectedLanguage ='en';
      localStorage.setItem('language', 'en');
      this.translate.use('en');
      
     }else{
      this.selectedLanguage =this.languageName;
      this.translate.use(this.languageName);
     }
    this.id = localStorage.getItem('userId')
    this.profileService.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
      this.profileobject = data;
      if (this.profileobject.profileImagePath == null || this.profileobject.profileImagePath == "") {
        this.profileImage = false;
        this.Name = this.profileobject.firstName;
        this.lastname=this.profileobject.lastName;
        this.initialsletters = this.getShortName(this.profileobject.firstName, this.profileobject.lastName);
        this.letters = this.initialsletters.split('').join(' ');
      }
      else {
        this.profileImage = true;
        this.Name = this.profileobject.firstName;
        this.lastname=this.profileobject.lastName;
        this.imageSrc = `${this.profileobject.profileImagePath}`;
      }
    },
    (err: any) => {
        if (err.status !== 404) {
          return;
        }
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.router.navigate(['/login']);
      
    });

//     this.profileService.getNotification().subscribe((data: any) => {
// alert('hi');
//       this.notificationobject = data;
//       this.notificationcount= data.length;
//       this.selectednotification = data[0];
//     });

    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.navbarVariant}`;
    });
    this.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(null)
    });

  }
  onRowSelect(event, op: OverlayPanel) {

     console.log(event.data)
     this.selectednotification = event.data
     this.shareimageService.setUserDropdown({data:event.data.userId, name:event.data.userName})
     localStorage.setItem('notificationDataUserId', event.data.userId);
     localStorage.setItem('notificationDataUserName', event.data.userName);

     this.selectednotification.userId = event.data.userId;
     this.selectednotification.userName = event.data.userName;

     window.location.pathname='/dashboard/store';
     
    // this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
    this.isShowDiv = false; 
    // window.location.pathname='/dashboard/store';
}

  logout() {
    this.appService.logout();
  }

  onToggleMenuSidebar() {
    this.store.dispatch(new ToggleSidebarMenu());
  }

  onToggleControlSidebar() {
    this.store.dispatch(new ToggleControlSidebar());
  }
}
