
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from '@/Model/product';
import { Store } from '@/Model/store';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '@/AddCategory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@/utils/Helper';
import { ShareimageService } from '@services/shareimage.service';
import { ProfileService } from '@services/profile.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-Editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['./editproduct.component.css'],
})
export class EditproductComponent implements OnInit {
  @ViewChild('inputVideoFile') inputVideoFile: ElementRef;
  ProductToSave: ProductModel = new ProductModel();
  StoreToSave: Store = new Store();
  productform!: FormGroup;
  categoryform: FormGroup;
  id!: number;
  sourcesFiles: any[] = [];
  video: any[] = [];
  imageDeleteFrom: any;
  deletedImages: any[] = [];
  sourcesFile: any[] = [];
  videos: any[] = [];
  deletedVideos: any[] = [];
  videoDeleteFrom: any;
  prodImgCnt: number = 0;
  step: any = 1;
  selectedCategory: any;
  productCategories: any[];
  userId: any;
  currentTab: any;
  event: any;
  isSuperAdmin:boolean;
  without_html: string;
  public step1: boolean = false;
  public step2: boolean = false;
  public coverimg: boolean = false
  public formuiSubmit: boolean = true;
  public ispdFormSubmitted: boolean = false;
  public ispcFormSubmitted: boolean = false;
  public isuiFormSubmitted: boolean = false;
  public isuvFormSubmitted: boolean = false;
  public isFormSubmitted: boolean = false;
  public isCategoryFormsubmitted: boolean = false;
  display: boolean = false;
  selectdTab: any;
  selectedUserId: any;
  lengthError: boolean = false;
  maxlengthError:string;
  languageName:any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '11rem',
    minHeight: '5rem',
    maxHeight: '15rem',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter product detail here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    public productService: ProductService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private helper: HelperService,
    private shareimageService: ShareimageService,
    private profileservice: ProfileService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
    this.InitializeProductForm();
    this.InitializeCategoryForm();
  }

  ngOnInit() {
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
     }else{
      this.translate.use(this.languageName);
     }
    this.currentTab ='pd';

    document
      .getElementById('tempid_Product')
      ?.classList.add('highlight_sideMenu');
    document.getElementById('defaultOpen').click();
    this.shareimageService.setPageName('Products');
   
    this.SpinnerService.show();
    this.id = this.route.snapshot.params['id'];

    this.productService.GetProductByProductId(this.id).subscribe(
      (data: any) => {
        this.ProductToSave = data;
        this.loadCategories();
      
        this.ProductToSave.productImages.length = this.ProductToSave.productImages == undefined
         ? 0
        : this.ProductToSave.productImages.length
        this.ProductToSave.discount = this.ProductToSave.discount == 0
        ? null
       : this.ProductToSave.discount
       this.ProductToSave.brandName = this.ProductToSave.brandName == 'null'
        ? ''
       : this.ProductToSave.brandName
        this.prodImgCnt = this.ProductToSave.productImages.length;
        this.selectedCategory = data.categoryId;

        this.productform
          .get('productDetail.name')
          .setValue(this.ProductToSave.name);
        this.productform
          .get('productDetail.description')
          .setValue(this.ProductToSave.description);
        var sp = (
          Math.round(this.ProductToSave.sellingPrice * 100) / 100
        ).toFixed(2);
        if (sp.length > 8) {
          sp = Math.trunc(this.ProductToSave.sellingPrice).toString();
        }

        this.productform.get('productDetail.sellingPrice').setValue(sp);
        this.productform
          .get('productDetail.showSellingPrice')
          .setValue(this.ProductToSave.showSellingPrice);
        this.productform
          .get('productDetail.discount')
          .setValue(this.ProductToSave.discount);

        this.productform
          .get('productDetail.brandName')
          .setValue(this.ProductToSave.brandName);
        this.productform
          .get('productDetail.active')
          .setValue(this.ProductToSave.active);

        this.productform
          .get('productCategory.categoryId')
          .setValue(this.ProductToSave.categoryId);
        this.productform
          .get('productCategory.selectedCategory')
          .setValue(this.selectedCategory);
        this.productform
          .get('productImage.CoverImageId')
          .setValue(this.ProductToSave.coverImageId);
        this.GetStoreBYStoreId();
        this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
      }
    );
    this.profileservice.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
     if(this.isSuperAdmin==true){
      this.profileservice.getNotification().subscribe((data: any) => {
        this.shareimageService.setnotification(data)
        localStorage.removeItem('notificationDataUserId');
            localStorage.removeItem('notificationDataUserName');
      });
     }
    },(err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);
    
  });
  }
  private GetAllActiveCategoryByStoreId() {
   
    this.categoryService
      .GetAllActiveCategoryByStoreId(this.ProductToSave.storeId)
      .subscribe((data: any) => {
        this.productCategories = data;
        this.selectedUserId = data[0]?.userID;
      });
  }

  private InitializeProductForm() {
    this.productform = new FormGroup({
      productDetail: new FormGroup({
        name: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        description: new FormControl('', [Validators.required, this.editor()]),
        sellingPrice: new FormControl('', [Validators.required]),
        showSellingPrice: new FormControl(true),
        discount: new FormControl('', [
          Validators.pattern('^[1-9][0-9]?$|^100$'),
          Validators.min(0),
        ]),
        brandName: new FormControl('', [
          Validators.maxLength(15),
        ]),
        active: new FormControl(true),
      }),
      productCategory: new FormGroup({
        categoryId: new FormControl(''),
        selectedCategory: new FormControl('', Validators.required),
      }),
      productImage: new FormGroup({
        coverImageFileName: new FormControl(''),
        CoverImageId: new FormControl(''),
      }),
      productVideo: new FormGroup({
        productVideofiles: new FormControl(''),
      }),
    });
  }
  private InitializeCategoryForm() {
    this.categoryform = new FormGroup({
      userID: new FormControl(this.userId),
      categoryName: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
      ]),
      description: new FormControl(''),
      active: new FormControl(true),
    });
  }
  get categoryValue() {
    return this.categoryform.controls;
  }
  showDialog() {
    // this.isCategoryFormsubmitted = false;
    // this.InitializeCategoryForm();
    // this.display = true;
    this.router.navigateByUrl("/dashboard/addCategory");
  }
  AddCategory() {
    this.isCategoryFormsubmitted = true;
    this.SpinnerService.show();
    if (this.categoryform.invalid) {
      this.SpinnerService.hide();
      return;
    } else {
      const addCategory = new FormData();
      addCategory.append('categoryName', this.categoryform.value.categoryName);
      addCategory.append('description', this.categoryform.value.description);
      addCategory.append('active', this.categoryform.value.active);
      addCategory.append('userID', this.productCategories[0].userID);
      addCategory.append('language',this.languageName)
      this.categoryService.create(addCategory).subscribe(
        (res: any) => {
          this.GetAllActiveCategoryByStoreId();
          const translatedMessage = this.translate.instant('toastr.success.createcategory');
          this.toastr.success(translatedMessage);
       
          this.isCategoryFormsubmitted = false;
          this.SpinnerService.hide();
          this.display = false;
        },
        (error: any) => {
          const translatedMessage = this.translate.instant('toastr.error.duplicatecategory');
          this.toastr.error(translatedMessage);
   
          this.SpinnerService.hide();
        }
      ),
        (error: any) => {
          this.SpinnerService.hide();
          const translatedMessage = this.translate.instant('toastr.error');
          this.toastr.error(translatedMessage);
      
        };
    }
  }
  loadCategories() {

    this.categoryService.getAllActiveCategory(this.languageName).subscribe((data: any) => {
      this.productCategories = data;
    }),
      (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
       
      };
  }

  GetStoreBYStoreId() {
    this.productService
      .GetStoreByStoreId(this.ProductToSave.storeId)
      .subscribe((data: any) => {
        this.StoreToSave = data;
      });
  }

  UpdateProduct(isStepCall) {
    this.isFormSubmitted = true;
    if (isStepCall) {
      this.validateSteps();
      if (this.step != 4) return;
    } else {
      var mobViewValidate = this.validateforMobileView();
      if (mobViewValidate == false) return false;
    }
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append('StoreId', `${this.ProductToSave.storeId}`);
    product.append('CategoryId', `${this.selectedCategory}`);
    product.append('Name', `${this.productform.value.productDetail.name}`);
    product.append(
      'Description',
      `${this.productform.value.productDetail.description}`
    );
    product.append(
      'SellingPrice',
      `${this.productform.value.productDetail.sellingPrice}`
    );
    product.append(
      'ShowSellingPrice',
      `${this.productform.value.productDetail.showSellingPrice}`
    );
    product.append(
      'Discount',
      `${this.productform.value.productDetail.discount ?? 0}`
    );
    product.append(
      'BrandName',
      `${this.productform.value.productDetail.brandName}`
    );
    product.append('Active', `${this.productform.value.productDetail.active}`);
    product.append(
      'CoverImageId',
      `${this.productform.value.productImage.CoverImageId ?? 0}`
    );

    if (
      this.productform.value.productImage.coverImageFileName === '' ||
      this.productform.value.productImage.coverImageFileName == null ||
      this.productform.value.productImage.coverImageFileName == false
    ) {
      product.append('coverImageFileName', `null`);
    } else {
      product.append(
        'coverImageFileName',
        `${this.productform.value.productImage.coverImageFileName}`
      );
    }

    for (let index = 0; index < this.sourcesFiles.length; index++) {
      product.append('ProductImagefiles', this.sourcesFiles[index]);
    }
    for (let index = 0; index < this.videos.length; index++) {
      product.append('ProductVideofiles', this.videos[index]);
    }

    if (
      this.ProductToSave.imagesToBeDeleted == null ||
      this.ProductToSave.imagesToBeDeleted.length < 0
    ) {
      product.append('imagesToBeDeleted', '0');
    } else {
      for (var i = 0; i < this.ProductToSave.imagesToBeDeleted.length; i++) {
        product.append(
          'imagesToBeDeleted[' + i + ']',
          `${this.ProductToSave.imagesToBeDeleted[i]}`
        );
      }
    }

    if (
      this.ProductToSave.videosToBeDeleted == null ||
      this.ProductToSave.videosToBeDeleted.length < 0
    ) {
      product.append('videosToBeDeleted', '0');
    } else {
      for (var i = 0; i < this.ProductToSave.videosToBeDeleted.length; i++) {
        product.append(
          'videosToBeDeleted[' + i + ']',
          `${this.ProductToSave.videosToBeDeleted[i]}`
        );
      }
    }

    var imgcnt = this.ProductToSave.productImages.length;
    var newImgFile = this.sourcesFiles.length;
    if (imgcnt === 0 && newImgFile === 0) {
      this.SpinnerService.hide();
      return;
    }

    this.productService.UpdateProduct(product).subscribe(
      (data: any) => {
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
        this.ProductToSave = data;

        const translatedMessage = this.translate.instant('toastr.success.productupdate');
        this.toastr.success(translatedMessage);
        this.redirectToProduct();
      },
      (error) => {
        if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error.productduplicate');
          this.toastr.error(translatedMessage);
       
          this.SpinnerService.hide();
        } else {
          const translatedMessage = this.translate.instant('toastr.error.productupdate');
          this.toastr.error(translatedMessage);
     
          this.SpinnerService.hide();
        }
      }
    );
  }
  UpdateProductDetails(evt:any) {
    this.ispdFormSubmitted = true;

    this.without_html = this.removeHTMLTags(this.removeHTMLEntities(this.removeHTMLNumbers(this.productform.value.productDetail.description)));

    if(this.without_html.length>500){
      this.maxlengthError="Product description maxlength is 500";
    }
    if(this.without_html.length<500 || this.without_html.length===500)
   {
    this.maxlengthError="";
   }
    if(this.maxlengthError!=""){
      this.productform.controls.productdetail.status == 'INVALID'
      if (this.productform.controls.productDetail.invalid) {
        if (this.selectdTab !== 'pd') {
          const translatedMessage = this.translate.instant('toastr.error.productdetailrequired');
          this.toastr.error(translatedMessage);

        }
  
        return false;
      }
    }
    
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append('StoreId', `${this.ProductToSave.storeId}`);
    product.append('Name', `${this.productform.value.productDetail.name}`);
    product.append(
      'Description',
      `${this.productform.value.productDetail.description}`
    );
    product.append(
      'SellingPrice',
      `${this.productform.value.productDetail.sellingPrice}`
    );
    product.append(
      'ShowSellingPrice',
      `${this.productform.value.productDetail.showSellingPrice}`
    );
    product.append(
      'Discount',
      `${this.productform.value.productDetail.discount ?? 0}`
    );
    product.append(
      'BrandName',
      `${this.productform.value.productDetail.brandName}`
    );
    product.append('Active', `${this.productform.value.productDetail.active}`);
    

    this.productService.UpdateProductDetails(product).subscribe(
      (data: any) => {
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
       
        this.activeTab(evt, this.selectdTab);
      },
      (error) => {
        if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error.productduplicate');
          this.toastr.error(translatedMessage);
     
          this.SpinnerService.hide();
        } else {
          const translatedMessage = this.translate.instant('toastr.error.productupdate');
          this.toastr.error(translatedMessage);
       
          this.SpinnerService.hide();
        }
        this.selectdTab = 'pd';
      }
    );
  }

  UpdateProductCategory(evt: any) {
    this.ispdFormSubmitted = true;
    
    if (this.productform.controls.productCategory.invalid) {
      if (this.selectdTab !== 'pc') {
        const translatedMessage = this.translate.instant('toastr.error.productcategoryrequired');
        this.toastr.error(translatedMessage);
     
      }
      return false;
    }
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append('StoreId', `${this.ProductToSave.storeId}`);
    product.append('CategoryId', `${this.selectedCategory}`);
    
    this.productService.AddNewProductCategory(product).subscribe(
      (data: any) => {
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
  
        this.activeTab(evt, this.selectdTab);
      },
      (error) => {
        if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error.updateproductcategory');
          this.toastr.error(translatedMessage);
     
          this.SpinnerService.hide();
        } else {
          const translatedMessage = this.translate.instant('toastr.error.updateproductcategory');
          this.toastr.error(translatedMessage);

          this.SpinnerService.hide();
        }
        this.selectdTab = 'pc';
      }
    );
  }

  UpdateProductImages(evt:any) {
    this.ispdFormSubmitted = true;
    this.formuiSubmit = false;
    if (this.ProductToSave.productImages.length == 0) {
      if (this.selectdTab !== 'ui') {
        const translatedMessage = this.translate.instant('toastr.error.productimagerequired');
        this.toastr.error(translatedMessage);
     
      }
      return false;
    } else {
      var validCoverimage = this.validateCoverImage();
      if (validCoverimage == false) {
        if (this.selectdTab !== 'ui') {
          const translatedMessage = this.translate.instant('toastr.error.coverimagerequired');
          this.toastr.error(translatedMessage);

        }

        return false;
      }
    }
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append('StoreId', `${this.ProductToSave.storeId}`);
    product.append(
      'CoverImageId',
      `${this.productform.value.productImage.CoverImageId ?? 0}`
    );

    if (
      this.productform.value.productImage.coverImageFileName === '' ||
      this.productform.value.productImage.coverImageFileName == null ||
      this.productform.value.productImage.coverImageFileName == false
    ) {
      product.append('coverImageFileName', `null`);
    } else {
      product.append(
        'coverImageFileName',
        `${this.productform.value.productImage.coverImageFileName}`
      );
    }

    for (let index = 0; index < this.sourcesFiles.length; index++) {
      product.append('ProductImagefiles', this.sourcesFiles[index]);
    }

    if (
      this.ProductToSave.imagesToBeDeleted == null ||
      this.ProductToSave.imagesToBeDeleted.length < 0
    ) {
      product.append('imagesToBeDeleted', '0');
    } else {
      for (var i = 0; i < this.ProductToSave.imagesToBeDeleted.length; i++) {
        product.append(
          'imagesToBeDeleted[' + i + ']',
          `${this.ProductToSave.imagesToBeDeleted[i]}`
        );
      }
    }

    var imgcnt = this.ProductToSave.productImages.length;
    var newImgFile = this.sourcesFiles.length;
    if (imgcnt === 0 && newImgFile === 0) {
      this.SpinnerService.hide();
      return;
    }
    
    this.productService.UpdateProductImages(product).subscribe(
      (data: any) => {
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
     
        this.deletedImages=[];
        this.ProductToSave.productImages = [];
        this.sourcesFiles = [];
        this.sourcesFile = [];
        this.productService.GetProductByProductId(this.id).subscribe(
          (data: any) => {
            this.ProductToSave = new ProductModel();
            this.ProductToSave = data;
            this.GetAllActiveCategoryByStoreId();
            this.ProductToSave.productImages.length = this.ProductToSave.productImages == undefined
             ? 0
            : this.ProductToSave.productImages.length
            this.prodImgCnt = this.ProductToSave.productImages.length;
            this.selectedCategory = data.categoryId;
            this.productform.get('productImage.coverImageFileName').setValue(false);
            this.productform
              .get('productDetail.name')
              .setValue(this.ProductToSave.name);
            this.productform
              .get('productDetail.description')
              .setValue(this.ProductToSave.description);
            var sp = (
              Math.round(this.ProductToSave.sellingPrice * 100) / 100
            ).toFixed(2);
            if (sp.length > 8) {
              sp = Math.trunc(this.ProductToSave.sellingPrice).toString();
            }
    
            this.productform.get('productDetail.sellingPrice').setValue(sp);
            this.productform
              .get('productDetail.showSellingPrice')
              .setValue(this.ProductToSave.showSellingPrice);
            this.productform
              .get('productDetail.discount')
              .setValue(this.ProductToSave.discount);
    
            this.productform
              .get('productDetail.brandName')
              .setValue(this.ProductToSave.brandName);
            this.productform
              .get('productDetail.active')
              .setValue(this.ProductToSave.active);
    
            this.productform
              .get('productCategory.categoryId')
              .setValue(this.ProductToSave.categoryId);
            this.productform
              .get('productCategory.selectedCategory')
              .setValue(this.selectedCategory);
            this.productform
              .get('productImage.CoverImageId')
              .setValue(this.ProductToSave.coverImageId);
            this.GetStoreBYStoreId();
            this.SpinnerService.hide();
         
          },
          (error) => {
            this.SpinnerService.hide();
          }
        );
        this.activeTab(evt, this.selectdTab);
        this.formuiSubmit = true;
        
      },
      (error) => {
        if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error.updateproductimage');
          this.toastr.error(translatedMessage);
       
          this.SpinnerService.hide();
        } else {
          const translatedMessage = this.translate.instant('toastr.error.updateproductimage');
          this.toastr.error(translatedMessage);
   
          this.SpinnerService.hide();
        }
        this.selectdTab = 'ui';
      }
    );
  }

  UpdateProductVideo() {
    this.ispdFormSubmitted = true;

    if (this.productform.status == 'INVALID') {
      this.SpinnerService.hide();
      const translatedMessage = this.translate.instant('toastr.error.requiredfield');
      this.toastr.error(translatedMessage);
    
      return false;
    }
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append('StoreId', `${this.ProductToSave.storeId}`);
    for (let index = 0; index < this.videos.length; index++) {
      product.append('ProductVideofiles', this.videos[index]);
    }
    if (
      this.ProductToSave.videosToBeDeleted == null ||
      this.ProductToSave.videosToBeDeleted.length < 0
    ) {
      product.append('videosToBeDeleted', '0');
    } else {
      for (var i = 0; i < this.ProductToSave.videosToBeDeleted.length; i++) {
        product.append(
          'videosToBeDeleted[' + i + ']',
          `${this.ProductToSave.videosToBeDeleted[i]}`
        );
      }
    }
    
    this.productService.UpdateProductVideo(product).subscribe(
      (data: any) => {
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
        this.ProductToSave = data;
        const translatedMessage = this.translate.instant('toastr.success.productupdate');
        this.toastr.success(translatedMessage);
    
        
        this.redirectToProduct();
      },
      (error) => {
        if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error.updateproductvideo');
          this.toastr.success(translatedMessage);
  
          this.SpinnerService.hide();
        } else {
          const translatedMessage = this.translate.instant('toastr.error.updateproductvideo');
          this.toastr.success(translatedMessage);
      
          this.SpinnerService.hide();
        }
        this.selectdTab = 'uv';
      }
    );
  }
  removeHTMLNumbers(str: string): string {
    return str.replace(/&#?\w+;/g, '');
  }

  removeHTMLEntities(str: string): string {
    const element = document.createElement('div');
    element.innerHTML = str;
    return element.textContent || element.innerText || '';
  }

  removeHTMLTags(str: string): string {
    return str.replace(/<[^>]*>/g, '');
  }

  validateCoverImage() {
    var imgcnt = this.ProductToSave.productImages.length;
    var newImgFile = this.sourcesFiles.length;
    
    if (imgcnt === 0 && newImgFile === 0) {
      this.SpinnerService.hide();
      return;
    }

    if (this.ProductToSave.productImages.length > 0) {
      if (
        (this.productform.value.productImage.coverImageFileName === '' ||
          this.productform.value.productImage.coverImageFileName == null ||
          this.productform.value.productImage.coverImageFileName == false) &&
        (this.productform.value.productImage.CoverImageId == 0  || this.productform.value.productImage.CoverImageId == null)
      ) {
        this.coverimg = false;
     
        this.SpinnerService.hide();
        return false;
      }

      if (
        this.productform.value.productImage.CoverImageId > 0 &&
        this.ProductToSave.imagesToBeDeleted !== null
      ) {
        var iscovImgDeleted = false;
        this.coverimg = true;
        for (var i = 0; i < this.ProductToSave.imagesToBeDeleted.length; i++) {
          var covImgId = this.ProductToSave.imagesToBeDeleted[i];
          if (this.productform.value.productImage.CoverImageId === covImgId) {
            iscovImgDeleted = true;
            break;
          }
        }
        if (
          iscovImgDeleted &&
          this.productform.value.productImage.coverImageFileName == ''
        ) {
          this.coverimg = false;
       
          this.SpinnerService.hide();
          return false;
        }
      }
    }
    this.coverimg = true
    return true;
  }

  validateSteps() {
    this.isFormSubmitted = true;
    this.maxlengthError="";
    if (this.productform.controls.productDetail.invalid && this.step == 1) {
      return;
    }
    if (this.isFormSubmitted && this.step == 1) {
      this.step1 = true;
    }
    if (this.isFormSubmitted && this.step == 2) {
      this.step1 = false;
      this.step2 = true;
    }
    if (this.isFormSubmitted && this.step == 3) {
      this.step2 = false;
    }
    if (this.productform.controls.productCategory.invalid && this.step == 2) {
      return;
    }

    if (
      this.ProductToSave.productImages.length === 0 &&
      this.sourcesFile.length === 0 &&
      this.step === 3
    )
      return;

    if (this.step === 3) {
      var validCoverimage = this.validateCoverImage();
      if (validCoverimage == false) return false;
    }

    this.step = this.step < 4 ? this.step + 1 : this.step;
  }

  validateforMobileView() {
    this.isFormSubmitted = true;
   

    
    if (this.productform.controls.productCategory.invalid) {
      if (this.selectdTab !== 'pc') {
        const translatedMessage = this.translate.instant('toastr.error.productcategoryrequired');
        this.toastr.success(translatedMessage);
 
      }

      return false;
    }
    if (this.ProductToSave.productImages.length == 0) {
      if (this.selectdTab !== 'ui') {
        const translatedMessage = this.translate.instant('toastr.error.productimagerequired');
        this.toastr.success(translatedMessage);
    
      }
      return false;
    } else {
      var validCoverimage = this.validateCoverImage();
      if (validCoverimage == false) {
        if (this.selectdTab !== 'ui') {
          const translatedMessage = this.translate.instant('toastr.error.coverimagerequired');
          this.toastr.success(translatedMessage);
       
        }

        return false;
      }
    }

    if (this.productform.status == 'INVALID') {
      this.SpinnerService.hide();
      const translatedMessage = this.translate.instant('toastr.error.requiredfield');
      this.toastr.success(translatedMessage);
   
      return false;
    }

    return true;
  }

  removeImage(url: any) {
    var deletedFilename = url.fileinfo.name;
    if(deletedFilename == this.productform.value.productImage.coverImageFileName || this.ProductToSave.coverImageFileName == deletedFilename){
      this.productform.value.coverImageId = null;
      this.productform.value.productImage.coverImageFileName = null;
      this.productform.value.productImage.CoverImageId = null;
      this.productform.value.productImage.coverImageFileName = false;
      this.productform.value.productImage.CoverImageId = null
      this.productform.get('productImage.coverImageFileName').setValue(false);
 
      this.ProductToSave.coverImageFileName = null;

    }
    for (var i = 0; i < this.ProductToSave.productImages.length; i++) {
      var fileobj = this.ProductToSave.productImages[i];
      if (fileobj.name == deletedFilename) {
        this.ProductToSave.productImages.splice(i, 1);
      }
    }

    for (var i = 0; i < this.sourcesFile.length; i++) {
      var fileobj1 = this.sourcesFile[i];
      if ( this.sourcesFile[i].fileinfo.name == deletedFilename) {
        this.sourcesFile.splice(i, 1);
      }
    }

    for (var i = 0; i < this.sourcesFiles.length; i++) {
      var fileobj2 = this.sourcesFiles[i];
      if (fileobj2.name == deletedFilename) {
        this.sourcesFiles.splice(i, 1);
      }
    }
  }

  removeEditImage(i: number, imgId: number) {
    this.deletedImages.push(imgId);
    if(imgId.toString() ==this.ProductToSave.coverImageId){
      this.ProductToSave.coverImageId = null;
      this.productform.value.productImage.CoverImageId = null;
      this.productform.value.productImage.coverImageFileName = false;
      this.productform.value.productImage.CoverImageId = null}
    this.ProductToSave.imagesToBeDeleted = this.deletedImages;
    this.ProductToSave.productImages.splice(i, 1);
  }

  onSelectedImage(event: any) {
    var existingFileCnt =
      this.ProductToSave.productImages == undefined
        ? 0
        : this.ProductToSave.productImages.length;
    var newFileCnt = 0;
    var currentFileCnt =
      event.target.files == undefined ? 0 : event.target.files.length;
    var totalFileCnt = existingFileCnt + newFileCnt + currentFileCnt;
    if (totalFileCnt > 4) {
      const translatedMessage = this.translate.instant('toastr.error.imagecount');
      this.toastr.error(translatedMessage);


      return;
    }
    if (event.target.files.length > 0) {
      var filesCount = event.target.files.length;
      for (let i = 0; i < filesCount; i++) {
        if (event.target.files[0].size > 2097152) {
          const translatedMessage = this.translate.instant('toastr.error.imagesize');
          this.toastr.error(translatedMessage);
      
          return;
        } else if (!this.validateFile(event.target.files[i].name)) {
          const translatedMessage = this.translate.instant('toastr.error.imageformat');
          this.toastr.error(translatedMessage);
      

          this.productform.controls['productImagefiles'].reset();
          return false;
        }else if(this.sourcesFiles.length > 0 && this.duplicateFileName(event.target.files[i].name) === false ){
          const translatedMessage = this.translate.instant('toastr.error.duplicatefile');
          this.toastr.error(translatedMessage);
;
          return false;
        } else {
          var reader = new FileReader();
          reader.onload = (ee: any) => {
            var fileData = {
              fileinfo: event.target.files[i],
              fileResult: ee.target.result,
            };
            this.sourcesFile.push(fileData);
          };
          reader.readAsDataURL(event.target.files[i]);

          this.sourcesFiles.push(event.target.files[i]);
          this.ProductToSave.productImages.push(event.target.files[i]);
          if(this.productform.value.productImage.coverImageFileName == event.target.files[i].name){
            this.productform.value.productImage.coverImageFileName = false;
          }
        }
        const fileInput = event.target;

        fileInput.addEventListener('click', () => {
          fileInput.value = null;
        });
      }
    }
  }
duplicateFileName(name: String){
  
  for(let j = 0; j < this.sourcesFiles.length; j++){
    if(name == this.sourcesFiles[j].name){
 
      
        return false;
      }
  }
}
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg'
    ) {
      return true;
    } else {
      return false;
    }
  }

  removeVideoEdit(i: any, video: any) {
    this.videoDeleteFrom.value.id = i;
    this.videoDeleteFrom.value.video = video;
  }
  removevideo(i: number) {
    this.videos.splice(i, 1);
    this.video.splice(i, 1);
    this.inputVideoFile.nativeElement.value = null;
  }
  removeEditvideo(i: number, videoId: number) {
    this.deletedVideos.push(videoId);
    this.ProductToSave.videosToBeDeleted = this.deletedVideos;
    this.ProductToSave.productVideos.splice(i, 1);
  }
  onSelectVideo(event: any) {
    var existingFileCnt =
      this.ProductToSave.productVideos == undefined
        ? 0
        : this.ProductToSave.productVideos.length;
    var newFileCnt = this.videos == undefined ? 0 : this.videos.length;
    var currentFileCnt =
      event.target.files == undefined ? 0 : event.target.files.length;
    var totalFileCnt = existingFileCnt + newFileCnt + currentFileCnt;
    if (totalFileCnt > 1) {
      const translatedMessage = this.translate.instant('toastr.error.videocount');
      this.toastr.error(translatedMessage);

      return;
    }
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size <= 4404020) {
        var filesCount = event.target.files.length;
        for (let i = 0; i < filesCount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.video.push(event.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      } else {
        const translatedMessage = this.translate.instant('toastr.error.videosize');
        this.toastr.error(translatedMessage);
    
        return;
      }
      this.videos.push(event.target.files[0]);
    }
  }
  openTab(evt: any, tab) {
    this.selectdTab = tab;
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    document.getElementById(tab).style.display = 'block';
    evt.currentTarget.className += ' active';
  }

  openNextTab(evt: any, tab) {
    this.selectdTab = tab;
    if(this.selectdTab=='pc'){
      this.ispdFormSubmitted=true;
      this.without_html = this.removeHTMLTags(this.removeHTMLEntities(this.removeHTMLNumbers(this.productform.value.productDetail.description)));
      if(this.without_html.length > 500){
        this.maxlengthError="Product description maxlength is 500";
      }
      if (this.productform.controls.productDetail.invalid || this.without_html.length > 500) {
        this.selectdTab='pd';
        this.currentTab = this.selectdTab;
           return;
      }
      this.UpdateProductDetails(evt);
    }
    if(this.selectdTab=='ui'){
      this.ispcFormSubmitted=true;
      if (this.productform.controls.productCategory.invalid) {
        this.selectdTab='pc';
        this.currentTab = this.selectdTab;
              return;
      }
      this.UpdateProductCategory(evt); 
    }
    if(this.selectdTab=='uv'){
      this.isuiFormSubmitted=true;
      if (this.ProductToSave.productImages.length == 0)
      {this.selectdTab='ui'; 
      this.currentTab = this.selectdTab;
      return;
      
    }
    this.step = 3;
    this.coverimg = this.validateCoverImage();
    if (this.coverimg == false){
        this.selectdTab='ui';
        this.currentTab = this.selectdTab;
         return;
      }
     
      this.UpdateProductImages(evt);
    }

  }
  activeTab(evt: any, tab){
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    document.getElementById(tab).style.display = 'block';
    if(tab=='pd'){tablinks[0].className = 'tablinks active'}
    if(tab=='pc'){tablinks[1].className = 'tablinks active'}
    if(tab=='ui'){tablinks[2].className = 'tablinks active'}
    if(tab=='uv'){tablinks[3].className = 'tablinks active'}
 
  }

  get f() {
    return this.productform.controls;
  }
  get productDetail() {
    return this.productform.controls['productDetail']['controls'];
  }
  get productCategory() {
    return this.productform.controls['productCategory']['controls'];
  }
  get productImage() {
    return this.productform.controls['productImage']['controls'];
  }

  previous() {
    this.step = this.step - 1;
  }

  onCancelClick() {
    this.redirectToProduct();
  }
  redirectToProduct() {
    var sid = this.ProductToSave.storeId;
    var uid = this.selectedUserId;
    this.router.navigate(['dashboard/product'], { queryParams: { sid, uid } });
  }
  onCategoryClick() {
    this.router.navigateByUrl('dashboard/category');
  }

  Back(): void {
    this.router.navigateByUrl('/dashboard/product');
  }

  ngOnDestroy(): void {
    document
      .getElementById('tempid_Product')
      .classList.remove('highlight_sideMenu');
  }

  DeselectRadio(imageType) {
    
    if (imageType === 'new') {

      this.productform.get('productImage.CoverImageId').setValue(0);

    }
    if (imageType === 'old') {
      this.productform.get('productImage.coverImageFileName').setValue(false);
    }
  }
  onInput(event: any) {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.data);
    if (!pattern.test(inputChar)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }

  limitInputLength(event: any) {
    const input = event.target as HTMLInputElement;

    if (input.value.length > 8) {
      input.value = input.value.slice(0, 8);
    }
  }
  preventExceedingMaxLength(event: any) {
    const input = event.target as HTMLInputElement;
    const maxLength = 8;
    const value = input.value;

    if (
      value.length >= maxLength &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      event.preventDefault();
    }
  }
  editor(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      var value = control.value;
      let without_html = value.replace(/<(?:.|\n)*?>/gm, ' ');
      if (without_html === '   ') {
        return { lengthError: true };
      } else {
        return null;
      }
    };
  }
  pastepreventExceedingMax(event: any) {
    const into = event.clipboardData.getData('text/plain');

    let without_html = this.productform.value.productDetail.description.replace(
      /<(?:.|\n)*?>/gm,
      ' '
    );

    without_html = without_html + into;

    if (without_html.length > 500) {
      event.prevehntDefault();
    }
  }

  preventExceedingMax(event: any) {
    const input = event.target as HTMLInputElement;
    const maxLength = 499;
    if (
      input.innerText.length > maxLength &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      event.preventDefault();
    }
  }

  descriptionCharCount(event) {
    const count = event.target.innerText.length; 
    if (count > 500) {
      event.preventDefault();
      this.productform.patchValue({ description: '' });
    }
  }

  pastepreventnegativevalue(event: any) {
    const into = event.clipboardData.getData('text/plain');
    const substring = '-';
    into.includes(substring);
    if (into.includes(substring)) {
      event.prevehntDefault();
    }
  }
  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  restrictInputMobile(event: any) {

    this.maxlengthError="";
  }

  getPlaceholderText() {
    return this.translate.instant('updateproduct.editor.placeholder');
  }

}
