<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid main-content">
    <div class="row mobile-view-border">
      <div class="col-md-1"></div>
      <div class="col-md-9">
        <form
          [formGroup]="changePasswordForm"
          (ngSubmit)="onChangePassword()"
          (keydown.enter)="$event.preventDefault()"
        >
          <div class="col-sm-8">
            <label for="exampleInputPassword1" class="control-label mt-2"
              >{{ "changepassword.oldpassword.label" | translate
              }}<span class="required">*</span></label
            >
            <div class="password">
              <input
                [type]="show_password ? 'text' : 'password'"
                class="form-control password-box"
                id="exampleInputPassword1"
                formControlName="oldpassword"
                placeholder="{{
                  'changepassword.oldpassword.placeholder' | translate
                }}"
                [focusMe]="true"
              />
              <span class="eyeicon">
                <i
                  [class]="show_eyepassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  (click)="showPassword()"
                ></i
              ></span>
            </div>
            <div
              class="text-red text-12 font-weight-bold mt-1"
              *ngIf="
                FormSubmitted &&
                funchangePasswordForm.oldpassword.errors?.required
              "
            >
              {{ "validation.oldpassword.required" | translate }}
            </div>
            <div
              class="text-red text-12 font-weight-bold"
              *ngIf="funchangePasswordForm.oldpassword.errors?.minlength"
            >
              {{ "validation.oldpassword.minlength" | translate }}
            </div>
            <label class="control-label mt-2"
              >{{ "changepassword.newpassword.label" | translate
              }}<span class="required"> *</span></label
            >
            <div class="password">
              <input
                [type]="show_newpassword ? 'text' : 'password'"
                class="form-control password-box"
                id="exampleInputPassword1"
                formControlName="newpassword"
                placeholder="{{
                  'changepassword.newpassword.placeholder' | translate
                }}"
              />
              <span class="eyeicon">
                <i
                  [class]="
                    show_eyeNewpassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                  "
                  (click)="showNewPassword()"
                ></i
              ></span>
            </div>
            <div
              class="text-red text-12 font-weight-bold mt-1"
              *ngIf="
                FormSubmitted &&
                funchangePasswordForm.newpassword.errors?.required
              "
            >
              {{ "validation.newpassword.required" | translate }}
            </div>
            <div
              class="text-red text-12 font-weight-bold"
              *ngIf="funchangePasswordForm.newpassword.errors?.minlength"
            >
              {{ "validation.newpassword.minlength" | translate }}
            </div>
            <div
              class="text-red text-12 font-weight-bold"
              *ngIf="
                funchangePasswordForm.newpassword.errors?.passwordValidator
              "
            >
              {{ "validation.newpassword.comparetype" | translate }}
            </div>
            <label class="control-label mt-2"
              >{{ "changepassword.confirmpassword.label" | translate
              }}<span class="required">*</span></label
            >
            <div class="password">
              <input
                [type]="show_confirmpassword ? 'text' : 'password'"
                class="form-control password-box"
                id="exampleInputPassword1"
                formControlName="confirmpassword"
                placeholder="{{
                  'changepassword.confirmpassword.placeholder' | translate
                }}"
              />
              <span class="eyeicon">
                <i
                  [class]="
                    show_eyeconfirmpassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                  "
                  (click)="showConfirmPassword()"
                ></i
              ></span>
            </div>
            <div
              class="text-red text-12 font-weight-bold mt-1 mb-3"
              *ngIf="
                FormSubmitted &&
                funchangePasswordForm.confirmpassword.errors?.required
              "
            >
              {{ "validation.confirmpassword.required" | translate }}
            </div>
            <div
              class="text-red text-12 font-weight-bold mb-3"
              *ngIf="
                funchangePasswordForm.confirmpassword.errors
                  ?.confirmPasswordValidator
              "
            >
              {{
                "validation.confirmpassword.confirmpasswordvalidator"
                  | translate
              }}
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="mb-4 mt-3">
      <div class="row btn-position">
        <div class="col-md-1"></div>
        <div class="col-md-11 mobile-button-padding desktop-button-padding">
          <button
            type="cancel"
            class="btn btn-colour-cancel mobile-btn-save-cancel"
            (click)="onCancelClick()"
          >
            {{ "changepassword.button.cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-colour mobile-btn-save"
            (click)="onChangePassword()"
          >
            {{ "changepassword.button.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
