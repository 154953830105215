
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StoreAPIService } from '../storeapiservice.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '@services/profile.service';
import { HelperService } from '@/utils/Helper';
import { ShareimageService } from '@services/shareimage.service';
import { MobileNumberValidator } from '../mobilrnumber.validator';
import intlTelInputObject from 'intl-tel-input';
import intlTelInput from 'intl-tel-input';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';



@Component({
  selector: 'app-addStore',
  templateUrl: './addStore.component.html',
  styleUrls: ['./addStore.component.css']
})

export class AddStoreComponent implements OnInit, OnDestroy {
  @ViewChild('phoneInput') phoneInput: ElementRef;
  @ViewChild('phoneInput1') phoneInput1: ElementRef;
  selectedUserId: any;
  isActive: boolean = true;
  storeform!: FormGroup;
  imageSrc: string;
  sourcesFile: any[] = [];
  public isFormSubmitted: boolean = false;
  UserName: string;
  mobileNumber: string;
  users: any[];
  selectedUser: any;
  isSuperAdmin: boolean;
  userId: any;
  languageName: any;
  isSubmitted: boolean = false;
  iti: intlTelInput.Plugin;
  iti1: intlTelInput.Plugin;
  options: intlTelInput.Options = { separateDialCode: true, autoInsertDialCode: true, utilsScript: "./assets/js/intl-tel-input/utils.js", nationalMode: false, formatOnDisplay: false, autoPlaceholder: 'off' }
  whatsappNumberError: string;
  contactNumberError: string;
  apiResponse: any;
  urlmessage: string;
  storeurl:any;
   url:any;
  constructor(
    public storeService: StoreAPIService,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private SpinnerService: NgxSpinnerService,
    private profileservice: ProfileService,
    private shareimageService: ShareimageService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
    this.CreateFormControls();
  }


  ngOnInit(): void {
    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    document.getElementById("tempid_Store")?.classList.add("highlight_sideMenu");
    this.shareimageService.setPageName("Stores");
    this.storeform.controls['storeUrl'].valueChanges.subscribe(() => {
      // Clear all error messages
      this.urlmessage = '';
  });
    this.LoadUsers();
  }
  ngAfterViewInit() {

    this.iti = intlTelInputObject(this.phoneInput.nativeElement, this.options);
    this.iti1 = intlTelInputObject(this.phoneInput1.nativeElement, this.options);
    this.iti.setCountry('in');
    this.iti1.setCountry('in');
  }

  private CreateFormControls() {
    const reg = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    this.storeform = this.formBuilder.group({
      UserId: new FormControl(this.userId),
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      contactNumber: new FormControl('', [Validators.pattern(/^\S+$/)]),
      whatsAppNumber: new FormControl(this.mobileNumber, [Validators.required, Validators.pattern(/^\S+$/)]),
      email: new FormControl('', [Validators.maxLength(60), Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      address: new FormControl(''),
      facebookUrl: new FormControl('', Validators.pattern(reg)),
      instagramUrl: new FormControl('', Validators.pattern(reg)),
      GSTNo: new FormControl('', [Validators.pattern("^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})+$")]),
      active: new FormControl(true),
      storeUrl: new FormControl('',[Validators.required])
    },
      {
        validator: MobileNumberValidator("whatsAppNumber", "contactNumber")
      }
    );

  }

  onInputWhatsappNumber(event: any) {
       this.whatsappNumberError = "";
   
    if (/(\d)\1{5,}/.test(event.target.value)) {
      this.whatsappNumberError ="Invalid number"
    }


  }
  onInputContactNumber(event: any) {
    this.contactNumberError = "";
   
    if (/(\d)\1{5,}/.test(event.target.value)) {
      this.contactNumberError ="Invalid number"
    }
  }
  private LoadUsers() {

    //server get the userid from token and return user information
    this.profileservice.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
      this.userId = data.id;
      this.mobileNumber = data.isSuperAdmin ? '' : data.mobileNumber;
      this.UserName = data.isSuperAdmin ? '' : data.email;
      const mobilenumberArray = this.mobileNumber.split(" ");
      if (mobilenumberArray.length > 1) {
        this.storeform.controls['whatsAppNumber'].setValue(mobilenumberArray[1]);
        this.iti.setNumber(this.mobileNumber.trim());
      } else {
        this.storeform.controls['whatsAppNumber'].setValue(this.mobileNumber);
      }

      if (this.isSuperAdmin === false) {
        this.storeform.controls['email'].setValue(data.email);
      }

      if (this.isSuperAdmin) {

        this.storeform.addControl('username', new FormControl('', Validators.required));
        //if user is super admin then populate user email address in dropdown
        this.profileservice.GetUsersForDropdown().subscribe((data: any) => {
          this.users = data;
        });
        this.getNotification();
      }
      else {
        this.storeform.removeControl("username");
      }

    }, (err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);

    });
  }

  get f() {
    return this.storeform.controls;
  }
  getNotification() {
    this.profileservice.getNotification().subscribe((data: any) => {
      this.shareimageService.setnotification(data)
      localStorage.removeItem('notificationDataUserId');
      localStorage.removeItem('notificationDataUserName');
    });
  }

  CreateNewStore() {

    if(this.whatsappNumberError!= "" && this.whatsappNumberError!= undefined){
       return
     }
     if( this.contactNumberError != "" && this.contactNumberError!= undefined){
    return
     }
    this.isSubmitted = true;
    this.SpinnerService.show();
    this.isFormSubmitted = true;
    this.whatsappNumberError = "";
    this.contactNumberError = "";
    if (this.storeform.status == 'INVALID') {
      this.isSubmitted = false;
      this.SpinnerService.hide();
      return
    }
    if (!this.iti.isValidNumber()) {
      console.log(this.iti.isValidNumber())
      this.whatsappNumberError = this.getWhatsappNumberErrorMessage(this.iti.getValidationError());
      this.SpinnerService.hide();
    }
    if (this.storeform.value.contactNumber != "") {
      if (!this.iti1.isValidNumber()) {
        console.log("iti1", this.iti1.isValidNumber())
        this.contactNumberError = this.getContactNumberErrorMessage(this.iti1.getValidationError());
        this.SpinnerService.hide();
      }
    }
    if (this.whatsappNumberError === "" && this.contactNumberError === "") {
      const storeData = new FormData();
      storeData.append("name", this.storeform.value.name);
      storeData.append("description", this.storeform.value.description);

      if (this.storeform.value.contactNumber != '') {
        storeData.append("contactNumber", '+' + this.iti1.getSelectedCountryData().dialCode + ' ' + this.storeform.value.contactNumber);

      }

      var whatsAppNumberArray = this.storeform.value.whatsAppNumber.split(" ");
      if (whatsAppNumberArray.length > 1) {
        storeData.append("whatsAppNumber", '+' + this.iti.getSelectedCountryData().dialCode + ' ' + whatsAppNumberArray[1]);
      } else {
        storeData.append("whatsAppNumber", '+' + this.iti.getSelectedCountryData().dialCode + ' ' + this.storeform.value.whatsAppNumber);
      }

      storeData.append("email", this.storeform.value.email);
      storeData.append("address", this.storeform.value.address);
      storeData.append("facebookUrl", this.storeform.value.facebookUrl);
      storeData.append("instagramUrl", this.storeform.value.instagramUrl);
      storeData.append("GSTNo", this.storeform.value.GSTNo);
      storeData.append("active", this.storeform.value.active);
      storeData.append("storeUrl", this.storeform.value.storeUrl);
      if (this.isSuperAdmin) {

        this.selectedUserId = this.storeform.value.username;
        storeData.append("UserId", this.storeform.value.username);
      } else {
        storeData.append("UserId", '0');
      }

      this.storeService.create(storeData).subscribe((res: any) => {
        this.isSubmitted = false;
        const translatedMessage = this.translate.instant('toastr.success.createstore');
        this.toastr.success(translatedMessage);
        this.SpinnerService.hide();
        this.redirectToProduct();

      }, (error: any) => {
        this.isSubmitted = false;
        const translatedMessage = this.translate.instant('toastr.error.duplicatestore');
        this.toastr.error(translatedMessage);
        this.SpinnerService.hide();
      }
      )
    }

  }

  onCancelClick() {
    this.redirectToProduct();
  }

  redirectToProduct() {
    if (this.isSuperAdmin) {
      var uid = this.selectedUserId;
      this.router.navigate(
        ['dashboard/store'],
        { queryParams: { uid } }
      );
    }
    else {
      this.router.navigateByUrl("dashboard/store");
    }
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.storeform.patchValue({
          fileSource: reader.result
        });
      };
      this.sourcesFile.push(file);
    }
  }
  onInputKeyPress = (event: KeyboardEvent) => {
    const allowedChars = /[0-9\+\-\ ]/;
    if (
      !allowedChars.test(event.key) 
    ) {
      event.preventDefault();
    }
  };
  onChange(event) {
    const mobilenumberArray = event.mobileNumber.split(" ");
    if (mobilenumberArray.length > 1) {
      ;
      this.storeform.controls['whatsAppNumber'].setValue(mobilenumberArray[1]);
      this.iti.setNumber(event.mobileNumber.trim());
    } else {
      this.storeform.controls['whatsAppNumber'].setValue(event.mobileNumber);
    }
    this.profileservice.getUserByUserId(event.id).subscribe((data: any) => {
      this.storeform.controls['email'].setValue(data.email);
    }, (err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);

    });

  }
  getWhatsappNumberErrorMessage(errorCode) {
    switch (errorCode) {
      case window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return "Invalid whatsapp number";
      case window.intlTelInputUtils.validationError.TOO_SHORT:
        return "Invalid whatsapp number";
      case window.intlTelInputUtils.validationError.TOO_LONG:
        return "Invalid whatsapp number";
      case window.intlTelInputUtils.validationError.NOT_A_NUMBER:
        return "Invalid whatsapp number";
      default:
        return "Invalid whatsapp number";

    }
  }
  getContactNumberErrorMessage(errorCode) {
    switch (errorCode) {
      case window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return "Invalid contact number";
      case window.intlTelInputUtils.validationError.TOO_SHORT:
        return "Invalid contact number";
      case window.intlTelInputUtils.validationError.TOO_LONG:
        return "Invalid contact number";
      case window.intlTelInputUtils.validationError.NOT_A_NUMBER:
        return "Invalid contact number";
      default:
        return "Invalid contact number";
    }
  }


  createStoreUrl(name: string) {
   this.urlmessage = " ";
    this.languageName = localStorage.getItem('language');
    if (this.languageName === 'en') {
      this.storeService.getStoreUrl(name)
        .subscribe((data: any) => {
          this.apiResponse = data.message;
           this.url=data.url;
          if( this.apiResponse==='Store url already exists'){
            this.storeform.controls['storeUrl'].setValue(this.url);
            this.urlmessage = "Store url already exists"
          }else{
            this.storeform.controls['storeUrl'].setValue(this.url);
          }
      
        }
        );
    }
    else {
      this.url =environment.storeUrl; 
      this.storeform.controls['storeUrl'].setValue( this.url);
      this.urlmessage = "Please create your store Url";
    }
   
  }
  onInput(event: any) {
    if (event.key === 'Backspace' ) {
      event.preventDefault();
    }
    const parts = this.storeform.value.storeUrl.split('/');
    this.storeurl = parts[parts.length - 1];
     this.storeService.checkStoreUrl(this.storeurl).subscribe((data:any)=>{
    if(data.success===true){
      this.urlmessage="true";
     }
     else{
      this.urlmessage=" ";
     }
})

   
  }
  disableBackspace(event: KeyboardEvent): void {

    if (event.key === 'Backspace' && event.target instanceof HTMLInputElement) {
      const inputValue = event.target.value;
      if (inputValue.length <= 31) {
        event.preventDefault();
      }
    }
  }
  ngOnDestroy(): void {
    document.getElementById("tempid_Store").classList.remove("highlight_sideMenu");
    this.iti.destroy();
    this.iti1.destroy();
  }
}
