import { AppState } from '@/store/state';
import { ToggleSidebarMenu } from '@/store/ui/actions';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {


  constructor(private router:Router,
    private store: Store<AppState>,) { }

  ngOnInit() {
    localStorage.clear();
    this.store.dispatch(new ToggleSidebarMenu());
    this.router.navigateByUrl("/login");
  }

}
