<div class="container siginpage-container">
  <div class="row">
    <div class="card signin-page size-H">
      <div class="MartsUp-header">
        <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
      </div>
      <div class="header-border"></div>
      <div class="card-body pt-2">
        <div class="signin-header mb-3">
          <span class="signin">{{ "resetpassword.title" | translate }}</span>
        </div>
        <div>
          <form
            [formGroup]="recoverPasswordForm"
            (ngSubmit)="recoverPassword()"
            class="sigin-form"
          >
            <div class="form-group">
              <img src="assets\svgimages\profile.svg" class="email-icon" />
              <label for="exampleInputEmail1 " class="email-text">{{
                "resetpassword.username.label" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                value="{{ this.username }}"
                class="form-control"
                disabled
              />
            </div>
            <div class="form-group">
              <img src="assets\svgimages\password svg.svg" class="pass-icon" />
              <label for="exampleInputPassword1" class="email-text"
                >{{ "resetpassword.newpassword.label" | translate
                }}<span class="required">*</span></label
              >
              <div class="password">
                <input
                  [type]="show_password ? 'text' : 'password'"
                  class="form-control password-box"
                  id="exampleInputPassword1"
                  formControlName="newpassword"
                  placeholder="{{
                    'resetpassword.newpassword.placeholder' | translate
                  }}"
                  [focusMe]="true"
                />
                <span class="eyeicon">
                  <i
                    [class]="show_eyepassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="showPassword()"
                  ></i
                ></span>
              </div>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && f.newpassword.errors?.required"
              >
                {{ "validation.password.required" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="f.newpassword.errors?.minlength"
              >
                {{ "validation.password.minlength" | translate }}
              </div>
            </div>
            <div class="form-group">
              <img src="assets\svgimages\password svg.svg" class="pass-icon" />
              <label for="exampleInputPassword1" class="email-text"
                >{{ "resetpassword.confirmpassword.label" | translate
                }}<span class="required">*</span></label
              >
              <div class="password">
                <input
                  [type]="show_confirmpassword ? 'text' : 'password'"
                  class="form-control password-box"
                  id="exampleInputPassword1"
                  formControlName="confirmNewPassword"
                  placeholder="{{
                    'resetpassword.confirmpassword.placeholder' | translate
                  }}"
                />
                <span class="eyeicon">
                  <i
                    [class]="
                      show_eyeconfirmpassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                    "
                    (click)="showConfirmPassword()"
                  ></i
                ></span>
              </div>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && f.confirmNewPassword.errors?.required"
              >
                {{ "validation.confirmpassword.required" | translate }}
              </div>

              <div
                class="text-red text-12 font-weight-bold mb-3"
                *ngIf="f.confirmNewPassword.errors?.confirmPasswordValidator"
              >
                {{
                  "validation.confirmpassword.confirmpasswordvalidator"
                    | translate
                }}
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="btn btn-secondary btn-lg btn-block signin-btn mt-4"
              >
                <div>
                  {{ "resetpassword.button.name" | translate }}
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
