import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreAPIService } from '../storeapiservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '@services/profile.service';
import { Table } from 'primeng/table';
import { ShareimageService } from '@services/shareimage.service';
import { PublishModel } from '@/Model/publish';
import { Notification } from '@/Model/notification';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-test',
  templateUrl: './storeList.component.html',
  styleUrls: ['./storeList.component.css'],
  providers: [ConfirmationService],
})
export class StoreListComponent implements OnInit {
  errorMessage: boolean = false;
  data: any;
  users: any[];
  eventid: string;
  selectedUser: any;
  isSuperAdmin: boolean = false;
  userId: any;
  Products: PublishModel[];
  errormsg: string;
  noRecordMsgFlag: boolean;
  noRecordMsg: boolean;
  searchText = '';
  attributeData = '';
  attributeDataHeader = '';
  confirm: boolean = false;
  errormsgReason: string = '';
  notificationData: Notification[];
  display: boolean = false;
  confirmReject: boolean = false;
  store: number;
  user: number;
  reason: string = '';
  confirmClick: boolean = false;
  pageSize = 10; // Number of items per page
  currentPage = 1; // Current page number
  totalItems: number; // Total number of items
  showPaginationControls = false;
  languageName: any;
  @ViewChild('customSearch') customSearchElement: ElementRef;
  @ViewChild('select') selectElement: ElementRef;
  constructor(
    private storeService: StoreAPIService,
    private toastr: ToastrService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private SpinnerService: NgxSpinnerService,
    private profileservice: ProfileService,
    private shareimageService: ShareimageService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.languageName = localStorage.getItem('language');
    if (
      this.languageName === null ||
      this.languageName === '' ||
      this.languageName === undefined
    ) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    document
      .getElementById('tempid_Store')
      ?.classList.add('highlight_sideMenu');
    this.shareimageService.setPageName('Stores');

    var userId = parseInt(localStorage.getItem('notificationDataUserId'));
    this.SpinnerService.show();
    this.profileservice.getUserById().subscribe(
      (data: any) => {
        this.isSuperAdmin = data.isSuperAdmin;
        this.userId = data.id;
        if (this.isSuperAdmin === true) {
          this.profileservice.GetUsersForDropdown().subscribe((data: any) => {
            this.users = data;
            this.getNotification();
            this.setUserPreSelection();
            this.SpinnerService.hide();
          });
          if (userId) {
            this.selectedUser = Number(userId);
            var obj = { id: this.selectedUser };
            this.onUsenameDropdownChange(obj);
          }
        } else {
          this.loadStores(0);
        }
      },
      (err: any) => {
        if (err.status !== 404) {
          return;
        }
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.router.navigate(['/login']);
      }
    );
  }
  onEdit(searchText) {
    this.errorMessage = false;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].name.includes(this.searchText)) {
        this.errorMessage = false;
        return;
      }
    }
    this.errorMessage = true;
    return;
  }
  getNotification() {
    this.profileservice.getNotification().subscribe((data: any) => {
      this.shareimageService.setnotification(data);
    });
  }
  showDialog(data) {
    this.attributeDataHeader = data.name;
    this.attributeData = data.storeURL;
    this.display = true;
  }
  onUsenameDropdownChange(event) {
    if (event === undefined) return;

    if (event != null) {
      this.SpinnerService.show();
      this.eventid = event.id;

      this.storeService.GetStoresByUserId(event.id).subscribe(
        (data) => {
          this.data = data;
          this.totalItems = data.length;
          if (data.length > 0) {
            this.showPaginationControls = true;
          }
          this.SpinnerService.hide();
        },
        (err: any) => {
          this.SpinnerService.hide();
        }
      );
    }
  }

  loadStores(uid) {
    this.SpinnerService.show();
    this.storeService.GetStoresByUserId(uid).subscribe(
      (data) => {
        if (data == null || data.length == 0) {
          this.SpinnerService.hide();
          this.data = null;
          this.showPaginationControls = false;
        } else {
          this.SpinnerService.hide();
          this.data = data;
          this.totalItems = data.length;
          if (data.length > 0) {
            this.showPaginationControls = true;
          }
        }
      },
      (err: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
        this.SpinnerService.hide();
      }
    );
  }
  onRefreshmobile() {
    this.searchText = '';
    if (this.isSuperAdmin === false) {
      this.loadStores(0);
    } else {
      this.SpinnerService.show();
      var storeid = this.eventid;
      this.storeService.GetStoresByUserId(storeid).subscribe(
        (data) => {
          this.data = data;
          this.getNotification();
          this.SpinnerService.hide();
        },
        (err: any) => {
          this.SpinnerService.hide();
        }
      );
    }
  }
  isEmpty(event) {
    if (event.filteredValue.length == 0) {
      this.noRecordMsgFlag = true;
    } else {
      this.noRecordMsgFlag = false;
    }
  }
  ngAfterViewInit() { }
  onAddClick() {
    this.router.navigateByUrl('/dashboard/addStore');
  }

  onRefresh(table: Table) {
    table.clear();
    this.customSearchElement.nativeElement.value = '';
    if (this.isSuperAdmin === false) {
      this.loadStores(0);
    } else {
      this.SpinnerService.show();
      var storeid = this.eventid;
      this.storeService.GetStoresByUserId(storeid).subscribe(
        (data) => {
          this.data = data;
          this.getNotification();
          this.SpinnerService.hide();
        },
        (err: any) => {
          this.SpinnerService.hide();
        }
      );
    }
  }
  onDeleteClick(store: any) {
    this.translate
      .get('deleteConfirmationDialog')
      .subscribe((translations: any) => {
        this.confirmationService.confirm({
          header: translations.title,
          message: translations.message,
          icon: 'pi pi-info-circle',
          accept: () => {
            this.storeService.delete(store.storeId).subscribe((data: any) => {
              this.SpinnerService.hide();
              this.loadStores(store.userId);
              this.getNotification();
              const translatedMessage = this.translate.instant(
                'toastr.success.deletestore'
              );
              this.toastr.success(translatedMessage);
            });
          },
          reject: () => { },
          acceptLabel: translations.accept,
          rejectLabel: translations.reject,
        });
      });
  }

  oneditClick(id: any) {
    this.router.navigateByUrl('dashboard/store/' + id + '/editStore');
  }

  publishClick(store: any) {
    this.translate
      .get('publishConfirmationDialog')
      .subscribe((translations: any) => {
        this.confirmationService.confirm({
          message: translations.message,
          header: translations.title,
          icon: 'pi pi-info-circle',
          accept: () => {
            this.SpinnerService.show();
            this.storeService.publishStore(store.storeId).subscribe(
              (data: any) => {
                const translatedMessage = this.translate.instant(
                  'toastr.success.publishstore'
                );
                this.toastr.success(translatedMessage);
                this.loadStores(store.userId);
                this.getNotification();
                this.SpinnerService.hide();
              },
              (err: any) => {
                const translatedMessage =
                  this.translate.instant('toastr.error.error');
                this.toastr.error(translatedMessage);
                this.SpinnerService.hide();
              }
            );
          },
          reject: () => { },
          acceptLabel: translations.accept,
          rejectLabel: translations.reject,
        });
      });
  }
  onConfirmClick() {
    this.SpinnerService.show();
    this.confirmClick = true;
    if (this.reason != '') {
      this.storeService.rejectStore(this.store, this.reason).subscribe(
        (data: any) => {
          const translatedMessage = this.translate.instant(
            'toastr.success.rejectstore'
          );
          this.toastr.error(translatedMessage);
          this.loadStores(this.user);
          this.getNotification();
          this.SpinnerService.hide();
        },
        (err: any) => {
          const translatedMessage =
            this.translate.instant('toastr.error.error');
          this.toastr.error(translatedMessage);
          this.SpinnerService.hide();
        }
      );
      this.confirmReject = true;
      this.confirm = false;
    } else {
      this.SpinnerService.hide();
      const translatedMessage = this.translate.instant(
        'validation.rejectstore'
      );
      this.errormsgReason = translatedMessage;
      return;
    }
  }
  rejectClick(store: any) {
    this.reason = '';
    this.store = store.storeId;
    this.user = store.userId;
    this.confirm = true;
    this.confirmClick = false;
  }
  oncancelClick() {
    this.confirm = false;
    this.reason = '';
    this.confirmClick = false;
  }

  sendPublishRequest(store: any) {
    if (store.publishStatus === 'Pending') {
      const translatedMessage = this.translate.instant(
        'toastr.info.publishrequest'
      );
      this.toastr.info(translatedMessage);
      return;
    }
    this.SpinnerService.show();
    this.storeService.sendPublishRequest(store.storeId).subscribe(
      (data: any) => {
        this.Products = data;
        if (data) {
          var values = [];

          for (var i = 0, l = data.length; i < l; i++) {
            values.push(i + 1 + ':' + data[i].productName);
          }
          var productdata = values.join(', ');
          const translatedMessage = this.translate.instant(
            'toastr.error.publishrequestsending'
          );
          this.toastr.error(translatedMessage + productdata);
          this.SpinnerService.hide();
        } else {
          const translatedMessage = this.translate.instant(
            'toastr.success.publishrequestsent'
          );
          this.toastr.success(translatedMessage);
          this.loadStores(store.userId);
          this.SpinnerService.hide();
          data;
        }
        this.getNotification();
      },
      (err: any) => {
        const translatedMessage = this.translate.instant(
          'toastr.error.publish'
        );
        this.toastr.error(translatedMessage);
        this.SpinnerService.hide();
      }
    );
  }

  setUserPreSelection() {
    if (this.isSuperAdmin) {
      var uid = this.route.snapshot.queryParamMap.get('uid');
      if (uid != null || uid != undefined) {
        if (this.users.length > 0) {
          var uidValid = this.users.filter((u) => u.id == Number(uid));
          if (uidValid.length > 0) {
            this.selectedUser = Number(uid);
            var obj = { id: this.selectedUser };
            this.onUsenameDropdownChange(obj);
          }
        }
      }
    }
  }
  pageChanged(event: any) {
    this.currentPage = event;
  }

  ngOnDestroy(): void {
    document
      .getElementById('tempid_Store')
      .classList.remove('highlight_sideMenu');
  }
}
