export class Userprofile {
    id?: number;
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    mobileNumber: string = '';
    profileImagePath: string = '';
    profileImage?: FormData;
    createdBy: string;
    active: boolean;
    createdDate: Date;
    otp?: number;
    otpTime: Date;
    password: string;
    updatedBy: string;
    updatedDate: Date;
    username: string = '';
  }
  