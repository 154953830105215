<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"></div>
    </div>
  </div>
</div>

<ngx-spinner
bdColor="rgba(202,202,202,0.8)"
size="medium"
color="#e72744"
type="timer"
>
<p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
<section class="content">
  <div class="container-fluid">
    <div class="row main-content-desktop">
      <div class="col-md-4 col-sm-12 mt-2">
        <div class="card total-store-card">
          <div class="card-body">
            <div class="row image-dashboard">
              <div class="col-md-8 col-8 total-store">
                <div class="row total-score-text"> {{ "dashboard.totalstores" | translate }}</div>
                <div class="row total-count">
                  <div *ngIf="loadingStore == true">
                    <i class="fa fa-spinner fa-spin dashboard-spin-size"></i>
                  </div>
                  <div *ngIf="loadingStore == false">
                    {{ Store != 0 ? Store : 0 }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-4 vertical-line dashboard-icon-svg">
                <a routerLink="/dashboard/store" class="nav-link">
                  <img class="color-change"src="assets/svgimages/storered.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mt-2 products-mobileview">
        <div class="card category total-store-card">
          <div class="card-body">
            <div class="row image-dashboard">
              <div class="col-md-8 col-8 total-store">
                <div class="row total-score-text">{{ "dashboard.totalcategories" | translate }}</div>
                <div class="row total-count">
                  <div *ngIf="loadingCategory == true">
                    <i class="fa fa-spinner fa-spin dashboard-spin-size"></i>
                  </div>
                  <div *ngIf="loadingCategory == false">
                    {{ Categories != 0 ? Categories : 0 }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-4 vertical-line">
                <a routerLink="/dashboard/category" class="nav-link">
                  <img class="color-change" src="assets/svgimages/categoryred.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 mt-2">
        <div class="card products total-store-card">
          <div class="card-body">
            <div class="row image-dashboard">
              <div class="col-md-8 col-8 total-store">
                <div class="row total-score-text">{{ "dashboard.totalproducts" | translate }}</div>
                <div class="row total-count">
                  <div *ngIf="loadingProduct == true">
                    <i class="fa fa-spinner fa-spin dashboard-spin-size"></i>
                  </div>
                  <div *ngIf="loadingProduct == false">
                    {{ Product != 0 ? Product : 0 }}
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-4 vertical-line ">
                <a routerLink="/dashboard/product" class="nav-link">
                  <img class="color-change" src="assets/svgimages/productred.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="row  desktop-chart">
        <div class="col-md-7">
          <div *ngIf="lineData==true">
            <div class="card chart total-store-card">
           <span class="quantity-detail"><b>{{ "dashboard.quantitydetail" | translate }}</b></span>
              <p-chart type="line" [responsive]="true" [data]="lineChartData"[width]="1000" [height]="400" style="display: inline;" [options]="basicOptions" ></p-chart>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div>
            <div class="card chart total-store-card">
          <span class="quantity-detail"><b>{{ "dashboard.categoryusagedetail" | translate }}</b></span>
              <p-chart type="bar" [data]="barChartData"[width]="40" [height]="400" style="display: inline;" [options]="options" ></p-chart>
            </div>
          </div>
        </div>
        <div></div>
      </div>
        <div class="row  desktop-chart"*ngIf="isSuperAdmin">
          <div class="col-md-7">
            <div *ngIf="pieData==true">
              <div class="card chart total-store-card">
                <span class="quantity-detail"><b>{{ "dashboard.storedetail" | translate }}</b></span>
                <p-chart type="bar"   [responsive]="true" #chart  [width]="1000" [height]="400" responsive="true"   [data]="pieChartData" [options]="basicOptionsbar" ></p-chart>
              </div>
              </div>
          </div>
        </div>
 
  
    <!--mobile view -->
    <div class="row main-content-mobile">
      <div class="col-md-4 col-sm-12 mt-2">
        <div class="card total-store-card">
          <div class="card-body dashboard-card">
            <div class="row image-dashboard">
              <div class="col-md-4 col-4">
                <a routerLink="/dashboard/store" class="nav-link">
                  <img class="color-change" src="assets/svgimages/storered.svg" alt="" />
                </a>
              </div>
              <div class="col-md-8 col-8 total-store vertical-line">
                <div class="row total-score-text">{{ "dashboard.totalstores" | translate }}</div>
                <div class="row total-count">
                  <div *ngIf="loadingStore == true">
                    <i class="fa fa-spinner fa-spin dashboard-spin-size"></i>
                  </div>
                  <div *ngIf="loadingStore == false">
                    {{ Store != 0 ? Store : 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 mt-2 products-mobileview">
        <div class="card category total-store-card">
          <div class="card-body">
            <div class="row image-dashboard">
              <div class="col-md-4 col-4">
                <a routerLink="/dashboard/category" class="nav-link">
                  <img class="color-change" src="assets/svgimages/categoryred.svg" alt="" />
                </a>
              </div>
              <div class="col-md-8 col-8 total-store vertical-line">
                <div class="row total-score-text">{{ "dashboard.totalcategories" | translate }}</div>
                <div class="row total-count">
                  <div *ngIf="loadingCategory == true">
                    <i class="fa fa-spinner fa-spin dashboard-spin-size"></i>
                  </div>
                  <div *ngIf="loadingCategory == false">
                    {{ Categories != 0 ? Categories : 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 mt-2">
        <div class="card products total-store-card">
          <div class="card-body">
            <div class="row image-dashboard">
              <div class="col-md-4 col-4">
                <a routerLink="/dashboard/product" class="nav-link">
                  <img class="color-change" src="assets/svgimages/productred.svg" alt="" />
                </a>
              </div>
              <div class="col-md-8 col-8 total-store vertical-line">
                <div class="row total-score-text">{{ "dashboard.totalproducts" | translate }}</div>
                <div class="row total-count">
                  <div *ngIf="loadingProduct == true">
                    <i class="fa fa-spinner fa-spin dashboard-spin-size"></i>
                  </div>
                  <div *ngIf="loadingProduct == false">
                    {{ Product != 0 ? Product : 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div *ngIf="lineData==true">
          <div class="card chart">
            <span class="quantity-detail"><b>{{ "dashboard.quantitydetail" | translate }}</b></span>
            <p-chart type="line" [data]="lineChartData" [options]="basicOptions"></p-chart>
          </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div >
          <div class="card chart">
            <span class="quantity-detail"><b>{{ "dashboard.categoryusagedetail" | translate }}</b></span>
            <p-chart type="bar" [data]="barChartData" [options]="options"></p-chart>
          </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isSuperAdmin">
        <div class="col-sm-12">
          <div *ngIf="pieData==true">
          <div class="card chart">
            <span class="quantity-detail"><b>{{ "dashboard.storedetail" | translate }}</b></span>
            <p-chart type="bar" [data]="pieChartData"></p-chart>
          </div>
          </div>
        </div>
      </div>

    </div>
  
  </div>
</section>
