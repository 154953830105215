import { ChangePasswordComponent } from '@modules/change-password/change-password.component';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AvatarModule } from 'ngx-avatar-ng13';
import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import {NgxPaginationModule} from 'ngx-pagination';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { StoreListComponent } from './AddStore/storeList/storeList.component';
import { AddStoreComponent } from './AddStore/addStore/addStore.component';
import { EditStoreComponent } from './AddStore/editStore/editStore.component';
import { ProductlistComponent } from './product/productlist/productlist.component';
import { AddproductComponent } from './product/addproduct/addproduct.component';
import { EditproductComponent } from './product/editproduct/editproduct.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddCategoryComponent } from './AddCategory/addCategory/addCategory.component';
import { EditCategoryComponent } from './AddCategory/editCategory/editCategory.component';
import { CategoryListComponent } from './AddCategory/categoryList/categoryList.component';
import { VerifyOTPComponent } from '@modules/verifyOTP/verifyOTP.component';
import { UserprofileComponent } from '@modules/userprofile/userprofile.component';
import { SetpasswordverifyOTPComponent } from '@modules/setpasswordverifyOTP/setpasswordverifyOTP.component';
import { SetpasswordComponent } from '@modules/setpassword/setpassword.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TokenInterceptor } from '@services/token.interceptor';
import { StoreFilterPipe } from './AddStore/storeFflter.pipe';
import { CategoryFilterPipe } from './AddCategory/categoryfilter.pipe';
import { ProductFilterPipe } from './product/productfilter.pipe';
import { ReportComponent } from './report/report.component';
import { UserlistComponent } from './AddUser/userlist/userlist.component';
import { AddUserComponent } from './AddUser/add-user/add-user.component';
import { EditUserComponent } from './AddUser/edit-user/edit-user.component';
import { UserfilterPipe } from './AddUser/userfilter.pipe';
import { InputFocusDirective } from './utils/InputFocusDirective';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { QRCodeModule } from 'angular2-qrcode';
import {ChartModule} from 'primeng/chart';
import { TermsConditionsComponent } from '@modules/terms-conditions/terms-conditions.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ClickOutsideModule } from 'ng-click-outside';
import {  HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);}
registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    MenuSidebarComponent,
    DashboardComponent,

    PrivacyPolicyComponent,
    MenuItemComponent,
    TermsConditionsComponent,
    StoreListComponent,
    AddStoreComponent,
    EditStoreComponent,
    ProductlistComponent,
    AddproductComponent,
    EditproductComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    AddCategoryComponent,
    CategoryListComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LoginComponent,
    VerifyOTPComponent,
    UserprofileComponent,
    SetpasswordComponent,
    SetpasswordverifyOTPComponent,
    ChangePasswordComponent,
    StoreFilterPipe,
    CategoryFilterPipe,
    ProductFilterPipe,
    UserfilterPipe,
    ReportComponent,
    UserlistComponent,
    AddUserComponent,
    EditUserComponent,
    InputFocusDirective

   ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    CommonModule,
    FormsModule,
    AngularEditorModule,
    TableModule,
    CalendarModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    OverlayPanelModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    MultiSelectModule,
    HttpClientModule,
    FormsModule,
    AccordionModule,
    NgSelectModule,
    NgxSpinnerModule.forRoot({ type: 'timer' }),
    AvatarModule,
    DialogModule,
    QRCodeModule,
    ChartModule,
    ClickOutsideModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
