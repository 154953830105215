<div class="main-content">
  <form
    [formGroup]="userprofile"
    (ngSubmit)="onSubmit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="contact-form">
      <div class="img-header row">
        <div class="col-2">
          <div *ngIf="profileImageVisible == true; else templatename">
            <img
              width="150px"
              height="150px"
              [src]="imageSrc"
              class="profile-img"
              (click)="onImageClick($event)"
            />
            <br />
          </div>

          <ng-template #templatename>
            <ngx-avatar
              size="150"
              class="profile-img"
              name="{{ letters }}"
            ></ngx-avatar>
          </ng-template>
          <div class="upload-btn-wrapper button-position">
            <button class="btn_upload" (click)="uploader.click()">
              <img
                src="assets/svgimages/editprofile.svg"
                (click)="onImageClick($event)"
                alt=""
                class="edit-button"
              />
            </button>
            <input
              class="select-profile-picture mb-3"
              formControlName="profileImagePath"
              id="file"
              type="file"
              #uploader
              class="form-control"
              accept="image/x-png, image/gif, image/jpeg"
              (change)="onFileChange($event)"
              style="border: 0px"
              name="myfile"
            />
          </div>
        </div>
        <div class="col-10">
          <div
            *ngIf="profileImageVisible === true; else profileblock"
            class="user username"
          >
            <div>
              {{ this.userprofile.value.firstName }}
              {{ this.userprofile.value.lastName }}
            </div>
          </div>
          <ng-template #profileblock class="">
            <div class="user username">
              {{ this.userprofile.value.firstName }}
              {{ this.userprofile.value.lastName }}
            </div>
          </ng-template>
        </div>
      </div>

      <div class="container profile-page-container mt-4">
        <div class="profile-form">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label text-14">
                {{ "userprofile.firstname.label" | translate }}
                <span class="required">*</span></label
              >
              <div class="">
                <input
                  formControlName="firstName"
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'userprofile.firstname.placeholder' | translate
                  }}"
                  [focusMe]="true"
                  maxlength="15"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f.firstName.errors?.required"
                >
                  {{ "validation.firstname.required" | translate }}
                </div>
            
              </div>
            </div>
            <br />
            <div class="col-md-1"></div>
            <div class="col-md-5 margin-email">
              <label class="control-label text-14">
                {{ "userprofile.lastname.label" | translate
                }}<span class="required">*</span></label
              >
              <div class="">
                <input
                  formControlName="lastName"
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'userprofile.lastname.placeholder' | translate
                  }}"
                  maxlength="15"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f.lastName.errors?.required"
                >
                  {{ "validation.lastname.required" | translate }}
                </div>
               
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-5">
              <label class="control-label text-14">{{
                "userprofile.mobilenumber.label" | translate
              }}</label>
              <div>
                <input
                  formControlName="mobileNumber"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5 margin-email">
              <label class="control-label text-14">{{
                "userprofile.email.label" | translate
              }}</label>
              <input
                formControlName="email"
                type="email"
                class="form-control"
                placeholder="{{ 'userprofile.email.placeholder' | translate }}"
                maxlength="50"
              />
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.email.errors?.pattern"
              >
                {{ "validation.email.pattern" | translate }}
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-5">
              <label class="control-label text-14">{{
                "userprofile.username.label" | translate
              }}</label>
              <div>
                <input
                  formControlName="username"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-profile-page">
          <div class="row">
            <button
              type="cancel"
              class="btn btn-colour-cancel mobile-btn-save-cancel"
              (click)="onCancelClick()"
            >
              {{ "userprofile.button.cancel" | translate }}
            </button>
            <button type="submit" class="btn btn-colour mobile-btn-save">
              {{ "userprofile.button.submit" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
