import { Router } from '@angular/router';
import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RecoverpasswordService } from '@services/recoverpassword.service';
import { ResetPassword } from '@/Model/resetpassword';
import { ConfirmPasswordValidator } from '@modules/confirmpassword.validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';

    public recoverPasswordForm: FormGroup;
    public loading = false;
    email: any;
    username: any;
    mobilenumber: any;
    errorMessage: string;
    languageName: any;
    public isFormSubmitted: boolean = false;
    passwordmatch: ResetPassword = new ResetPassword();
    show_password: Boolean = false;
    show_eyepassword: Boolean = false;
    show_confirmpassword: Boolean = false;
    show_eyeconfirmpassword: Boolean = false;
    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private router: Router,
        private recoverpasswordservice: RecoverpasswordService,
        private formBuilder: FormBuilder,
        private translate: TranslateService
    ) { this.translate.setDefaultLang('en'); }

    ngOnInit(): void {

        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.username = JSON.parse(localStorage.getItem('username'))
        this.languageName = localStorage.getItem('language');
        if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
            localStorage.setItem('language', 'en');
            this.translate.use('en');
        } else {
            this.translate.use(this.languageName);
        }

        this.recoverPasswordForm = this.formBuilder.group({
            newpassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
            confirmNewPassword: new FormControl('', [Validators.required]),
        }, {
            validator: ConfirmPasswordValidator("newpassword", "confirmNewPassword")
        });
    }
    get f() {

        return this.recoverPasswordForm.controls;
    }

    recoverPassword() {

        this.isFormSubmitted = true;
        this.loading = true;
        if (this.recoverPasswordForm.valid) {

            this.passwordmatch.newpassword = this.f.newpassword.value;
            this.passwordmatch.confirmNewPassword = this.f.confirmNewPassword.value;
            if (this.passwordmatch.newpassword == this.passwordmatch.confirmNewPassword) {
                const resetpassworddata = new FormData();

                resetpassworddata.append('username', this.username);
                resetpassworddata.append('newpassword', this.recoverPasswordForm.value.newpassword);

                this.recoverpasswordservice.resetpassword(resetpassworddata).subscribe((data: any) => {

                    var user = JSON.parse(data);
                    if (user.active == false) {
                        const translatedMessage = this.translate.instant('toastr.info.passwordreset');
                        this.toastr.info(translatedMessage, '', {
                            timeOut: 9000,

                        });

                    }

                    this.router.navigateByUrl("/login");
                    this.loading = false;
                },
                    (error: any) => {

                        const translatedMessage = this.translate.instant('toastr.error');
                        this.toastr.error(translatedMessage);
                        this.loading = false;
                    }

                );

            } else {
                const translatedMessage = this.translate.instant('toastr.error');
                this.toastr.error(translatedMessage);
                this.loading = false;
            }
        }
        else {
            this.loading = false;
        }
    }

    showPassword() {
        this.show_password = !this.show_password;
        this.show_eyepassword = !this.show_eyepassword;
    }

    showConfirmPassword() {
        this.show_confirmpassword = !this.show_confirmpassword;
        this.show_eyeconfirmpassword = !this.show_eyeconfirmpassword;
    }
    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
