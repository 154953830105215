export const environment = {
  production: true,
  // apiUrl: 'https://api.martsup.co.in/martsupprod/api',
  userApiUrl: ' https://api.martsup.com/userprod/api',
  storeApiUrl: 'https://api.martsup.com/storeprod/api',
  catApiUrl: ' https://api.martsup.com/categoryprod/api',
  prodApiUrl: 'https://api.martsup.com/productprod/api',
  publishApiUrl: 'https://api.martsup.com/publishprod/api',
  reportApiUrl: 'https://api.martsup.com/reportprod/api',
  profileImageUrl: 'https://martsup.s3.ap-south-1.amazonaws.com/ProfileImage',
  storeUrl:'https://www.my.martsup.com/'
};

