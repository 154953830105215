<!-- Main content -->
<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
<section class="content main-content">
  <!-- Default box -->
  <div class="">
    <div class="main-title text-23">
      <label class="mb-0">{{'updateproduct.title' | translate }}</label
      ><label class="text-secondary pl-2 text-transform-capitalize">{{
        this.StoreToSave.name
      }}</label>
    </div>
  </div>
  <div class="tab-content" id="pills-tabContent">
    <ul
      class="tab-pane fade show active"
      id="pills-home"
      role="tablist"
      aria-labelledby="pills-home-tab"
    >
      <div class="product-steps">
        <div class="steps mt-4 mb-4">
          <li class="step">
            <span class="product-count" *ngIf="step > 1"> </span>
            <span
              class="product-count product-detail active"
              *ngIf="step === 1"
            >
              <img src="assets/svgimages/check.svg" class="true-img" />
            </span>
            <span
              [ngClass]="
                step === 1
                  ? 'pl-2 step-title product-detail active'
                  : 'pl-2 step-title '
              "
              >{{'updateproduct.productdetail' | translate }}</span
            >
            <span class="product-line"></span>
          </li>
          <li class="step">
            <span class="product-count" *ngIf="step != 2"> </span>
            <span
              class="product-count product-detail active"
              *ngIf="step === 2"
            >
              <img src="assets/svgimages/check.svg" class="true-img" />
            </span>
            <span
              [ngClass]="
                step === 2
                  ? 'pl-2 step-title product-detail active'
                  : 'pl-2 step-title '
              "
              >{{'updateproduct.productcategory' | translate }}</span
            >
            <span class="product-line"></span>
          </li>
          <li class="step">
            <span class="product-count" *ngIf="step != 3"> </span>
            <span
              class="product-count product-detail active"
              *ngIf="step === 3"
            >
              <img src="assets/svgimages/check.svg" class="true-img" />
            </span>
            <span
              [ngClass]="
                step === 3
                  ? 'pl-2 step-title product-detail active'
                  : 'pl-2 step-title '
              "
              >{{'updateproduct.uploadimages' | translate }}</span
            >
            <span class="product-line"></span>
          </li>
          <li class="step">
            <span class="product-count" *ngIf="step < 4"> </span>
            <span class="product-count product-detail active" *ngIf="step == 4">
              <img src="assets/svgimages/check.svg" class="true-img" />
            </span>
            <span
              [ngClass]="
                step === 4
                  ? 'pl-2 step-title product-detail active'
                  : 'pl-2 step-title '
              "
              >{{'updateproduct.uploadvideo' | translate }}</span
            >
          </li>
        </div>
      </div>
    </ul>
  </div>

  <div class="row mobile-view-border mb-5">
    <div class="col-md-1"></div>
    <div class="col-md-9">
      <div class="container-fluid main-content pt-0">
        <form #form="ngForm" [formGroup]="productform">
          <div
            class="product-details-noborder"
            *ngIf="step == 1"
            formGroupName="productDetail"
          >
            <div class="form-group mt-6 col-12">
              <label for="exampleInput"
                >{{'updateproduct.productdetail.productname.label' | translate }}<span class="required">*</span></label
              >
              <input
                type="text"
                autofocus
                class="form-control input-box"
                id="exampleInput"
                placeholder="{{'updateproduct.productdetail.productname.placeholder'| translate }}"
                formControlName="name"
                [focusMe]="true"
                maxlength="20"
              />
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="productDetail.name.errors?.pattern"
              >
              {{ "validation.productname.pattern" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && productDetail.name.errors?.required"
              >
             {{ "validation.productname.required" | translate }}
              </div>
           
            </div>
            <div class="form-group mt-6 col-12">
              <label for="exampleInput"
                >{{'updateproduct.productdetail.productdescription.label' | translate }}<span class="required">*</span></label
              >
              <angular-editor
                id="editor1"
                formControlName="description"
                [config]="config"
                (paste)="pastepreventExceedingMax($event)"
                (keydown)="preventExceedingMax($event)"
                (keyup)="descriptionCharCount($event)"
                [placeholder]="getPlaceholderText()"
              ></angular-editor>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="
                  isFormSubmitted && productDetail.description.errors?.required
                "
              >
              {{ "validation.description.required" | translate }}
              </div>
              <div
              class="text-red text-12 font-weight-bold mt-1"
              *ngIf="
                isFormSubmitted && productDetail.description.errors?.lengthError
              "
            >
            {{ "validation.description.required" | translate }}
            </div>
        
            </div>

            <div class="form-group" style="display: flex">
              <div class="col-6">
                <label for="exampleInput"
                  >{{'updateproduct.productdetail.sellingprice.label' | translate }} <span class="required">*</span></label
                >
                <input
                  type="number"
                  formControlName="sellingPrice"
                  class="form-control"
                  id="sellingPrice"
                  placeholder="{{'updateproduct.productdetail.sellingprice.placeholder'| translate }}"
                  onkeydown="return event.key !== '-' && event.key !== '+';"   
                  maxlength="8" (input)="limitInputLength($event)"
                  (keydown)="preventExceedingMaxLength($event)"   
                   (paste)="pastepreventnegativevalue($event)"
                   (keypress)="decimalFilter($event)"
                />
            
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="
                    isFormSubmitted &&
                    productDetail.sellingPrice.errors?.required
                  "
                >
                {{ "validation.sellingprice.required" | translate }}
                </div>
              </div>
              <div class="col-6 product-checkbox label-margin">
              
                <div class="form-check">
                  <input
                    style="height: 35px"
                    type="checkbox"
                    value=""
                    formControlName="showSellingPrice"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <span style="margin-top: 10px"><b>{{'updateproduct.productdetail.showsellingprice.label' | translate }}</b></span>
                </div>
              </div>
            </div>
            <div class="form-group mt-2" style="display: flex">
              <div class="col-6">
                <label for="exampleInput">{{'updateproduct.productdetail.discount.label' | translate }}</label>
                <input
                  style="height: 35px"
                  type="text"
                  class="form-control"
                  formControlName="discount"
                  id="exampleInput"
                  placeholder="{{'updateproduct.productdetail.discount.placeholder'| translate }}"
                  (input)="onInput($event)" pattern="[0-9]+"
                  maxlength="3"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="productDetail.discount.errors?.pattern"
                >
                {{ "validation.discount.pattern" | translate }}
                </div>
              </div>
              <div class="col-6 product-checkbox label-margin">
        
                <div class="form-check">
                  <input
                    style="height: 35px"
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                    formControlName="active"
                  />
                  <span style="margin-top: 10px"><b>{{'updateproduct.productdetail.active.label' | translate }}</b></span>
                </div>
              </div>
            </div>

            <div class="form-group mt-6 col-12">
              <label for="exampleInput ">{{'updateproduct.productdetail.brandname.label' | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="brandName"
                id="exampleInput"
                placeholder="{{'updateproduct.productdetail.brandname.placeholder'| translate }}"
                maxlength="15"
              />
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="productDetail.brandName.errors?.pattern"
              >
              {{ "validation.brandname.pattern" | translate }}
              </div>
        
            </div>
          </div>

          <div
            class="container"
            *ngIf="step == 2 "
            formGroupName="productCategory"
          >
            <div class="product-details-noborder">
              <label>{{'updateproduct.productcategory.selectcategory.label' | translate }} <span class="required">*</span></label>
              <ng-select
                [items]="productCategories"
                autofocus
                bindLabel="categoryName"
                bindValue="id"
                placeholder="{{'updateproduct.productcategory.selectcategory.placeholder'| translate }}"
                name="categoryName"
                [(ngModel)]="selectedCategory"
                formControlName="selectedCategory"
                [clearable]="false"
              >
              </ng-select>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="
                  isFormSubmitted &&
                  productCategory.selectedCategory.errors?.required
                "
              >
              {{ "validation.selectcategory" | translate }}
              </div>
            </div>
            <div *ngIf="isSuperAdmin" style="padding-top: 10px" class="product-details-noborder">
              <button
                type="button"
                label="Create Your Category"
                class="btn btn-colour"
                (click)="showDialog()"
              >
                <span class="p-button-label">{{'updateproduct.productcategory.button.createcategory' | translate }}</span>
              </button>
            </div>
          </div>

          <div class="container" *ngIf="step == 3" formGroupName="productImage">
            <div class="product-details-noborder">
              
              <label>{{'updateproduct.uploadimage.productimages.label' | translate }} <span class="required">*</span></label>
         

        
              <div style="width: 100%">
             
                <div class="upload-btn-wrapper">
                  <button class="btn btn-colour">{{'updateproduct.uploadimage.button.choosefiles' | translate }}</button>
                  <input
                    name="myImage"
                    type="file"
                    id="myImage"
                    accept="image/x-png, image/gif, image/jpeg"
                    (change)="onSelectedImage($event)"
                    multiple
                  />     <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="
                  isFormSubmitted && this.ProductToSave.productImages.length == 0"
                >
                {{ "validation.productimages.required" | translate }}
                </div>
                  <br />
                  <div class="invalid-feedback"></div>
                </div>

                <div class="mt-3">
                  <div class="displayFlex" *ngIf="this.ProductToSave != null">
                    <div
                      *ngFor="
                        let url of this.ProductToSave.productImages;
                        let i = index
                      "
                    >
                      <div
                        *ngIf="url.imagePath"
                        class="Productimg"
                        style="margin-right: 10px; margin-bottom: 10px"
                      >
                        <img [src]="url.imagePath" width="250px" />

                        <div class="row">
                          <div class="col-md-7 radio-btn-img">
                            <label>
                              <input
                                style="margin-right: 3px"
                                type="radio"
                                name="CoverImageId"
                                [value]="url.id"
                                [checked]="
                                  url.id === this.ProductToSave.coverImageId
                                "
                                formControlName="CoverImageId"
                                (click)="DeselectRadio('old')"
                              />
                            </label>
                          </div>
                          <div class="col-md-5 upload-img">
                            <img
                              class="edit-img"
                              src="assets/svgimages/delete.svg"
                              (click)="removeEditImage(i, url.id)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let url of sourcesFile; let i = index">
                      <div
                        class="Productimg"
                        style="margin-right: 10px; margin-bottom: 10px"
                      >
                        <img [src]="url.fileResult" width="250px" />
                        <div class="row">
                          <div class="col-md-7 radio-btn-img">
                            <label style="padding-left: 10px">
                              <input
                                type="radio"
                                name="coverImageFileName"
                                [value]="sourcesFiles[i].name"
                                [checked]="
                                  url.name ===
                                  this.ProductToSave.coverImageFileName
                                "
                                formControlName="coverImageFileName"
                                (click)="DeselectRadio('new')"
                              />
                            </label>
                          </div>
                          <div class="col-md-5 upload-img">
                            <img
                              src="assets/svgimages/delete.svg"
                              class="edit-img"
                              (click)="removeImage(url)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="coverimagediv"
                  class="text-red text-12 font-weight-bold mt-1 ng-star-inserted"
                  *ngIf="!step2 && isFormSubmitted && coverimg == false &&  this.ProductToSave.productImages.length > 0"
                >
                {{ "validation.selectcoverimage" | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="container" *ngIf="step == 4" formGroupName="productVideo">
            <div class="product-details-noborder">
              <label class="control-label">{{'updateproduct.uploadvideo.productvideo.label' | translate }}</label>

              <div style="width: 100%">
                <div class="upload-btn-wrapper">
                  <div class="row" style="width: 400px">
                    <div class="col-6">
                      <button class="btn btn-colour">{{'updateproduct.uploadvideo.button.choosefiles' | translate }}</button>
                      <input
                        name="myVideo"
                        type="file"
                        id="myVideo"
                        accept="video/*"
                        #inputVideoFile
                        (change)="onSelectVideo($event)"
                      />
                    </div>

                    <div class="col-6">
                
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div
                  class="col-md-6 mb-3"
                  *ngFor="let item of video; let i = index"
                >
                  <div class="Productimg">
                    <video width="100%" height="250px" controls>
                      <source [src]="item" type="video/mp4" />
                    </video>

                    <div class="upload-video-img">
                      <img
                        src="assets/svgimages/delete.svg"
                        class=""
                        (click)="removevideo(i)"
                        class="edit-video"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 mb-3"
                  *ngFor="
                    let item of this.ProductToSave.productVideos;
                    let i = index
                  "
                >
                  <div class="Productimg">
                    <div>
                      <video width="100%" height="250px" controls>
                        <source [src]="item.videoPath" type="video/mp4" />
                      </video>
                    </div>
                    <div class="upload-video-img">
                      <img
                        src="assets/svgimages/delete.svg"
                        class=""
                        (click)="removeEditvideo(i, item.id)"
                        class="edit-video"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div class="container" [ngClass]="step > 1 ? 'margin-top-150' : ''">
            <div class="row mt-6 product-details-noborder">
              <div class="col-4" style="text-align: left">
                <button
                  type="button"
                  class="btn btn-colour-cancel"
                  *ngIf="step != 1"
                  (click)="previous()"
                >
                {{'updateproduct.button.previous' | translate }}
                </button>
              </div>
              <div class="col-8" style="text-align: right" *ngIf="step !== 4">
                <button
                type="cancel"
                class="btn btn-colour-cancel"
                (click)="onCancelClick()"
              
              >
              {{'updateproduct.button.cancel' | translate }}
              </button>
                <button
                  type="button"
                  class="btn btn-colour"
                  *ngIf="step != 4"
                  (click)="validateSteps()"
                  style="margin-left: 15px"
                >
                {{'updateproduct.button.next' | translate }}
                </button>
              
              </div>
              <div class="col-8" *ngIf="step === 4" style="text-align: right">
              
                <button
                  type="cancel"
                  class="btn btn-colour-cancel"
                  (click)="onCancelClick()"
             
                >
                {{'updateproduct.button.next' | translate }}
                </button>
                <button
                type="button"
                class="btn btn-colour"
                (click)="UpdateProduct(true)"
                *ngIf="step == 4"
                style="margin-left: 15px"
              >
              {{'updateproduct.button.submit' | translate }}
              </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!-- <p-dialog header="{{'addcategory.title'| translate }}"[(visible)]="display" [modal]="true"  [styleClass]="'p-dialog-style-class'" [draggable]="false" [resizable]="false">
  <form #form="ngForm" [formGroup]="categoryform" >
    <div>
      <label class="control-label text-14"
        >{{'addcategory.categoryname.label' | translate }} <span class="required">*</span></label
      >
      <input
        type="text"
        class="form-control"
        placeholder="{{'addcategory.categoryname.placeholder'| translate }}"
        formControlName="categoryName"
        [focusMe]="true"
        maxlength="20"
      />
      <div
        class="text-red text-12 font-weight-bold mt-1"
        *ngIf="categoryValue.categoryName.errors?.pattern"
      >
      {{ "validation.categoryname.pattern" | translate }}
      </div>
      <div
        class="text-red text-12 font-weight-bold mt-1"
        *ngIf="
          isCategoryFormsubmitted && categoryValue.categoryName.errors?.required
        "
      >
      {{ "validation.categoryname.required" | translate }}
      </div>

    </div>
    <div class="mt-3">
      <label class="control-label text-14">{{'addcategory.description.label' | translate }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="{{'addcategory.description.placeholder'| translate }}"
        formControlName="description"
        maxlength="50"
      />
    </div>
    <div class="mt-4">
      <input
        type="checkbox"
        value=""
        id="flexCheckChecked"
        checked
        formControlName="active"
      />&nbsp;
      <label
        class="form-check-label text-14 active-font-weight"
        for="flexCheckChecked"
      >
      {{'addcategory.active' | translate }}
      </label>
    </div>
  </form>
  <div class="row mt-5">
    <div class="col-md-10"></div>
    <div class="col-md-2 ">
      <button
        type="submit"
        class="btn btn-colour mobile-btn-save"
        (click)="AddCategory()"
      >
      {{'addcategory.button.submit' | translate }}
      </button>
    </div>
  </div>
</p-dialog> -->
<!-- Mobile View -->
<div class="d-block d-md-none d-lg-none">
  <div class="container bg-color-header">
    <div class="col-12">
      <ul>
        <li class="col-3">
          <div class="tabname">
            <a>
              <span
                class="border-header"
                (click)="openTab($event, 'pd')"
                class="tablinks"
                id="defaultOpen"
              >
              {{'updateproduct.mobile.detail' | translate }}</span
              >
            </a>
          </div>
        </li>
        <li class="col-3">
          <div class="tabname">
            <a>
              <span
                class="border-header"
                (click)="openTab($event, 'pc')"
                class="tablinks"
              >
              {{'updateproduct.mobile.category' | translate }}</span
              >
            </a>
          </div>
        </li>
        <li class="col-3">
          <div class="tabname">
            <a>
              <span
                class="border-header"
                (click)="openTab($event, 'ui')"
                class="tablinks"
              >
              {{'updateproduct.mobile.images' | translate }}</span
              >
            </a>
          </div>
        </li>
        <li class="col-3">
          <div class="tabname">
            <a>
              <span
                class="border-header"
                (click)="openTab($event, 'uv')"
                class="tablinks"
              >
              {{'updateproduct.mobile.video' | translate }}</span
              >
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <form #form="ngForm" [formGroup]="productform">
    <div class="container tabcontent" id="pd" formGroupName="productDetail">
      <div class="product-details">
        <div class="mt-15">
          <label> {{'updateproduct.productdetail' | translate }}</label>
        </div>
        <div class="mt-10">
          <div class="form-group">
            <label for="exampleInput" class="text-14"
              > {{'updateproduct.productdetail.productname.label' | translate }}<span class="required">*</span></label
            >
            <input
              type="text"
              class="form-control input-box"
              placeholder="{{'updateproduct.productdetail.productname.placeholder'| translate }}"
              formControlName="name"
            />
            <div
              class="text-red text-12 font-weight-bold mt-1"
              *ngIf="productDetail.name.errors?.pattern"
            >
            {{ "validation.productname.pattern" | translate }}
            </div>
            <div
              class="text-red text-12 font-weight-bold mt-1"
              *ngIf="ispdFormSubmitted && productDetail.name.errors?.required"
            >
            {{ "validation.productname.required" | translate }}
            </div>
        
          </div>
        </div>

        <div class="form-group">
          <label for="exampleInput" class="text-14"
            > {{'updateproduct.productdetail.productdescription.label' | translate }}<span class="required">*</span></label
          >
          <angular-editor
            id="editor1"
            formControlName="description"
            [config]="config"
            (paste)="pastepreventExceedingMax($event)"
            (keydown)="restrictInputMobile($event)"
            [placeholder]="getPlaceholderText()"
          ></angular-editor>
          <div
            class="text-red text-12 font-weight-bold mt-1"
            *ngIf="
            ispdFormSubmitted && productDetail.description.errors?.required
            "
          >
          {{ "validation.description.required" | translate }}
          </div>
          <div
          class="text-red text-12 font-weight-bold mt-1"
          *ngIf="
          ispdFormSubmitted && productDetail.description.errors?.lengthError
          "
        >
        {{ "validation.description.required" | translate }}
        </div>
        <div
        class="text-red text-12 font-weight-bold mt-1"
        *ngIf="
        ispdFormSubmitted "
      >
           {{maxlengthError}}
      </div>
        </div>

        <div class="form-group">
          <label for="exampleInput" class="text-14"
            > {{'updateproduct.productdetail.sellingprice.label' | translate }}<span class="required">*</span></label
          >
          <input
            type="number"
            class="form-control"
            formControlName="sellingPrice"
            id="sellingPrice"
            placeholder="{{'updateproduct.productdetail.sellingprice.placeholder'| translate }}"
            onkeydown="return event.key !== '-' && event.key !== '+';"   
            maxlength="8" (input)="limitInputLength($event)"
            (keydown)="preventExceedingMaxLength($event)"  
            (paste)="pastepreventnegativevalue($event)" 
            (keypress)="decimalFilter($event)"
          />
      

          <div
            class="text-red text-12 font-weight-bold mt-1"
            *ngIf="
            ispdFormSubmitted && productDetail.sellingPrice.errors?.required
            "
          >
          {{ "validation.sellingprice.required" | translate }}
          </div>
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            value=""
            id="flexCheckChecked"
            formControlName="showSellingPrice"
            class="form-check-input"
            
          />
          <label class="form-check-label" for="exampleCheck1" class="text-14"
            >{{'updateproduct.productdetail.showsellingprice.label' | translate }}</label
          >
        </div>
        <div class="form-group mt-2">
          <label for="exampleInput" class="text-14">{{'updateproduct.productdetail.discount.label' | translate }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="{{'updateproduct.productdetail.discount.placeholder'| translate }}"
            formControlName="discount"
            (input)="onInput($event)" pattern="[0-9]+"
            maxlength="3"
          />
          <div
            class="text-red text-12 font-weight-bold mt-1"
            *ngIf="productDetail.discount.errors?.pattern"
          >
          {{ "validation.discount.pattern" | translate }}
          </div>
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            value=""
            id="flexCheckChecked"
            formControlName="active"
            class="form-check-input"
          />
          <label class="form-check-label" for="exampleCheck1" class="text-14"
            >{{'updateproduct.productdetail.active.label' | translate }}</label
          >
        </div>
        <div class="form-group mt-6">
          <label for="exampleInput " class="text-14">{{'updateproduct.productdetail.brandname.label' | translate }}</label>
          <input
            class="form-control"
            placeholder="{{'updateproduct.productdetail.brandname.placeholder'| translate }}"
            formControlName="brandName"
            maxlength="15"
          />
        </div>
        <div
        class="text-red text-12 font-weight-bold mt-1"
        *ngIf="productDetail.brandName.errors?.pattern"
      >
      {{ "validation.brandname.pattern" | translate }}
      </div>
   
      </div>
    </div>

    <div class="container tabcontent" id="pc" formGroupName="productCategory">
      <div class="product-details">
        <div class="mt-15">
          <label class="">{{'updateproduct.productcategory' | translate }}</label>
        </div>
        <div class="mt-10">
          <div class="form-group">
            <label for="exampleInput" class="text-14"
              >{{'updateproduct.productcategory.selectcategory.label' | translate }}<span class="required">*</span></label
            >
            <ng-select
              [items]="productCategories"
              appendTo="body"
              bindLabel="categoryName"
              bindValue="id"
              placeholder="{{'updateproduct.productcategory.selectcategory.placeholder'| translate }}"
              name="categoryName"
              [(ngModel)]="selectedCategory"
              formControlName="selectedCategory"
              [clearable]="false"
            >
            </ng-select>
            <div
              class="text-red text-12 font-weight-bold mt-1"
              *ngIf="ispcFormSubmitted && productCategory.selectedCategory.errors?.required"
            >
              Product category is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container tabcontent" id="ui" formGroupName="productImage">
      <div class="product-details">
        <div class="mt-15">
          <label class="">{{'updateproduct.uploadimage.productimages.label' | translate }}<span class="required">*</span></label>
        </div>

        <div
          class="text-red text-12 font-weight-bold mt-1"
          *ngIf="isuiFormSubmitted && this.ProductToSave.productImages.length == 0"
        >
        {{ "validation.productimages.required" | translate }}
        </div>

        <div class="row mt-2 pr-26">
          <div class="card chooes-file upload-btn-wrapper">
            <button class="btn btn-colour" (click)="myInput.click()">{{'updateproduct.uploadimage.button.choosefiles' | translate }}</button>
            <input
              #myInput
              name="myImage"
              type="file"
              id="myImage"
              accept="image/x-png, image/gif, image/jpeg"
              (change)="onSelectedImage($event)"
              multiple="multiple"
              class="input-choose mobile-view-chooes-btn"
            />
          </div>
        </div>

        <div>
          <div class="col-12" *ngFor="let item of sourcesFile; let i = index">
            <div
              class="card ml-10 upload-img-card"
              *ngIf="item.fileResult?.length > 0"
            >
              <img [src]="item.fileResult" height="200px" />
              <div class="row p-8">
                <div class="col-7 upload-img select-coverimage">
                  <input
                    type="radio"
                    name="coverImageFileName"
                    [value]="this.sourcesFile[i].fileinfo.name"
                    [checked]="
                      item.name === this.ProductToSave.coverImageFileName
                    "
                    formControlName="coverImageFileName"
                    (click)="DeselectRadio('new')"
                  />
                </div>
                <div class="col-5 upload-img">
                  <img
                    src="assets/svgimages/delete.svg"
                    class="edit-img-mobile"
                    (click)="removeImage(item)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12"
            *ngFor="let url of this.ProductToSave.productImages; let i = index"
          >
            <div
              class="card ml-10 upload-img-card"
              *ngIf="url.imagePath?.length > 0"
            >
              <img [src]="url.imagePath" height="200px" />
              <div class="row p-8">
                <div class="col-7 upload-img select-coverimage">
                  <input
                    type="radio"
                    name="CoverImageId"
                    [value]="url.id"
                    [checked]="url.id === this.ProductToSave.coverImageId"
                    formControlName="CoverImageId"
                    (click)="DeselectRadio('old')"
                  />
                </div>
                <div class="col-5 upload-img">
                  <img
                    src="assets/svgimages/delete.svg"
                    class="edit-img-mobile"
                    (click)="removeEditImage(i, url.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
        id="coverimagediv"
        class="text-red text-12 font-weight-bold mt-1 ng-star-inserted cover "
        *ngIf="isuiFormSubmitted && coverimg == false && (sourcesFile.length > 0 || ProductToSave.productImages.length > 0 )"
      >
      {{ "validation.selectcoverimage" | translate }}
      </div>
      </div>
    </div>

    <div class="container tabcontent" id="uv" formGroupName="productVideo">
      <div class="product-details">
        <div class="mt-15">
          <label>{{'updateproduct.uploadvideo.productvideo.label' | translate }}</label>
        </div>

        <div class="row mt-2 pr-26">
          <div class="card chooes-file-video upload-btn-wrapper">
            <button class="btn btn-colour" (click)="myInput.click()">{{'updateproduct.uploadvideo.button.choosefiles' | translate }}</button>
            <input
              name="myVideo"
              type="file"
              id="myVideo"
              accept="video/*"
              (change)="onSelectVideo($event)"
              #inputVideoFile
            />
          </div>
        </div>
      </div>

      <div class="row" *ngFor="let item of video; let i = index">
        <div class="card uplaod-vd-card" style="width: 100%">
          <div>
            <video width="100%" height="250px" controls>
              <source [src]="item" type="video/mp4" />
            </video>
          </div>
          <div class="upload-video-img">
            <img
              src="assets/svgimages/delete.svg"
              class="edit-video-mobile"
              (click)="removevideo(i)"
            />
          </div>
        </div>
      </div>

      <div
        class="row"
        *ngFor="let item of this.ProductToSave.productVideos; let i = index"
      >
        <div class="card uplaod-vd-card" style="width: 100%">
          <div>
            <video width="100%" height="250px" controls>
              <source [src]="item.videoPath" type="video/mp4" />
            </video>
          </div>
          <div class="upload-video-img">
            <img
              src="assets/svgimages/delete.svg"
              class="edit-video-mobile"
              (click)="removeEditvideo(i, item.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="container" *ngIf="selectdTab==='pd'">
    <div class="row mt-3 ">
 
      <div class="col-12">
        <button type="button " class="btn next-btn mb-3"(click)="openNextTab($event, 'pc')">
          {{'updateproduct.mobile.button.saveandcontinue' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="container position-btn" *ngIf="selectdTab==='pc'">
    <div class="row mt-3">
   
      <div class="col-12">
        <button type="button" class="btn next-btn mb-3 " (click)="openNextTab($event, 'ui')">
          {{'updateproduct.mobile.button.saveandcontinue' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="container position-btn-img" *ngIf="selectdTab==='ui'">
    <div class="row mt-3">
     
      <div class="col-12">
        <button type="button" class="btn next-btn mb-3" (click)="openNextTab($event, 'uv')">
          {{'updateproduct.mobile.button.saveandcontinue' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="container position-btn" *ngIf="this.formuiSubmit && selectdTab==='uv'">
    <div class="row mt-3">
  
      <div class="col-6">
        <button type="button" class="cancel-button mb-3" (click)="onCancelClick()">
          {{'updateproduct.button.cancel' | translate }}
        </button>
     
      </div>
      <div class="col-6">
        <button type="button" class="btn save-btn mb-3" (click)="UpdateProductVideo()">
          {{'updateproduct.button.submit' | translate }}
        </button>
      </div>
    </div>
  </div>

 
</div>
