import { ProductModel } from '@/Model/product';
import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../product.service';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '@services/profile.service';
import { StoreAPIService } from '@/AddStore/storeapiservice.service';
import { ShareimageService } from '@services/shareimage.service';
import { Table } from 'primeng/table';
import { Store } from '@/Model/store';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-list',
  styleUrls: ['productlist.component.css'],
  templateUrl: 'productlist.component.html',
  providers: [ConfirmationService]
})
export class ProductlistComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'name',
    'brandName',
    'sellingPrice',
    'active',
    'action',
  ];
  ProductList: ProductModel[] = [];
  ProductToSave: ProductModel = new ProductModel();
  StoreList: Store[] = [];
  pageSize = 10; // Number of items per page
  currentPage = 1; // Current page number
  totalItems: number; // Total number of items
  showPaginationControls = false;
  storeform!: FormGroup;
  selectedstore: any;
  storeIdarry: any[] = [];
  defaultstoreId: any;
  noRecordMsgFlag: boolean;
  noRecordMsg: boolean;
  searchText: string;
  users: any[];
  selectedUser: any;
  isSuperAdmin: boolean = false;
  errorMessage:boolean=false;
  userId: any;
  filteredUsers: any[];
  languageName:any;
  @ViewChild('customSearch') customSearchElement: ElementRef;
  selectedStoreOption: any;
  constructor(
    private productService: ProductService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private profileservice: ProfileService,
    private storeService: StoreAPIService,
    private route: ActivatedRoute,
    private shareimageService: ShareimageService,
    private translate: TranslateService
    ) { this.translate.setDefaultLang('en'); }

  ngOnInit(): void {
   
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
      this.translate.use('en');
     }else{
      this.translate.use(this.languageName);
     }
   
    document.getElementById("tempid_Product")?.classList.add("highlight_sideMenu");
    this.SpinnerService.show();
    this.shareimageService.setPageName("Products");
  
    this.selectedStoreOption = "";
    this.profileservice.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
      this.userId = data.id;
      if (this.isSuperAdmin === true) {
        this.profileservice.GetUsersForDropdown().subscribe((data: any) => {
          this.users = data;
          this.SpinnerService.hide();
          this.setUserPreSelection();
        });
      }
      else {
        this.GetAllStores();
      }
    },(err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);
    
  });


  }
  getNotification(){
    this.profileservice.getNotification().subscribe((data: any) => {
      this.shareimageService.setnotification(data)
      localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
    });
  }

  onChange(searchText){
   
    this.errorMessage = false;
      for (let i = 0; i < this.ProductList.length; i++) {
      if(this.ProductList[i].name.includes(this.searchText)){

        this.errorMessage = false;
        return;
      }}
      this.errorMessage = true;
       return;
  }
  SelectStore(event: any) {

    if (event === "" || event === undefined) return;
    this.selectedstore = event;
    this.GetAllProductsByStoreId();
  }

  setStorePreSelection() {
    var sid = this.route.snapshot.queryParamMap.get('sid');
    if (sid != null || sid != undefined) {
      if (this.StoreList.length > 0) {
        var sidValid = this.StoreList.filter(s => s.storeId == Number(sid))
        if (sidValid.length > 0) {
          this.selectedstore = Number(sid);
        }
      }
    }
    else {
      this.selectedstore = null;
    }

  }

  setUserPreSelection() {
    if (this.isSuperAdmin) {
      var uid = this.route.snapshot.queryParamMap.get('uid')
      if (uid != null || uid != undefined) {
        if (this.users.length > 0) {
          var uidValid = this.users.filter(u => u.id == Number(uid));
          if (uidValid.length > 0) {
            this.selectedUser = Number(uid);
            this.onUsenameDropdownChange(Number(uid), false);
          }
        }
      }
    }
  }

  SelectMobileStore(event: any) {

    if (event === "" || event === undefined) return;
    this.selectedstore = event;
    this.GetAllProductsByStoreId();
  }


  GetAllStores() {
    this.storeService.getAll().subscribe((data: any) => {
      this.StoreList = data?.filter(p => p.active == 1);
      this.setStorePreSelection();

      this.GetAllProductsByStoreId();

    });
    this.SpinnerService.hide();
  }


  isEmpty(event) {

    if (event.filteredValue.length == 0) {
      this.noRecordMsgFlag = true;
    }
    else {
      this.noRecordMsgFlag = false;
    }
  }

  onUsenameDropdownChange(event, reload) {
    localStorage.setItem('selectedId', event);
    if (event === undefined || event == null) return;
    this.SpinnerService.show();
    this.storeService.GetStoresByUserId(event).subscribe((data: any) => {

      this.StoreList = data.filter(p => p.active == 1);
      this.SpinnerService.hide();

      if (reload == false) {
        var sid = this.route.snapshot.queryParamMap.get('sid');
        if (sid != null || sid != undefined) {
          this.selectedstore = Number(sid);
        }
      }
      else {
        this.selectedstore = null;
      }

      this.GetAllProductsByStoreId();
    })
  }


  GetAllProductsByStoreId() {
    if (this.selectedstore === undefined || this.selectedstore === null) {

      if (this.StoreList !== null && this.StoreList?.length > 0) {
        this.noRecordMsg = true;
        this.defaultstoreId = this.StoreList[0];
        this.selectedstore = this.defaultstoreId.storeId;
        this.SpinnerService.show();
        this.productService.GetAllProduct(this.defaultstoreId.storeId).subscribe((data: any) => {
          if (data.length === 0) {
            this.ProductList = null;
       
          } else {
            this.ProductList = data;
            this.totalItems=this.ProductList.length;
         
            this.selectedstore = this.defaultstoreId.storeId;
          }
          this.SpinnerService.hide();
        })
      }
      else {
        this.ProductList = null;
   
      }
    }
    else {

      this.SpinnerService.show();
      this.productService.GetAllProduct(this.selectedstore).subscribe((data: any) => {
        if (data.length === 0) {
          this.ProductList = null;
        } else {
          this.ProductList = data;
        }
        this.SpinnerService.hide();
      }), (error: any) => {
        this.SpinnerService.hide();
      };
    }
  }

  Reset() {
    this.GetAllStores();
  }


  onRefreshmobile() {
    this.searchText = "";
    if (this.isSuperAdmin === false) {
      this.GetAllProductsByStoreId();
    }
    else {
      this.GetAllProductsByStoreId();
    }

  }
  onRefresh(table: Table) {
    table.clear();
    this.customSearchElement.nativeElement.value = "";
    if (this.isSuperAdmin === false) {
      this.GetAllProductsByStoreId();
    }
    else {
      this.GetAllProductsByStoreId();
    }

  }
  onAddClick() {

    if (this.StoreList == null || this.StoreList === undefined || this.StoreList.length == 0) {
      if (this.isSuperAdmin) {
   
        const translatedMessage = this.translate.instant('toastr.info.productlist.selectstore');
        this.toastr.info(translatedMessage);
      } else {
    
        const translatedMessage = this.translate.instant('toastr.info.productlist.createstore');
        this.toastr.info(translatedMessage);
      }

      return;
    }
    if (this.selectedstore != null) {

      this.router.navigateByUrl('dashboard/product/' + this.selectedstore + '/addproduct');
    } else {
      if (this.selectedstore == null) {

        this.defaultstoreId = this.StoreList[0];
        this.router.navigateByUrl('dashboard/product/' + this.defaultstoreId.storeId + '/addproduct');
      } else {
        const translatedMessage = this.translate.instant('toastr.error.productlist');
        this.toastr.error(translatedMessage);
     
      }
    }
  }

  onEditClick(id: any) {
    this.router.navigateByUrl('dashboard/product/' + id + '/editproduct');
  }

  onDeleteClick(productId: any) {
    this.translate.get('deleteProductConfirmationDialog').subscribe((translations:any)=>{
      this.confirmationService.confirm({
        message:translations.message,
        header: translations.title,
        icon: 'pi pi-info-circle',
        accept: () => {
  
          this.productService.DeleteProduct(productId).subscribe((data: any) => {
            (data);
            this.SpinnerService.hide();
            const translatedMessage = this.translate.instant('toastr.success.productdelete');
            this.toastr.success(translatedMessage);
            this.GetAllProductsByStoreId();
  
          }), (error: any) => {
            const translatedMessage = this.translate.instant('toastr.error.errordeleteproduct');
            this.toastr.error(translatedMessage);
         
          };
        },
        reject: () => {
        },
        acceptLabel: translations.accept,
        rejectLabel: translations.reject
      });
    })



  }
  pageChanged(event: any) {
    this.currentPage = event;
}
  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    document.getElementById("tempid_Product").classList.remove("highlight_sideMenu");
  }


}
