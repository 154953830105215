import { FormGroup } from '@angular/forms';
export function PasswordValidator(
  oldControlName: string,
  newControlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    let oldControl = formGroup.controls[oldControlName];
    let newControl = formGroup.controls[newControlName];
    let matchingControl = formGroup.controls[matchingControlName];

    if (newControl.errors && newControl.errors.minlength) {
      return;
    }

    if (newControl.errors && newControl.errors.required) {
      return;
    }
    if (newControl.value === '') {
      newControl.setErrors({ required: true });
    } else {
      newControl.setErrors(null);
    }

    if (newControl.value.length === 8 || newControl.value.lengh >= 8) {
      newControl.setErrors(null);
    } else {
      newControl.setErrors({ minlength: true });
    }

    if (
      oldControl.value !== newControl.value ||
      oldControl.value === '' ||
      newControl.value === ''
    ) {
      newControl.setErrors(null);
    } else {
      newControl.setErrors({ passwordValidator: true });
    }

    if (
      matchingControl.errors &&
      !matchingControl.errors.confirmPasswordValidator
    ) {
      return;
    }

    if (newControl.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
