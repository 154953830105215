import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

  constructor(private router: Router, private http: HttpClient) { }
  forgotpassword(email: any) {

    return this.http.post(`${environment.userApiUrl}/User/ForgotPassword/${email}`, email,{responseType: 'text'})
  }
}
