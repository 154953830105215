import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { environment } from "environments/environment";
import { map } from "rxjs/operators";

@Injectable({providedIn:'root'})

export class MobileNumberValidater{
    constructor(private http:HttpClient){}

    checkMobileNumber(mobileNumber :any){
        return this.http.get<{ success: boolean }>(`${environment.userApiUrl}/User/CheckDuplicateByMobileNumber/${mobileNumber}`).pipe(
            map(res => {console.log('res',res.success);   
      
              return res.success ? { mobileNumberTaken: true } : null;
          } )
          );
    }
}