import { ConfirmationService } from 'primeng/api';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareimageService } from '@services/shareimage.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { RegisterService } from '@services/register.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterModel } from '@/Model/register';
import { ProfileService } from '@services/profile.service';
import intlTelInputObject from 'intl-tel-input';
import intlTelInput from 'intl-tel-input';
import { MobileNumberValidater } from '@/validators/mobileNumberValidators';
import { EmailValidator } from '@/validators/emailValidators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  providers: [ConfirmationService]
})
export class AddUserComponent implements OnInit {
  @ViewChild('phoneInput') phoneInput: ElementRef;
  public userlistForm: FormGroup;
  public isFormSubmitted: boolean = false;
  userobj: RegisterModel = new RegisterModel();
  accessGiven: boolean = false;
  show_userbutton: Boolean = false;
  show_usereye: Boolean = false;
  validationerror: string='';
  duplicateMobileNumber: string='';
  languageName: any;
  mobileNumberValue: any;
  iti: intlTelInput.Plugin;
  iti1: intlTelInput.Plugin;
  options: intlTelInput.Options = { separateDialCode: true, autoInsertDialCode: true, utilsScript: "./assets/js/intl-tel-input/utils.js", formatOnDisplay: false, autoPlaceholder: 'off' }
  constructor(
    private SpinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private shareimageService: ShareimageService,
    private registerService: RegisterService,
    private router: Router,
    private toastr: ToastrService,
    private confirmationservice: ConfirmationService,
    private profileservice: ProfileService,
    private mobileNumberValidator: MobileNumberValidater,
    private emailValidator: EmailValidator,
    private translate: TranslateService

  ) {
    this.translate.setDefaultLang('en')
  }

  ngOnInit(): void {
    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    this.profileservice.getUserById().subscribe((data: any) => { }, (err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);

    });
    this.shareimageService.setPageName("Users");
    this.profileservice.getNotification().subscribe((data: any) => {
      this.shareimageService.setnotification(data)
      localStorage.removeItem('notificationDataUserId');
      localStorage.removeItem('notificationDataUserName');
    });
    this.userlistForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern(/^\S+$/)]),
      email: new FormControl('', [Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")], [this.emailValidator.checkEmail.bind(this.emailValidator)]),
      active: new FormControl(false),
      isSuperAdmin: new FormControl(false),

    });
  }
  ngAfterViewInit() {
    this.iti = intlTelInputObject(this.phoneInput.nativeElement, this.options);
    this.iti.setCountry('in');
  }
  get f() {
    return this.userlistForm.controls;
  }

  onCancelClick() {
    this.router.navigateByUrl("dashboard/users");
  }

  onSubmit() {
    this.isFormSubmitted = true;
    this.SpinnerService.show();

    if (this.userlistForm.invalid) {
      this.SpinnerService.hide();
      return
    }
    else {
      if (!this.iti.isValidNumber()) {
        this.validationerror = this.getValidationErrorMessage(this.iti.getValidationError());
        this.SpinnerService.hide();
      }
      else {
        if (this.duplicateMobileNumber == "") {
          const registerdata = new FormData();
          registerdata.append('firstName', this.userlistForm.value.firstName);
          registerdata.append('lastName', this.userlistForm.value.lastName);
          registerdata.append("mobileNumber", '+' + this.iti.getSelectedCountryData().dialCode + ' ' + this.userlistForm.value.mobileNumber);
          registerdata.append('email', this.userlistForm.value.email);
          registerdata.append('active', this.userlistForm.value.active);
          registerdata.append('isSuperAdmin', this.userlistForm.value.isSuperAdmin);
          this.registerService.createUser(registerdata).subscribe(() => {
            this.SpinnerService.hide();
            const translatedMessage = this.translate.instant('toastr.success.userregister');
            this.toastr.success(translatedMessage);
            this.router.navigateByUrl("dashboard/users");
          },
            (err: HttpErrorResponse) => {
              if (err.error.message == 'Email already exists') {
                const translatedMessage = this.translate.instant('validation.email.duplicate');
                this.toastr.error(translatedMessage);
                this.SpinnerService.hide();
              }
              else if (err.error.message == 'Mobile number already exists') {
                const translatedMessage = this.translate.instant('validation.mobilenumber.duplicate');
                this.toastr.error(translatedMessage);
                this.SpinnerService.hide();
              }
              else if (err.error.message == 'Username') {
                const translatedMessage = this.translate.instant('toastr.username.duplicate');
                this.toastr.error(translatedMessage);
                this.SpinnerService.hide();
              }
              else if (err.error.errors.MobileNumber[0] == 'Invalid Mobile Number') {
                const translatedMessage = this.translate.instant('toastr.mobilenumber.pattern');
                this.toastr.error(translatedMessage);
                this.SpinnerService.hide();
              }
              else {
                const translatedMessage = this.translate.instant('toastr.error');
                this.toastr.error(translatedMessage);
                this.SpinnerService.hide();
              }
            }
          )
        }
        this.SpinnerService.hide();
      }


    }
  }
  onInput(event: any) {
    this.validationerror='';
    if (/(\d)\1{5,}/.test(event.target.value)) {
      this.validationerror ="Invalid number"
    }
  
    this.mobileNumberValue = '+' + this.iti.getSelectedCountryData().dialCode + ' ' + this.userlistForm.value.mobileNumber;
    this.mobileNumberValidator.checkMobileNumber(this.mobileNumberValue).subscribe((data: any) => {
      if (data.mobileNumberTaken === true) {
        this.duplicateMobileNumber = 'Mobile number already exists';
      } else {
        this.duplicateMobileNumber = '';
      }
    })
  }
  onInputKeyPress = (event: KeyboardEvent) => {
    const allowedChars = /[0-9\+\-\ ]/;
    if (
      !allowedChars.test(event.key) 
    ) {
      event.preventDefault();
    }
  };

  getValidationErrorMessage(errorCode) {
    switch (errorCode) {
      case window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return "Invalid mobile number";
      case window.intlTelInputUtils.validationError.TOO_SHORT:
        return "Invalid mobile number";
      case window.intlTelInputUtils.validationError.TOO_LONG:
        return "Invalid mobile number";
      case window.intlTelInputUtils.validationError.NOT_A_NUMBER:
        return "Invalid mobile number";
      default:
        return "Invalid mobile number";

    }
  }
  onCheckboxChange() {
    this.confirmationservice.confirm({
      message: 'Are you sure do you want to give superadmin access? ',
      header: 'Super Admin Access Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.accessGiven = true;

      },
      reject: () => {
        this.accessGiven = false;
      }
    });

  }
}
