<div class="container siginpage-container">
  <div class="row">
    <div class="card signin-page">
      <div class="MartsUp-header">
        <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
      </div>
      <div class="header-border"></div>
      <div class="card-body pt-2">
        <div class="signin-header mb-3">
          <span class="signin"> {{ "login.title" | translate }}</span>
        </div>
        <ng-select
          [items]="languages"
          bindLabel="language"
          bindValue="id"
          [(ngModel)]="selectedLanguage"
          (change)="useLanguage($event)"
          [clearable]="false"
          [searchable]="false"
          placeholder="{{ 'login.selectlanguage.text' | translate }}"
        >
        </ng-select>
 
         
        <div class="mt-3">
          <form
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit()"
            class="sigin-form"
          >
            <div class="form-group">
              <img src="assets\svgimages\Email.svg" class="email-icon" />
              <label for="exampleInputEmail1 " class="email-text"
                >{{ "login.username.label" | translate
                }}<span class="required" style="color: red">*</span></label
              >
              <input
                type="email"
                class="form-control"
                formControlName="username"
                maxlength="50"
                placeholder="{{ 'login.username.placeholder' | translate }}"
                [focusMe]="true"
              />
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.username.errors?.required"
              >
                {{ "validation.username.required" | translate }}
              </div>
            </div>
            <div class="form-group">
              <img src="assets\svgimages\password svg.svg" class="email-icon" />
              <label for="exampleInputPassword1" class="email-text"
                >{{ "login.password.label" | translate
                }}<span class="required" style="color: red">*</span></label
              >
              <div class="password">
                <input
                  [type]="show_button ? 'text' : 'password'"
                  class="form-control"
                  id="exampleInputPassword1"
                  formControlName="password"
                  placeholder="{{ 'login.password.placeholder' | translate }}"
                />
                <span class="eyeicon">
                  <i
                    [class]="show_eye ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    (click)="showPassword()"
                  ></i
                ></span>
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.password.errors?.required"
              >
                {{ "validation.password.required" | translate }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 mb-3">
                <a class="forgot-pass-text" [routerLink]="['/forgot-password']">
                  {{ "login.forgotpassword.link" | translate }}
                </a>
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="btn btn-secondary btn-lg btn-block signin-btn mt-3"
              >
                <div>
                  {{ "login.button.name" | translate }}
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </div>
              </button>
            </div>
            <div
              class="row"
              *ngIf="
                !f.password.errors?.required && !f.username.errors?.required
              "
            >
              <div
                class="col-12 col-sm-12 text-red text-12 font-weight-bold"
              >
                <div *ngIf="errorMessage === 'Username or password is invalid'">
                  {{ "validation.login.errormessage.invalid" | translate }}
                </div>
                <div
                  *ngIf="
                    errorMessage ===
                    'Your account is inactive. A confirmation will be sent to you once it has been activated.'
                  "
                >
                  {{ "validation.accountinactive" | translate }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 mt-3 new-user-text">
                {{ "login.register.newUser.text" | translate }}
              </div>
            </div>
            <div class="row text-center">
              <a [routerLink]="['/register']" class="createaccount-text">
                {{ "login.register.link" | translate }}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
