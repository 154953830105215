import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private router: Router, private http: HttpClient) { }

  registration(registerdata: any) {
    return this.http.post(`${environment.userApiUrl}/User/Register`, registerdata);
  }

  createUser(registerdata: any) {
    return this.http.post(`${environment.userApiUrl}/User/Create`, registerdata);
  }

  checkDuplicateByMobileNumber(mobilenumber: any) {
    return this.http.post(`${environment.userApiUrl}/User/CheckDuplicateByMobileNumber/${mobilenumber}`,mobilenumber);
  }

  checkDuplicateByEmail(email:any){
    return this.http.post(`${environment.userApiUrl}/User/CheckDuplicateByEmail/${email}`,email);
  }
}
