  <div class="modal fade" id="exampleModalLong1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="">
          <h5 class="modal-title" id="exampleModalLongTitle"> 
            <div class=" pt-3 pb-3">
            <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
          </div>
        </h5>
          <button type="button" class="close btn-close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="header-border  w-100"></div>
        <div class="modal-body text-position">
          <div class="card-body pt-2 term-body">
            <div class="signin-header mb-3">
              <span class="signin">Privacy Policy</span>
            </div>
            <div>
              <ol>
                <p>
                  This Privacy Policy aims to provide you with a better understanding of how we collect, use, and share your personal information. We may update this policy if there are any changes to our privacy practices. In case of significant changes, we will notify you through the MartsUp admin or by email.              
                </p>
              </ol>
              <ol>
                <p>
                  MartsUp is dedicated to safeguarding the privacy of your personal information. By using the Service, you accept and agree that our Privacy Policy governs MartsUp's collection, use, and sharing of this personal information.
                </p>
              </ol>
              <ol>
                <p>
                  Our values:
                </p>
              </ol>
              <ol>
                <p>
                  Trust is fundamental to the MartsUp platform, and we are committed to handling your information responsibly. Our values guide our product and service development, ensuring that your information and privacy are treated with utmost care.
                </p>
              </ol>
              <ol>
                <p>
                  Your information belongs to you:
                </p>
              </ol>
              <ol>
                <p>
                  We carefully assess the information required to provide our services and strive to collect only what is necessary. We aim to delete or anonymize this information when it is no longer needed. Our engineers work closely with our privacy and security teams to prioritize your ownership of information, utilizing it solely for your benefit.
                </p>
              </ol> 
              <ol>
                <p>
                  We protect your information from others:
                </p>
              </ol>
              <ol>
                <p>
                  We prioritize the protection of your personal information and will not share it with third parties unless you grant permission or if it is legally required. If we are legally obligated to share your information, we will notify you in advance.
                </p>
              </ol>
              <ol>
                <p>
                  We help merchants and partners meet their privacy obligations:
                </p>
              </ol>
              <ol>
                <p>
                  We understand that many merchants and partners using MartsUp may not have dedicated privacy teams. Therefore, we strive to assist them in fulfilling their privacy obligations. We design our products and services to be privacy-friendly and provide comprehensive FAQs and documentation on essential privacy topics. We are also available to address any privacy-related inquiries.
                </p>
              </ol>
              <ol>
                <p>
                  Why we process your information:
                </p>
              </ol>
              <ol>
                <p>
                  We process your information when fulfilling contractual obligations, such as processing subscription payments for using the MartsUp platform. We may also process your personal information with your consent, to meet business-related requirements, or when legally required. You have the right to withdraw your consent at any time.
                </p>
              </ol>
              <ol>
                <p>
                  Your rights over your information:
                </p>
              </ol>
              <ol>
                <p>
                  We believe you should have control over your personal information, regardless of your location. Depending on your use of MartsUp, you may have rights to access, correct, amend, delete, port, restrict, or object to certain uses of your information. Exercising these rights will not result in additional charges or a different level of service. Some rights may be limited by law and may apply only in specific circumstances. 
                </p>
              </ol>
              <ol>
                <p>
                  Retention of your information:
                </p>
              </ol>
              <ol>
                <p>
                  We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected. The retention period is determined by factors such as the nature of the data, potential risks, processing purposes, legal requirements, and our ability to achieve those purposes through alternative means. We will also retain and use your information to comply with legal obligations, resolve disputes, and enforce our policies. If you stop using our services or delete your account, we will delete your information or store it in an aggregated and anonymized format.
                </p>
              </ol>
              <ol>
                <p>
                  How we protect your information:
                </p>
              </ol>
              <ol>
                <p>
                  We prioritize the security and integrity of our platform and have dedicated teams working tirelessly to safeguard your information. However, it's important to note that no method of transmission or electronic storage can guarantee 100% security. Therefore, we cannot provide absolute assurance regarding the security of your personal information.
                </p>
              </ol>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary button-color" data-dismiss="modal">Close</button>
        
        </div>
      </div>
    </div>
  </div>