<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid main-content">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-9 add-store">
        <span class="font-weight-600 text-23 text-16 add-store-font">
          {{ "updatecategory.title" | translate }}</span
        >
      </div>
    </div>
    <div class="row mobile-view-border">
      <div class="col-md-1"></div>
      <div class="col-md-9">
        <form
          #form="ngForm"
          [formGroup]="categoryform"
          (ngSubmit)="submit()"
          (keydown.enter)="$event.preventDefault()"
        >
          <div class="contact-form">
            <div class="form-group mb-0">
              <label class="control-label col-sm-4 text-14"
                >{{ "updatecategory.categoryname.label" | translate }}
                <span class="required">*</span></label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'updatecategory.categoryname.placeholder' | translate
                  }}"
                  formControlName="categoryName"
                  [focusMe]="true"
                  maxlength="20"
                />
                <div class="text-red text-12 font-weight-bold mt-1">
                  <div
                    class="text-red text-12 font-weight-bold mt-1"
                    *ngIf="isFormSubmitted && f.categoryName.errors?.required"
                  >
                    {{ "validation.categoryname.required" | translate }}
                  </div>
                  <div *ngIf="f.categoryName.errors?.pattern">
                    {{ "validation.categoryname.pattern" | translate }}
                  </div>
                </div>
              </div>
            </div>

            <br />
            <div class="form-group mb-0">
              <label class="control-label col-sm-8 text-14"
                >{{ "updatecategory.description.label" | translate }}
              </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'updatecategory.description.placeholder' | translate
                  }}"
                  maxlength="50"
                  formControlName="description"
                />
              </div>
            </div>
            <br />
            <div class="form-group">
              <div class="col-sm-10">
                <input
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked
                  formControlName="active"
                />&nbsp;
                <label
                  class="form-check-label text-14 active-font-weight"
                  for="flexCheckChecked"
                >
                  {{ "updatecategory.active" | translate }}
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="mb-4 mt-3">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-11 mobile-button-padding desktop-button-padding">
          <button
            type="cancel"
            class="btn btn-colour-cancel mobile-btn-save-cancel"
            (click)="onCancelClick()"
          >
            {{ "updatecategory.button.cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-colour mobile-btn-save"
            (click)="submit()"
          >
            {{ "updatecategory.button.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
