import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetpasswordService {

  constructor(private router: Router, private http: HttpClient) { }
  setpassword(setPassword: any) {
    return this.http.post(`${environment.userApiUrl}/User/SetPassword`,setPassword,{responseType: 'text'})
  }
  checkDuplicateUsername(username: any) {
    return this.http.post(`${environment.userApiUrl}/User/CheckDuplicateByUsername/${username}`,username)
  }
}
