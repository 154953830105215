import { EmailValidator } from '@/validators/emailValidators';
import { RegisterService } from './../../services/register.service';
import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostBinding,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MobileNumberValidater } from '@/validators/mobileNumberValidators';
import intlTelInputObject from 'intl-tel-input';
import intlTelInput from 'intl-tel-input';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  @ViewChild('phoneInput') phoneInput: ElementRef;
  public registerForm: FormGroup;
  loading: boolean = false;
  public isFormSubmitted: boolean = false;
  emailError: string;
  mobileNumberError: string;
  loginError: string;
  validationerror: string='';
  duplicateMobileNumber: string='';
  mobileNumberValue: any;
  userId: any;
  languageName: any;
  iti: intlTelInput.Plugin;
  iti1: intlTelInput.Plugin;
  options: intlTelInput.Options = {
    separateDialCode: true, autoInsertDialCode: true, utilsScript: "./assets/js/intl-tel-input/utils.js",
    autoPlaceholder: 'off', formatOnDisplay: false,
  }

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private registerService: RegisterService,
    private emailValidator: EmailValidator,
    private mobileNumberValidator: MobileNumberValidater,
    private translate: TranslateService

  ) { this.translate.setDefaultLang('en'); }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'register-page'
    );

    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern(/^\S+$/)]),
      email: new FormControl('', [Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")], [this.emailValidator.checkEmail.bind(this.emailValidator)]),
      acceptTerm: new FormControl('', [Validators.requiredTrue])

    });

  }
  ngAfterViewInit() {
    this.iti = intlTelInputObject(this.phoneInput.nativeElement, this.options);
    this.iti.setCountry('in');
  }
  onInput(event: any) {
    if (/(\d)\1{5,}/.test(event.target.value)) {
      this.validationerror ="Invalid number"
    }
    else{
      this.validationerror = "";
      this.mobileNumberValue = '+' + this.iti.getSelectedCountryData().dialCode + ' ' + this.registerForm.value.mobileNumber;
      this.mobileNumberValidator.checkMobileNumber(this.mobileNumberValue).subscribe((data: any) => {
        if (data === null) {
          this.duplicateMobileNumber = '';
        } else {
          this.duplicateMobileNumber = 'Mobile number already exists';
        }
      })
    }
   
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
  
    if(this.validationerror!= "" ){
      return
    }
    this.isFormSubmitted = true;
    this.loginError = "";
    if (this.registerForm.invalid) {
      this.loading = false;
    }
    else if (this.registerForm.value.acceptTerm) {
      if (!this.iti.isValidNumber()) {
        this.validationerror = this.getValidationErrorMessage(this.iti.getValidationError());
        this.loading = false;
      }
      else {
        this.loading = true;
        if (this.duplicateMobileNumber == "") {
          this.validationerror = "";
          const registerdata = new FormData();
          registerdata.append('firstName', this.registerForm.value.firstName);
          registerdata.append('lastName', this.registerForm.value.lastName);
          registerdata.append("mobileNumber", '+' + this.iti.getSelectedCountryData().dialCode + ' ' + this.registerForm.value.mobileNumber)
          registerdata.append('email', this.registerForm.value.email);

          this.registerService.registration(registerdata).subscribe((data: any) => {
            this.userId = data.data.userId;
            localStorage.setItem('user', JSON.stringify(this.registerForm.value));
            this.loading = false;
            this.router.navigateByUrl('/verify-otp-password/' + this.userId);
          },
            (error: any) => {
              if (error.message = "Mobile number already exists") {
                this.loginError = "Mobile number already exists";
                this.loading = false;
              }
              else if (error.message = "Email already exists ") {
                this.loginError = "Email already exists";
                this.loading = false;
              } else {
                this.loginError = "Something went wrong";
                this.loading = false;
              }
            }
          )
        }
        else {
          this.loading = false;
        }
      }
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
    this.iti.destroy();
  }

  getValidationErrorMessage(errorCode) {
    switch (errorCode) {
      case window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return "Invalid mobile number";
      case window.intlTelInputUtils.validationError.TOO_SHORT:
        return "Invalid mobile number";
      case window.intlTelInputUtils.validationError.TOO_LONG:
        return "Invalid mobile number";
      case window.intlTelInputUtils.validationError.NOT_A_NUMBER:
        return "Invalid mobile number";
      default:
        return "Invalid mobile number";
    }
  }
  onInputKeyPress = (event: KeyboardEvent) => {
    const allowedChars = /[0-9\+\-\ ]/;
    if (
      !allowedChars.test(event.key) 
    ) {
      event.preventDefault();
    }
  };

}
