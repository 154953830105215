export class Store {
  storeId?: number = 0;
  userId?: number = 0;
  name: string;
  tagline: string;
  theme: string;
  supportsMultipleLang: boolean;
  storeRoute: string;
  contactNumber: string;
  description: string;
  email: string;
  address: string;
  whatsAppNumber: string;
  facebookUrl: string;
  twitterUrl: string;
  instagramUrl: string;
  createdOn: Date;
  createdBy: string;
  active: boolean;
  backgroundImage: FormData;
  backgroundImagePath: string;
  gstNo: string;
  publishStatus: string;
  success: boolean;
  storeURL: string;
}
