
export class RegisterModel {
    id?:number=0;
    firstName: string= '';
    lastName: string= '';
    mobileNumber:string='';
    email: string= '';
    password: string= '';
    isSuperAdmin:boolean;
    active:boolean;
    username: string = '';
    
}
