import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class EmailValidator {

  constructor(private http: HttpClient) {}

  checkEmail(email: FormControl) {

    if(email.value===null ||email.value===""){
      return Promise.resolve('');
    }
   else
    return this.http.get<{ success: boolean }>(`${environment.userApiUrl}/User/CheckDuplicateByEmail/${email.value}`).pipe(
      map(res => {console.log('res',res.success);   

        return res.success ? { emailTaken: true } : null;
    } )
    );
  }

}