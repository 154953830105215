import { StoreAPIService } from '@/AddStore/storeapiservice.service';
import { ProductModel } from '@/Model/product';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private apiURL = "";

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient, private storeService: StoreAPIService) {
    this.apiURL = environment.prodApiUrl;
  }

  GetAllProduct(StoreId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.apiURL + `/Product/GetAll/${StoreId}`);

  }

  CreateNewProduct(product: any): Observable<object> {
    return this.httpClient.post<any[]>(this.apiURL + `/Product/Save`, product);

  }
  AddNewProduct(product: any): Observable<object> {
    return this.httpClient.post<any[]>(this.apiURL + `/Product/CreateProduct`, product);

  }
  AddNewProductCategory(product: any): Observable<object> {
    return this.httpClient.put<any[]>(this.apiURL + `/Product/UpdateCategoryId`, product);

  }
  AddNewProductImages(product: any): Observable<object> {
    return this.httpClient.post<any[]>(this.apiURL + `/Product/AddProductImages`, product);

  }
  UpdateProductDetails(product: any): Observable<object> {
    return this.httpClient.put<any[]>(this.apiURL + `/Product/UpdateProduct`, product);

  }
  UpdateProductImagesOnAdd(product: any): Observable<object> {
    return this.httpClient.put<any[]>(this.apiURL + `/Product/UpdateProductImgaesOnAdd`, product);

  }
  UpdateProductImages(product: any): Observable<object> {
    return this.httpClient.put<any[]>(this.apiURL + `/Product/UpdateProductImgaes`, product);

  }
  AddNewProductVideo(product: any): Observable<object> {
    return this.httpClient.post<any[]>(this.apiURL + `/Product/AddProductVideo`, product);

  }
  UpdateProductVideo(product: any): Observable<object> {
    return this.httpClient.put<any[]>(this.apiURL + `/Product/UpdateProductVideo`, product);

  }
  
  GetProductByProductId(ProductId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.apiURL + `/Product/GetProduct/${ProductId}`)

  }

  UpdateProduct(product: any): Observable<object> {
    return this.httpClient.put<any[]>(this.apiURL + `/Product/Update`, product);

  }

  DeleteProduct(ProductId: number) {
    return this.httpClient.delete<any[]>(this.apiURL + `/Product/Delete?productId=${ProductId}`)

  }

  GetAllStores() {
    return this.storeService.getAll();

  }

  GetStoreByStoreId(StoreId: number) {
    return this.storeService.getStoreById(StoreId);

  }


  find(id: number): Observable<ProductModel> {
    return this.httpClient.get<ProductModel>(this.apiURL + '/posts/' + id)
      .pipe(
        catchError(this.errorHandler)
      )
  }



  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
