import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function notOnlyNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue = control.value;
    if (inputValue && /^\d+$/.test(inputValue)) {
      return { onlyNumbers: true };
    }
    return null;
  };
}