<div id="content">
  <div class="container-fluid main-content d-none d-md-block d-lg-block">
    <div class="row product-list-table mt-3">
      <p-table
        #dt
        [value]="ProductList"
        (onFilter)="isEmpty($event)"
        [rows]="10"
        [paginator]="true"
        class="table-padding table-width"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        styleClass="p-datatable-customers p-datatable-striped"
        [globalFilterFields]="['name', 'email', 'whatsAppNumber']"
        [rowHover]="true"
        dataKey="id"
        [showCurrentPageReport]="true"
        sortField="id"
        sortOrder="-1"
        [showCurrentPageReport]="true"
        [scrollable]="true"
        scrollDirection="horizontal" 
        scrollHeight="700px"
      >
        <ng-template pTemplate="caption">
          <div class="row">
           
            <div [ngClass]="isSuperAdmin === true ? 'col-3' : 'col-6'">
              <span class="p-input-icon-left" style="width: 100%">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  class="p-inputtext search_bar"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{'productlist.searchproduct.placeholder'| translate }}"
                  #customSearch
                />
              </span>
            </div>
            <div class="col-3 height38" *ngIf="isSuperAdmin === true">
              <ng-select
                *ngIf="isSuperAdmin === true"
                [items]="users"
                autofocus
                bindLabel="name"
                bindValue="id"
                placeholder="{{'productlist.selectusername.placeholder'| translate }}"
                name="id"
                [(ngModel)]="selectedUser"
                (ngModelChange)="onUsenameDropdownChange($event, true)"
                [clearable]="false"
              >
              </ng-select>
            </div>
            <div class="col-2">
             
              <ng-select
                [items]="StoreList"
                autofocus
                bindLabel="name"
                bindValue="storeId"
                placeholder="{{'productlist.selectstore.placeholder'| translate }}"
                name="storeId"
                [(ngModel)]="selectedstore"
                (ngModelChange)="SelectStore($event)"
                [clearable]="false"
              >
             </ng-select>
            </div>

            <div class="col-4" style="text-align: right">
              <button
                class="btn btn-lg btn-secondary btn_space"
                type="submit"
                (click)="onAddClick()"
                style="margin-right: 10px"
              >
                <img src="assets/svgimages/plus.svg" class="h-14" alt="" />{{ "productlist.button.addproduct" | translate }}
              </button>
              <button
                class="btn btn-primary btn_space"
                type="submit"
                (click)="onRefresh(dt)"
                title="{{'productlist.title.refresh'| translate }}"
              >
                <img src="assets/svgimages/Refresh.svg" class="h-14" alt="" />
              </button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" class="store-list-border">
          <br />

          <tr>
            <th pSortableColumn="name">
              {{ "productlist.productname" | translate }}<p-sortIcon field="name"></p-sortIcon>
            </th>

            <th pSortableColumn="sellingPrice">
              {{ "productlist.sellingprice" | translate }}<p-sortIcon field="sellingPrice"></p-sortIcon>
            </th>
            <th pSortableColumn="brandName">
              {{ "productlist.discount" | translate }}<p-sortIcon field="discount"></p-sortIcon>
            </th>
            <th pSortableColumn="active">
              {{ "productlist.status" | translate }} <p-sortIcon field="active"></p-sortIcon>
            </th>
            <th pSortableColumn="inventoryStatus" class="width-20percent">
              {{ "productlist.action" | translate }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr class="p-table">
            <td class="width-20percent text-transform-capitalize">
              {{ data.name }}
            </td>
            <td class="width-20percent">
              {{ data.sellingPrice | currency: "INR" }}
            </td>
            <td class="width-20percent">
              {{ data.discount > 0 ? data.discount + "%" : "No Discount" }}
            </td>
            <td class="width-20percent">
              <div *ngIf="data.active == true">
                <span class="status text-success">•</span> {{ "productlist.active" | translate }}
              </div>
              <div *ngIf="data.active == false">
                <span class="status text-warning">•</span> {{ "productlist.inactive" | translate }}
              </div>
            </td>

            <td class="store-list-icons">
              <button
                class="btn btn-rounded-hash mr-1"
                (click)="onEditClick(data.id)"
                title="{{'productlist.title.edit'| translate }}"
              >
                <img
                  src="assets/svgimages/editred.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
              <button
                class="btn btn-rounded-hash mr-1"
                (click)="onDeleteClick(data.id)"
                title="{{'productlist.title.delete'| translate }}"
              >
                <img
                  src="assets/svgimages/delete.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <!-- <div class="p-d-flex p-ai-center p-jc-between">
            There are
            {{ ProductList ? ProductList.length : 0 }} products to show
          </div> -->
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" style="text-align: left">{{ "productlist.text" | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-confirmDialog
    class="store-list-popup"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>

  <!-- Mobile view HTML -->
  <div class="d-block d-md-none d-lg-none container-fluid">
    <div class="mt-3">
      <div class="has-search position-relative">
        <i class="fa fa-search form-control-feedback"></i>
        <input
          type="text"
          class="form-control mobSearch"
          placeholder="{{'productlist.selectstore.placeholder'| translate }}"
          [(ngModel)]="searchText"
          autocomplete="off"
          (input)="onChange(searchText)"
          #Search
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <button
          type="submit"
          class="refresh-button ml-3"
          (click)="onRefreshmobile()"
        >
          <img src="assets/svgimages/Refresh.svg" alt="add" class="h-18" />
          <span class="marginleft-10px Buttontextsize">{{ "productlist.mobile.refresh" | translate }}</span>
        </button>
      </div>
      <div class="col-6">
        <button type="submit" class="add-store-btn" (click)="onAddClick()">
          <img src="assets/svgimages/plus.svg" alt="add" class="h-18" />
          <span class="marginleft-10px Buttontextsize">{{ "productlist.button.addproduct" | translate }}</span>
        </button>
      
      </div>
    </div>
    <div class="mt-3">
      <ng-select
        *ngIf="isSuperAdmin"
        autofocus
        [items]="users"
        bindLabel="name"
        bindValue="id"
        placeholder="{{'productlist.selectusername.placeholder'| translate }}"
        [virtualScroll]="true"
        [(ngModel)]="selectedUser"
        (ngModelChange)="onUsenameDropdownChange($event, true)"
        [clearable]="false"
      
      >
      </ng-select>
    </div>
    <div class="mt-3">
      
      <ng-select
        [items]="StoreList"
        [virtualScroll]="true"
        autofocus
        bindLabel="name"
        bindValue="storeId"
        placeholder="{{'productlist.selectstore.placeholder'| translate }}"
        name="storeId"
        [(ngModel)]="selectedstore"
        (ngModelChange)="SelectMobileStore($event)"
        [clearable]="false"
      >
      </ng-select>
    </div>

    <div
      class="mt-3"
      style="text-align: center"
      *ngIf="
        ProductList === null ||
        ProductList === undefined ||
        ProductList.length === 0
      "
    >
    {{ "productlist.text" | translate }}
    </div>
 
    <div *ngFor="let obj of ProductList | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalItems }| productFilter: searchText">
      <div class="card store-card mt-3">
        <div class="card-body padding-rem-75">
          <div class="row">
            <div class="col-md-9 col-9">
              <div class="d-flex align-items-center">
               
                <ngx-avatar
                  size="40"
                  name="{{ obj.name.substring(0, 15) }}"
                ></ngx-avatar>
                <div class="ml-1 lh-18">
                  <span
                    class="text-14 text-blue-color font-weight-600 text-transform-capitalize ml-2"
                    >{{ obj.name }}</span
                  >
                 
                </div>
              </div>
            </div>
            <div class="col-md-3 col-3" style="text-align: right">
              <span class="text-12 font-weight-600">
                <div *ngIf="obj.active == true">
                  <span class="status text-success mr-1 ml-1">•</span>Active
                </div>
                <div *ngIf="obj.active == false || obj.active == null">
                  <span class="status text-warning">•</span>Inactive
                </div>
              </span>
            </div>
          </div>
          <div class="row text-blue-color align-items-end mt-2">
            
            <div class="col-md-7 col-7 lh-22">

              <span class="text-9 d-block font-weight-600"
                >{{ "productlist.mobile.sellingprice" | translate }}<span  class="text-blue-color text-13 font-weight-600">{{ obj.sellingPrice | currency: "INR" }}</span
                ></span
              >
              <span class="text-9 d-block font-weight-600 mt-2"
                >{{ "productlist.mobile.discount" | translate }}<span class="text-blue-color text-13 font-weight-600"> {{
                  obj.discount > 0 ? obj.discount + "%" : "No Discount"
                }}</span>
               </span
              >
            </div>
            <div class="col-md-5 col-5">
              <div class="d-flex" style="float: right;">
                <button
                  class="btn btn-rounded-hash mr-1"
                  (click)="onEditClick(obj.id)"
                  title="Edit"
                >
                  <img
                    src="assets/svgimages/editred.svg"
                    alt=""
                    class="edit-button"
                  />
                </button>
                <button
                  class="btn btn-rounded-hash mr-1"
                  (click)="onDeleteClick(obj.id)"
                  title="Delete"
                >
                  <img
                    src="assets/svgimages/delete.svg"
                    alt=""
                    class="edit-button"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="pagination-container">
     <pagination-controls (pageChange)="pageChanged($event)" [maxSize]="10" [directionLinks]="true"  previousLabel="{{ 'pagination.previous' | translate }}"
     nextLabel="{{ 'pagination.next' | translate }}"></pagination-controls></div>
    </div>
  </div>


