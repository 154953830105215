import { Userprofile } from '@/Model/userprofile';
import { AppState } from '@/store/state';
import { ToggleSidebarMenu } from '@/store/ui/actions';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { ProfileService } from '@services/profile.service';
import { ShareimageService } from '@services/shareimage.service';
import { Observable } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu = MENU;
  public menu2 = MENU2;
  public menu3 = MENU3;
  Name: string = '';
  id: any;
  imageSrc: any;
  profileobject: Userprofile = new Userprofile();
  profileImage = false;
  initialsletters: any;
  letters: any;
  isSuperAdmin: boolean = false;
  constructor(
    public appService: AppService,
    private store: Store<AppState>,
    private profileService: ProfileService,
    private shareimageService: ShareimageService,
  ) {
    this.shareimageService.SharingData.subscribe((res: any) => {
      if (res.isImageChange) {
        this.imageSrc = res.imagePath;
        this.profileImage = true;
      }
      this.Name = res.data.firstName;

    })
  }

  ngOnInit() {
    this.id = localStorage.getItem('userId')
    this.profileService.getUserById().subscribe((data: any) => {
      this.profileobject = data;
      this.isSuperAdmin = data.isSuperAdmin;
      if (this.isSuperAdmin === false) {
        this.menu = this.menu.filter((item) => item.name !== 'User');
      }
      if (this.profileobject.profileImagePath == null || this.profileobject.profileImagePath == "") {
        this.profileImage = false;
        this.Name = this.profileobject.firstName;
        this.initialsletters = this.getShortName(this.profileobject.firstName, this.profileobject.lastName);
        this.letters = this.initialsletters.split('').join(' ');

      }
      else {
        this.profileImage = true;
        this.Name = this.profileobject.firstName;
        this.imageSrc = `${this.profileobject.profileImagePath}`;
      }
    });

  }

  refresh() {
    this.store.dispatch(new ToggleSidebarMenu());
  }

  getShortName(firstname, lastname) {
    const initials = firstname.charAt(0) + lastname.charAt(0);
    return initials.toUpperCase();
  }
}

export const MENU = [
  {
    name: 'Dashboard',
    iconClasses: 'fas fa-tachometer-alt',
    iconImagePath: 'assets/svgimages/Dashboard.svg',
    path: ['/dashboard']
  },
  {
    name: 'Store',
    iconClasses: 'fas fa-store',
    iconImagePath: 'assets/svgimages/store.svg',
    path: ['/dashboard/store']
  },
  {
    name: 'Category',
    iconClasses: 'fa fa-list-alt',
    iconImagePath: 'assets/svgimages/Categories.svg',
    path: ['/dashboard/category']
  },
  {
    name: 'Product',
    iconClasses: 'fas fa-cart-plus',
    iconImagePath: 'assets/svgimages/Cart.svg',
    path: ['/dashboard/product']
  },
  {
    name: 'User',
    iconClasses: 'fa fa-user-circle',
    iconImagePath: 'assets/svgimages/profileblue.svg',
    path: ['/dashboard/users']
  },
  {
    name: 'Report',
    iconClasses: '	fa fa-file-excel-o',
    iconImagePath: 'assets/svgimages/report.svg',
    path: ['/dashboard/report'],
  }
];
export const MENU3 = [
  {
    name: 'User',
    iconClasses: 'fa fa-user-circle',
    iconImagePath: 'assets/svgimages/profileblue.svg',
    path: ['/dashboard/users']
  },
];

export const MENU2 = [
  {
    name: 'Change Password',
    iconClasses: 'fa fa-user-circle',
    iconImagePath: 'assets/svgimages/Password.svg',
    path: ['/dashboard/changepassword']
  },
  {
    name: 'Logout',
    iconClasses: 'fa fa-user-circle',
    iconImagePath: 'assets/svgimages/logout.svg',
    path: ['/dashboard/logout']
  },

];


