<div class="container siginpage-container">
  <div class="row">
    <div class="card signin-page size-H">
      <div class="MartsUp-header">
        <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
      </div>
      <div class="header-border"></div>
      <div class="card-body setverify-pass-padding">
        <div class="signin-header mb-1">
          <span>{{ "otpverification.title" | translate }}</span>
        </div>
        <div class="text-header mb-3">
          <span>{{ "otpverification.titledetail" | translate }}</span>
        </div>
        <ng-select
          [items]="languages"
          bindLabel="language"
          bindValue="id"
          (change)="useLanguage($event)"
          [(ngModel)]="selectedLanguage"
          [clearable]="false"
          [searchable]="false"
          placeholder="{{ 'login.selectlanguage.text' | translate }}"
        >
        </ng-select>
        <div>
          <form
            [formGroup]="OTPForm"
            (ngSubmit)="onSubmit()"
            class="sigin-form"
          >
            <div class="form-group mt-3">
              <img src="assets\svgimages\otp.svg" class="email-icon" />
              <label for="exampleInputEmail1 " class="email-text"
                >{{ "otpverification.otp.label" | translate
                }}<span class="required">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="OTP"
                maxlength="4"
                (input)="onInput($event)"
                pattern="[0-9]+"
                placeholder="{{
                  'otpverification.otp.placeholder' | translate
                }}"
              />
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && this.OTPForm.invalid"
              >
                {{ "validation.otp.required" | translate }}
              </div>
            </div>
            <div>
              {{ "otpverification.notreceiveotptext" | translate }}
              <a
                (click)="resendOTP()"
                class="createaccount-text"
                style="cursor: pointer"
              >
                {{ "otpverification.resendotp.link" | translate }}
              </a>
            </div>
            <div>
              <button
                type="submit"
                class="btn btn-secondary btn-lg btn-block signin-btn mt-4"
                [type]="'submit'"
              >
                <div class="submit-text">
                  {{ "otpverification.button.name" | translate
                  }}<i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </div>
              </button>
            </div>
            <div class="row">
              <div
                class="col-12 col-sm-12 mb-3 text-red text-12 font-weight-bold"
              >
                <div *ngIf="errorMessage === 'Enter 4 digit valid OTP'">
                  {{ "validation.otp.minlength" | translate }}
                </div>
                <div *ngIf="errorMessage === 'OTP sent successfully'">
                  {{ "validation.resendotp" | translate }}
                </div>
                <div *ngIf="errorMessage === 'Invalid OTP or expired'">
                  {{ "validation.otpexpired" | translate }}
                </div>
                <div *ngIf="errorMessage === 'Error occured'">
                  {{ "validation.erroroccured" | translate }}
                </div>
                <div *ngIf="errorMessage === 'something went wrong'">
                  {{ "validation.error" | translate }}
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 mt-2 new-user-text">
              {{ "otpverification.olduser" | translate }}
            </div>
            <div class="row text-center">
              <a [routerLink]="['']" class="createaccount-text">
                {{ "otpverification.signin.link" | translate }}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
