

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'storeFilter' })
export class StoreFilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string): any[] {

    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
   
    return items ? items.filter(item => item.name.search(new RegExp(searchText, 'i')) > -1) : [];

  }
}
