import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userfilter'
})
export class UserfilterPipe implements PipeTransform {


  transform(items: any[], searchText: string): any[] {

    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
   
    //searching from firstName  only
    var result= items ? items.filter(item => item.firstName.search(new RegExp(searchText, 'i')) > -1) : [];
    if(result.length==0 || result== undefined){
      result= items ? items.filter(item => item.lastName.search(new RegExp(searchText, 'i')) > -1) : [];
    }
    if(result.length==0 || result== undefined){
      result= items ? items.filter(item => item.mobileNumber.search(new RegExp(searchText, 'i')) > -1) : [];
    }
    if(result.length==0 || result== undefined){
      result= items ? items.filter(item => item.email.search(new RegExp(searchText, 'i')) > -1) : [];
    }
   
   return result;
  }
}
