import { ReportService } from './../services/report.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '@services/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareimageService } from '@services/shareimage.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
  isSuperAdmin: boolean = false;
  users: any[];
  userId: any;
  reportForm: FormGroup;
  reportResult: any;
  username: string;
  startDate: string;
  endDate: string;
  isFormSubmitted: boolean = false;
  reports: any;
  responseResult: any;
  userDetail: any;
  selectedUser: any;
  exportCSV: any;
  errorMessage: string;
  accordionimgpath: string = 'assets/svgimages/expand.svg';
  productsCount: any;
  formattedStartDate: string;
  formattedEndDate: string;
  todaydate: Date = new Date();
  name: string;
  usernameError:string;
  maxAllowedDate: Date;
  pageSize = 10; // Number of items per page
  currentPage = 1; // Current page number
  totalItems: number; // Total number of items
  languageName:any;
  constructor(
    private SpinnerService: NgxSpinnerService,
    private profileservice: ProfileService,
    private reportservice: ReportService,
    private shareimageService: ShareimageService,
    private toastr: ToastrService,   
    private translate:TranslateService
  ) {this.translate.setDefaultLang('en'); }

  ngOnInit() {
    this.SpinnerService.show();
    this.shareimageService.setPageName('Report');
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
      this.translate.use('en');
     }else{
      this.translate.use(this.languageName);
     }
   
    this.maxAllowedDate = new Date(); 
    this.reportForm = new FormGroup({
      name: new FormControl(''),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });

    this.profileservice.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
      this.userId = data.id;
      this.username = data.username;

      if (this.isSuperAdmin === true) {
        this.profileservice.GetUsersForDropdown().subscribe((data: any) => {
          this.users = data;
          this.SpinnerService.hide();
        });
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
        });
      } else {
        this.reportservice
          .getReportFor30Days(this.username)
          .subscribe((data: any) => {
            this.reportResult = data;
            this.SpinnerService.hide();
          });
      }
      this.reportForm.value.startDate = new Date(
        new Date().setDate(this.todaydate.getDate() - 30)
      );

      this.formattedEndDate = this.todaydate.toLocaleDateString('en-GB');
      this.formattedStartDate =this.reportForm.value.startDate.toLocaleDateString('en-GB');
        
      this.reportForm.controls['startDate'].setValue(this.formattedStartDate);
      this.reportForm.controls['endDate'].setValue(this.formattedEndDate);
    });
  }

  get f() {
    return this.reportForm.controls;
  }

  searchReport() {

    this.errorMessage = '';
    this.isFormSubmitted = true;
    if(this.isSuperAdmin && (this.name=== undefined || this.name === '')){
      this.SpinnerService.hide();
      return false;
     }
     if(!this.isSuperAdmin){
      this.name=this.username
     }
    if (this.reportForm.invalid) {
      this.SpinnerService.hide();
      return false;
    } else {
      this.SpinnerService.show();
      const [day, month, year] = this.reportForm.value.startDate.split('/');
      var d1 = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

      const [day1, month1, year1] = this.reportForm.value.endDate.split('/');
      var d2 = new Date(parseInt(year1), parseInt(month1) - 1, parseInt(day1));
      if (d1 <= d2) {
        this.reports = {
         username : this.name,
          startDate: this.reportForm.value.startDate,
          endDate: this.reportForm.value.endDate,
        };
        this.reportservice.getReport(this.reports).subscribe((data: any) => {
          this.reportResult = data;

          this.SpinnerService.hide();
        });
      } else {
        this.errorMessage = 'Start date should not be greater than end date';
        this.SpinnerService.hide();
      }
    }
  }

  exportCSVFile() {
    
    this.errorMessage = '';
    this.isFormSubmitted = true;
    this.SpinnerService.show();
    if(!this.isSuperAdmin){
      this.name=this.username
     }
    if (this.reportForm.invalid) {
      this.SpinnerService.hide();
      return false;
    } else {
      this.userDetail = {
        username: this.name,
        startDate: this.reportForm.value.startDate,
        endDate: this.reportForm.value.endDate,
      };
      this.reportservice.getReport(this.userDetail).subscribe((data: any) => {
        this.responseResult = data;
        if (this.responseResult.length == 0) {
          const translatedMessage = this.translate.instant('toastr.error.report');
          this.toastr.error(translatedMessage);
   
          this.SpinnerService.hide();
        } else {
          const [day, month, year] = this.reportForm.value.startDate.split('/');
          var d1 = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

          const [day1, month1, year1] =
            this.reportForm.value.endDate.split('/');
          var d2 = new Date(
            parseInt(year1),
            parseInt(month1) - 1,
            parseInt(day1)
          );
          if (d1 <= d2) {
            this.reports = {
              username: this.name,
              startDate: this.reportForm.value.startDate,
              endDate: this.reportForm.value.endDate,
            };
            this.reportservice
              .exportToCSV(this.reports)
              .subscribe((response) => {
                let fileName = 'ProductUsageReport.csv';
                let blob: Blob = response.body as Blob;
                let a = document.createElement('a');
                a.download = fileName;
                a.href = window.URL.createObjectURL(blob);
                a.click();
                this.SpinnerService.hide();
              });
          } else {
            this.errorMessage =
              'Start date should not be greater than end date';
            this.SpinnerService.hide();
          }
        }
      });
    }
  }
  onUsernameDropdownChange(event) {

    this.usernameError='';
    if (event === undefined){
      this.usernameError="Please select username";
      return;
    }else {
      this.name = event.name;
      this.usernameError='';
    }
  
  }

  manageAccordion(id) {
    var panel = document.getElementById(id);
    if (panel.style.display === 'block') {
      panel.style.display = 'none';
      this.accordionimgpath = 'assets/svgimages/collapse.svg';
    } else {
      panel.style.display = 'block';
      this.accordionimgpath = 'assets/svgimages/expand.svg';
    }
  }

  productscount(products: any) {
    var result = [];

    for (var i = 0; i < products.length; i++) {
      var name = products[i].productName;
      if (result.length === 0) {
        result.push(name);
      } else {
        var match = false;
        for (var j = 0; j < result.length; j++) {
          if (name === result[j]) {
            match = true;
          }
        }
        if (match === false) {
          result.push(name);
        }
      }
    }

    return result.length;
  }
  pageChanged(event: any) {
    this.currentPage = event;
}
}

