import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '@/AddCategory/category.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '@services/profile.service';
import { Table } from 'primeng/table';
import { ShareimageService } from '@services/shareimage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-categoryList',
  templateUrl: './categoryList.component.html',
  styleUrls: ['./categoryList.component.css'],
  providers: [ConfirmationService]
})

export class CategoryListComponent implements OnInit {
  data: any;
  errorMessage: boolean = false;
  searchValue: string = '';
  noRecordMsgFlag: boolean;
  users: any[];
  selectedUser: any;
  isSuperAdmin: boolean = false;
  userId: any;
  searchText = '';
  eventid: string;
  pageSize = 10; // Number of items per page
  currentPage = 1; // Current page number
  totalItems: number; // Total number of items
  languageName: any;
  @ViewChild('customSearch') customSearchElement: ElementRef;

  constructor(private categoryService: CategoryService,
    private toastr: ToastrService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private SpinnerService: NgxSpinnerService,
    private profileservice: ProfileService,
    private shareimageService: ShareimageService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {

    document.getElementById("tempid_Category")?.classList.add("highlight_sideMenu");
    this.shareimageService.setPageName("Categories");

    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    this.SpinnerService.show();
    this.profileservice.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
      this.userId = data.id;

      if (this.isSuperAdmin === true) {

        this.loadCategories();
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
        });
      }
      else {
        this.categoryService.getAllActiveCategory(this.languageName).subscribe((data: any) => {
          this.data = data;

          this.SpinnerService.hide();
        })
      }
    }, (err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);

    });
  }
  onChange(searchText) {
    this.errorMessage = false;
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].categoryName.includes(this.searchText)) {
        this.errorMessage = false;
        return;
      }
    }
    this.errorMessage = true;
    return;
  }
  loadCategories() {

    this.SpinnerService.show();
    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
      this.languageName = 'en'
    }
    this.categoryService.getAll(this.languageName).subscribe((data: any) => {
      this.SpinnerService.hide();
      if (data.length === 0) {
        this.data = null;

      } else {
        this.data = data;

      }
    }),
      (error: any) => {
        this.SpinnerService.hide();
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);

      };
  }

  isEmpty(event) {
    if (event.filteredValue.length == 0) {
      this.noRecordMsgFlag = true;
    }
    else {
      this.noRecordMsgFlag = false;
    }
  }

  onAddClick() {
    this.router.navigateByUrl("/dashboard/addCategory");
  }

  onRefreshmobile() {
    this.searchText = "";
    if (this.isSuperAdmin === false) {
      this.loadCategories();
      this.SpinnerService.hide();
    }
    else {
      this.SpinnerService.show();
      var storeid = this.eventid;
      this.categoryService.getAll(this.languageName).subscribe(data => {
        this.data = data;
        this.SpinnerService.hide();
      }, (err: any) => {
        this.SpinnerService.hide();
      })

    }

  }
  onRefresh(table: Table) {
    table.clear();
    this.customSearchElement.nativeElement.value = "";
    if (this.isSuperAdmin === false) {
      this.loadCategories();
    }
    else {
      this.SpinnerService.show();
      var storeid = this.eventid;
      this.categoryService.getAll(this.languageName).subscribe(data => {
        this.data = data;
        this.SpinnerService.hide();

      }, (err: any) => {

        this.SpinnerService.hide();
      })
    }
  }

  onDeleteClick(cat: any) {

    this.translate.get('deleteCategoryConfirmationDialog').subscribe((translations: any) => {
      this.confirmationService.confirm({
        message: translations.message,
        header: translations.title,
        icon: 'pi pi-info-circle',
        accept: () => {
          this.categoryService.delete(cat.id).subscribe((data: any) => {
            if (data.message==="Category deleted successfully") {
              const translatedMessage = this.translate.instant('toastr.success.deletecategory');
              this.toastr.success(translatedMessage);
              this.loadCategories();
            } else {
              const translatedMessage = this.translate.instant('toastr.error.category');
              this.toastr.error(translatedMessage);
            }
          }),
            (error: any) => {
              const translatedMessage = this.translate.instant('toastr.error');
              this.toastr.error(translatedMessage);
            };
        },
        reject: () => {

        },
        acceptLabel: translations.accept,
        rejectLabel: translations.reject
      });
    })

  }

  oneditClick(id: any) {
    this.router.navigateByUrl("dashboard/category/" + id + "/editCategory");
  }

  pageChanged(event: any) {
    this.currentPage = event;
  }
  ngOnDestroy(): void {
    document.getElementById("tempid_Category").classList.remove("highlight_sideMenu");
  }

}
