import { UserlistComponent } from './AddUser/userlist/userlist.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { PrivacyPolicyComponent } from '@modules/privacy-policy/privacy-policy.component';
import { StoreListComponent } from './AddStore/storeList/storeList.component';
import { AddStoreComponent } from './AddStore/addStore/addStore.component';
import { EditStoreComponent } from './AddStore/editStore/editStore.component';
import { ProductlistComponent } from './product/productlist/productlist.component';
import { AddproductComponent } from './product/addproduct/addproduct.component';
import { EditproductComponent } from './product/editproduct/editproduct.component';
import { AddCategoryComponent } from './AddCategory/addCategory/addCategory.component';
import { CategoryListComponent } from './AddCategory/categoryList/categoryList.component';
import { EditCategoryComponent } from './AddCategory/editCategory/editCategory.component';
import { VerifyOTPComponent } from '@modules/verifyOTP/verifyOTP.component';
import { LogoutComponent } from '@modules/logout/logout.component';
import { UserprofileComponent } from '@modules/userprofile/userprofile.component';
import { SetpasswordverifyOTPComponent } from '@modules/setpasswordverifyOTP/setpasswordverifyOTP.component';
import { SetpasswordComponent } from '@modules/setpassword/setpassword.component';
import { SecurityGuard } from '@guards/SecurityGuard.guard';
import { ChangePasswordComponent } from '@modules/change-password/change-password.component';
import { ReportComponent } from './report/report.component';
import { AddUserComponent } from './AddUser/add-user/add-user.component';
import { EditUserComponent } from './AddUser/edit-user/edit-user.component';
import { TermsConditionsComponent } from '@modules/terms-conditions/terms-conditions.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: MainComponent, canActivate: [SecurityGuard],
    children: [
      {
        path: '',
        component: DashboardComponent, canActivate: [SecurityGuard]
      },
      {
        path: 'store',
        component: StoreListComponent
      },
      {
        path: 'addStore',
        component: AddStoreComponent
      },
      {
        path: 'store/:postId/editStore',
        component: EditStoreComponent
      },
      { path: 'product', component: ProductlistComponent },
      { path: 'product/:id/addproduct', component: AddproductComponent },
      { path: 'product/:id/editproduct', component: EditproductComponent },
     
      {
        path: 'category',
        component: CategoryListComponent
      },
      {
        path: 'addCategory',
        component: AddCategoryComponent
      },
      {
        path: 'category/:postId/editCategory',
        component: EditCategoryComponent
      },
      {
        path: 'userprofile',
        component: UserprofileComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {path:'changepassword',
      component:ChangePasswordComponent
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'users',
        component: UserlistComponent
      
      },
      {
        path: 'adduser',
        component: AddUserComponent
      },
      {
        path: 'users/:userid/edituser',
        component: EditUserComponent
      },
      {
        path: '**', pathMatch: 'full',
        component: DashboardComponent
      },
   

    ]
  },
  {
    path: 'register',
    component: RegisterComponent
  
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent

  },
  {
    path: 'reset-password',
    component: RecoverPasswordComponent
  
  },
  {
    path: 'set-password',
    component: SetpasswordComponent
   
  },
  {
    path: 'verify-otp/:id',
    component: VerifyOTPComponent,
  },
  {
    path: 'verify-otp-password/:id',
    component: SetpasswordverifyOTPComponent,
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
