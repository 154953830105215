import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UsernameValidator {

  constructor(private http: HttpClient) {}

  checkUsername(username: FormControl) {

    return this.http.get<{ success: boolean }>(`${environment.userApiUrl}/User/CheckDuplicateByUsername/${username.value}`).pipe(
      map(res => {console.log('res',res.success);   

        return res.success ? { usernameTaken: true } : null;
    } )
    );
  }


}