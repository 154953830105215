import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  url: string = "";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private httpClient: HttpClient) {
    this.url = environment.catApiUrl;
  }
  getAll(language): Observable<any> {

    return this.httpClient.get<any>(`${this.url}/Category/GetAll/`+language)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  GetCategoryForDropdown(): Observable<any> {
      return this.httpClient.get<any>(`${this.url}/Category/GetCategoryForDropdown`)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  getAllCategoryByUserID(userId): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/Category/GetAll/` + userId)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getAllActiveCategory(language): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/Category/GetActiveCategoryByLanguage/`+ language)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  GetAllActiveCategoryByStoreId(storeId): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/Category/GetAllActiveCategory/` + storeId)
      .pipe(
        catchError(this.errorHandler)
      )
  }


  create(category: any): Observable<object> {
    return this.httpClient.post<any>(`${this.url}/Category/Save`, category)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  update(category: any): Observable<any> {
    return this.httpClient.put<any>(`${this.url}/Category/update`, category)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  delete(id: number) {
    return this.httpClient.delete<any>(`${this.url}/Category/Delete/${id}`, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  getCategoryById(id: number) {
    return this.httpClient.get<any>(`${this.url}/Category/GetCategory/${id}`)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  getCategoryCount() {
    return this.httpClient.get<any>(`${this.url}/Category/GetCount`)
      .pipe(
        catchError(this.errorHandler)
      )
  }
getCategoryCountByCategoryId(CategoryId:number){
  return this.httpClient.get<any>(`${this.url}/Category/GetCategoryCountByCategoryId/${CategoryId}`)
  .pipe(
    catchError(this.errorHandler)
  )
}

  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
