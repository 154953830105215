import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@/Model/store';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class StoreAPIService {
url:string="";
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) {

  }

  getAll(): Observable<Store[]> {
    return this.httpClient.get<Store[]>(`${environment.storeApiUrl}/Store/GetAll`)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  GetStoresByUserId(userId:any): Observable<Store[]> {
    return this.httpClient.get<Store[]>(`${environment.storeApiUrl}/Store/GetAllStore/`+userId)
      .pipe(
        catchError(this.errorHandler)
      )
  }



  create(store: any): Observable<object> {
    return this.httpClient.post<any>(`${environment.storeApiUrl}/Store/Save`, store)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  update(store: any): Observable<Store> {
    
    return this.httpClient.put<Store>(`${environment.storeApiUrl}/Store/update`,store)
      .pipe(
        catchError(this.errorHandler)
      )
  }

  delete(id: number) {
    return this.httpClient.delete<Store>(`${environment.storeApiUrl}/Store/delete/${id}`, this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  getStoreById(id: number) {
    return this.httpClient.get<Store>(`${environment.storeApiUrl}/Store/getStore/${id}`)
      .pipe(
        catchError(this.errorHandler)
      )
  }


  GetStoreCount()
  {
    return this.httpClient.get<Store>(`${environment.storeApiUrl}/Store/getcount`)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  publishStore(storeId: number) {
    return this.httpClient.get<Store>(`${environment.publishApiUrl}/publish/publish/${storeId}`)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  rejectStore(storeId: number, reason:string ) {
    return this.httpClient.post<any>(`${environment.publishApiUrl}/publish/RejectPublishRequest/${storeId},${reason}`,this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }
 
  sendPublishRequest(storeId: number) {
    return this.httpClient.post<any>(`${environment.publishApiUrl}/publish/SendPublishRequest/${storeId}`,this.httpOptions)
      .pipe(
        catchError(this.errorHandler)
      )
  }
  getStoreUrl(name:string): Observable<string> {
   
    return this.httpClient.get<any>(`${environment.storeApiUrl}/Store/GetStoreUrl/${name}`,this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
 
  }
  checkStoreUrl(storeurl: string) {

    return this.httpClient.get<any>(`${environment.storeApiUrl}/Store/CheckDuplicateByStoreUrl/${storeurl}`)
    .pipe(
      catchError(this.errorHandler)
    )
   
  }
  errorHandler(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
