import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoryService } from '@/AddCategory/category.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '@services/profile.service';
import { ShareimageService } from '@services/shareimage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addCategory',
  templateUrl: './addCategory.component.html',
  styleUrls: ['./addCategory.component.css']
})
export class AddCategoryComponent implements OnInit {
  @ViewChild('select') selectElement: ElementRef;
  isActive: boolean = true;
  categoryform!: FormGroup;
  loading = false;
  public isFormSubmitted: boolean = false;
  users: any[];
  selectedUser: any;
  isSuperAdmin: boolean;
  userId: any;
  selectedUserId: any;
  languageName: any;
  categoryname:any[];
  selectedCategoryId:any;
  constructor(public categoryService: CategoryService,
    private router: Router,
    private toastr: ToastrService,
    private profileservice: ProfileService,
    private shareimageService: ShareimageService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
    this.CreateFormControl();
  }

  ngOnInit(): void {

    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    document.getElementById("tempid_Category")?.classList.add("highlight_sideMenu");
    this.shareimageService.setPageName("Categories");
  
      if ( this.languageName!='en') {
        this.categoryform.addControl('name', new FormControl('', Validators.required));
        this.categoryService.GetCategoryForDropdown().subscribe((data:any)=>{
          this.categoryname=data;
      
        })
    }
    else{
      this.categoryform.removeControl("name");
    }

 
    this.profileservice.getNotification().subscribe((data: any) => {
      this.shareimageService.setnotification(data)
      localStorage.removeItem('notificationDataUserId');
      localStorage.removeItem('notificationDataUserName');
    });
  }

  private CreateFormControl() {
    this.categoryform = new FormGroup({
      userID: new FormControl(this.userId),
      id:new FormControl(''),
      categoryName: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      description: new FormControl('', [Validators.maxLength(50)]),
      active: new FormControl(true),
      language: new FormControl('')
    });
  }

  get f() {
    return this.categoryform.controls;
  }

  CreateCategory() {

    this.loading = true;
    this.isFormSubmitted = true;
    if (this.categoryform.status == 'INVALID') {
      this.loading = false;
      return
    }

    const addCategory = new FormData();
    if(this.selectedCategoryId!=undefined ||this.selectedCategoryId!=null){
      addCategory.append("id", this.selectedCategoryId);
    }

    addCategory.append("categoryName", this.categoryform.value.categoryName);
    addCategory.append("description", this.categoryform.value.description);
    addCategory.append("active", this.categoryform.value.active);
    addCategory.append("userID", '0');
    addCategory.append("language", this.languageName)
    this.categoryService.create(addCategory).subscribe((res: any) => {
      const translatedMessage = this.translate.instant('toastr.success.createcategory');
      this.toastr.success(translatedMessage);
      this.redirectToCategory();
    }, (error) => {
      this.loading = false;
      const translatedMessage = this.translate.instant('toastr.error.duplicatecategory');
      this.toastr.error(translatedMessage);
    });
  }

  onCancelClick() {
    this.redirectToCategory();
  }

  redirectToCategory() {
    this.router.navigateByUrl("dashboard/category");
  }

  onCategoryNameDropdownChange(event){

    if (event === undefined) return;
    if(event !=null){
    this.selectedCategoryId=event.id;
    }
  }
  ngOnDestroy(): void {
    document.getElementById("tempid_Category").classList.remove("highlight_sideMenu");
  }

}
