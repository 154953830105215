<div id="content">
  <div class="container-fluid main-content d-none d-md-block d-lg-block">
    <div class="row product-list-table mt-3">
      <p-table
        #dt
        [value]="data"
        (onFilter)="isEmpty($event)"
        [rows]="10"
        [paginator]="true"
        class="table-padding table-width"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        styleClass="p-datatable-customers category-comp-padd p-datatable-striped"
        [globalFilterFields]="['categoryName', 'description']"
        [rowHover]="true"
        dataKey="id"
        [showCurrentPageReport]="true"
        [scrollable]="true"
        scrollDirection="horizontal"
        scrollHeight="700px"
      >
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-6">
              <span class="p-input-icon-left" style="width: 100%">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  class="search_bar"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{
                    'categorylist.search.placeholder' | translate
                  }}"
                  #customSearch
                  [focusMe]="true"
                />
              </span>
            </div>
            <div class="col-3" *ngIf="isSuperAdmin === true"></div>

            <div
              class="col-3"
              style="text-align: right"
              [ngClass]="{ 'col-6': isSuperAdmin === false }"
            >
              <button
                *ngIf="isSuperAdmin"
                class="btn btn-lg btn-secondary btn_space"
                type="submit"
                (click)="onAddClick()"
                style="margin-right: 10px"
              >
                <img src="assets/svgimages/plus.svg" class="h-14" alt="" />
                {{ "categorylist.button.addcategory" | translate }}
              </button>
              <button
                class="btn btn-primary btn_space"
                type="submit"
                (click)="onRefresh(dt)"
                title="Refresh"
              >
                <img src="assets/svgimages/Refresh.svg" class="h-14" alt="" />
              </button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" class="store-list-border">
          <br />
          <tr>
            <th pSortableColumn="categoryName">
              {{ "categorylist.categoryname" | translate
              }}<p-sortIcon field="categoryName"></p-sortIcon>
            </th>
            <th pSortableColumn="description">
              {{ "categorylist.description" | translate
              }}<p-sortIcon field="description"></p-sortIcon>
            </th>
            <th pSortableColumn="active">
              {{ "categorylist.status" | translate
              }}<p-sortIcon field="active"></p-sortIcon>
            </th>

            <th *ngIf="isSuperAdmin" pSortableColumn="inventoryStatus">
              {{ "categorylist.action" | translate }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr class="p-table">
            <td class="text-transform-capitalize tablename">
              {{ data.categoryName }}
            </td>
            <td class="email-id">{{ data.description }}</td>
            <td>
              <div *ngIf="data.active == true">
                <span class="status text-success">•</span
                >{{ "categorylist.active" | translate }}
              </div>
              <div *ngIf="data.active == false || data.active == null">
                <span class="status text-warning">•</span
                >{{ "categorylist.inactive" | translate }}
              </div>
            </td>
            <td *ngIf="isSuperAdmin" class="store-list-icons">
              <button
                class="btn btn-rounded-hash mr-1"
                (click)="oneditClick(data.id)"
                *ngIf="data.userID == userId || isSuperAdmin"
                title="Edit"
              >
                <img
                  src="assets/svgimages/editred.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
              <button
                class="btn btn-rounded-hash mr-1"
                (click)="onDeleteClick(data)"
                *ngIf="data.userID == userId || isSuperAdmin"
                title="Delete"
              >
                <img
                  src="assets/svgimages/delete.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6" style="text-align: left">
              {{ "categorylist.mobile.text" | translate }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-confirmDialog
    class="store-list-popup"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <ngx-spinner
    bdColor="rgba(202,202,202,0.8)"
    size="medium"
    color="#e72744"
    type="timer"
  >
    <p style="font-size: 20px; color: #e72744">Please wait...</p>
  </ngx-spinner>

  <!-- Mobile view HTML -->

  <div class="d-block d-md-none d-lg-none container-fluid">
    <div class="mt-3">
      <div class="has-search position-relative">
        <i class="fa fa-search form-control-feedback"></i>
        <input
          type="text"
          class="form-control mobSearch"
          placeholder="{{ 'categorylist.search.placeholder' | translate }}"
          [(ngModel)]="searchText"
          autocomplete="off"
          (input)="onChange(searchText)"
        />
      </div>

      <div class="row mt-3" *ngIf="isSuperAdmin">
        <div class="col-6">
          <button
            type="submit"
            class="refresh-button"
            (click)="onRefreshmobile()"
          >
            <img
              src="assets/svgimages/Refresh.svg"
              alt="Refresh"
              class="h-18"
            /><span class="marginleft-10px Buttontextsize">{{
              "categorylist.mobile.refresh" | translate
            }}</span>
          </button>
        </div>
        <div class="col-6">
          <button
            type="submit"
            *ngIf="isSuperAdmin"
            class="add-store-btn"
            (click)="onAddClick()"
          >
            <img src="assets/svgimages/plus.svg" alt="add" class="h-18" />
            <span class="marginleft-10px Buttontextsize">{{
              "categorylist.button.addcategory" | translate
            }}</span>
          </button>
        </div>
      </div>

      <div
        class="mt-3"
        style="text-align: center"
        *ngIf="
          selectedUser != null ||
          data === null ||
          data === undefined ||
          data.length === 0
        "
      >
        {{ "categorylist.mobile.text" | translate }}
      </div>
      <div
        *ngFor="
          let obj of data
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: currentPage,
                  totalItems: totalItems
                }
            | categoryFilter: searchText
        "
      >
        <div class="card store-card mt-3">
          <div class="card-body padding-rem-75">
            <div class="row">
              <div class="col-md-7 col-7">
                <div class="d-flex align-items-center">
                  <ngx-avatar
                    size="40"
                    name="{{ obj.categoryName.substring(0, 15) }}"
                  ></ngx-avatar>
                  <div class="ml-1 lh-18">
                    <span
                      class="text-14 text-blue-color font-weight-600 text-transform-capitalize ml-2"
                      >{{ obj.categoryName }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-5" style="text-align: right">
                <span class="text-12 font-weight-600">
                  <div *ngIf="obj.active == true">
                    <span class="status text-success mr-1 ml-1">•</span
                    >{{ "categorylist.active" | translate }}
                  </div>
                  <div *ngIf="obj.active == false || obj.active == null">
                    <span class="status text-warning">•</span
                    >{{ "categorylist.active" | translate }}
                  </div>
                </span>
              </div>
            </div>
            <div class="row text-blue-color align-items-end mt-2">
              <div class="col-md-12 col-12 lh-22" *ngIf="obj.description">
                <span class="text-9 font-weight-600"
                  >{{ "categorylist.mobile.description" | translate }}
                  <span class="text-blue-color text-13 font-weight-600">{{
                    obj.description
                  }}</span></span
                >
              </div>

              <div class="col-md-12 col-12 lh-22">
                <div class="d-flex" style="float: right">
                  <button
                    class="btn btn-rounded-hash mr-1"
                    (click)="oneditClick(obj.id)"
                    *ngIf="obj.userID == userId || isSuperAdmin"
                    title="Edit"
                  >
                    <img
                      src="assets/svgimages/editred.svg "
                      alt=""
                      class="edit-button"
                    />
                  </button>
                  <button
                    class="btn btn-rounded-hash mr-1"
                    (click)="onDeleteClick(obj)"
                    *ngIf="obj.userID == userId || isSuperAdmin"
                    title="Delete"
                  >
                    <img
                      src="assets/svgimages/delete.svg"
                      alt=""
                      class="edit-button"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="errorMessage == true">
        {{ "categorylist.mobile.text" | translate }}
      </div>
      <div class="pagination-container">
      <pagination-controls
        (pageChange)="pageChanged($event)"
        [maxSize]="10"
        [directionLinks]="true"
        previousLabel="{{ 'pagination.previous' | translate }}"
        nextLabel="{{ 'pagination.next' | translate }}"
      ></pagination-controls></div>
    </div>
  </div>
</div>
