import { Router } from '@angular/router';
import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ForgotpasswordService } from '@services/forgotpassword.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';

    public forgotPasswordForm: FormGroup;
    public loading = false;
    submitted = false;
    public isFormSubmitted: boolean = false;
    errorMessage: string = '';
    languageName: string = '';
    constructor(
        private renderer: Renderer2,
        private router: Router,
        private forgotpasswordservice: ForgotpasswordService,
        private translate: TranslateService
    ) { this.translate.setDefaultLang('en'); }

    ngOnInit(): void {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.languageName = localStorage.getItem('language');
        if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
          localStorage.setItem('language', 'en');
          this.translate.use('en');
         }else{
          this.translate.use(this.languageName);
         }
        this.forgotPasswordForm = new FormGroup({
            username: new FormControl('', [(Validators.required)]),
        });
    }

    get f() {
        return this.forgotPasswordForm.controls;
    }

    forgotPassword() {
    
        this.isFormSubmitted = true;
        this.loading = true;
        this.submitted = true;
        if (this.forgotPasswordForm.invalid) {
            this.loading = false;
        } else {
            const pattern = /^[+\d]+$/;
            if(pattern.test(this.forgotPasswordForm.value.username)){
                const countryCodeIndex = this.forgotPasswordForm.value.username.indexOf('+');
                const spaceIndex = this.forgotPasswordForm.value.username.indexOf(' ');
                this.forgotPasswordForm.value.username = this.forgotPasswordForm.value.username.substring(spaceIndex + 1);
            }
            this.forgotpasswordservice
                .forgotpassword(this.forgotPasswordForm.value.username)
                .subscribe(
                    (data: any) => {
                        data = JSON.parse(data);
                        if (data.success === false) {
                            this.errorMessage = 'Please check entered credentials';
                            this.loading = false;
                        } else {
                            this.router.navigateByUrl('/verify-otp/' + data.userId);
                            this.loading = false;
                        }
                    },
                    (error: any) => {
                        this.errorMessage = 'Please check with administrator';
                        this.loading = false;
                    }
                );
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
