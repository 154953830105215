<app-header></app-header>

<app-menu-sidebar
  class="sidebar-primary sidebar-color height-100vh sidebar_icon"
  style="min-height: 100vh; background-color: #f8f9fa; position: fixed"
></app-menu-sidebar>

<div class="content-wrapper bg-white">
  <router-outlet></router-outlet>
</div>

<app-control-sidebar></app-control-sidebar>
<div id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>
