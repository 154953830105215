import { LoginModel } from '@/Model/login';
import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding,
    ElementRef,
    ViewChild,
} from '@angular/core';

import { Router } from '@angular/router';
import { LoginService } from '@services/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    @ViewChild('select') selectElement: ElementRef;

    public loginForm: FormGroup;
    submitted = false;
    public isFormSubmitted: boolean = false;
    loading: boolean = false;
    show_button: Boolean = false;
    show_eye: Boolean = false;
    loginmodel: LoginModel = new LoginModel();
    errorMessage: string = '';
    languageName: string;
    selectedLanguage:string='en';
    languages = [
        { id: 'en', language: 'English' },
        { id: 'mr', language: 'मराठी' },
        { id: 'hi', language: 'हिंदी' },
    ];

    constructor(
        private renderer: Renderer2,
        private router: Router,
        private loginService: LoginService,
        private translate: TranslateService
    ) {
        this.translate.setDefaultLang('en');
    }

    ngOnInit() {
    
       this.languageName = localStorage.getItem('language');
       if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
        localStorage.setItem('language', 'en');
        this.selectedLanguage='en';
        this.translate.use( this.selectedLanguage);
       }
       else{
        this.selectedLanguage= this.languageName;
        this.translate.use( this.selectedLanguage);
       }

   
        this.renderer.addClass(document.querySelector('app-root'), 'login-page');
        this.loginForm = new FormGroup({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });
        this.loginForm.controls['password'].valueChanges.subscribe(() => {
            // Clear all error messages
            this.errorMessage = '';
        });
        this.loginForm.controls['username'].valueChanges.subscribe(() => {
            // Clear all error messages
            this.errorMessage = '';
        });
    }
    useLanguage(event: any) {
   
        localStorage.setItem('language', event.id);
        this.translate.use(event.id);
    }
    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
   
        this.languageName  = localStorage.getItem('language');
        localStorage.clear();
        this.isFormSubmitted = true;
        this.loading = true;
        if (this.loginForm.valid) {
            this.loading = true;
            this.loginmodel = this.loginForm.value;
            const pattern = /^[+\d]+$/;
            if(pattern.test(this.loginmodel.username)){
                const countryCodeIndex = this.loginmodel.username.indexOf('+');
                const spaceIndex = this.loginmodel.username.indexOf(' ');
                this.loginmodel.username = this.loginmodel.username.substring(
                    spaceIndex + 1
                );
            }
          
            this.loginService.login(this.loginmodel).subscribe(
                (data: any) => {
                    if (data.isAuthenticated) {
                        if(this.languageName===null || this.languageName==='' || this.languageName===undefined){
                            localStorage.setItem('language', 'en');
                        }else{
                            localStorage.setItem('language', this.languageName );
                        }
                   
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('userId', data.userId);
                        localStorage.setItem('mobileNumber', data.mobileNumber);
                        localStorage.setItem('userName', data.userName);
                        localStorage.setItem(
                            'displayname',
                            data.firstName + ' ' + data.lastName
                        );
                        localStorage.setItem('pagename', 'Dashboard');
                        this.router.navigateByUrl('/dashboard');
                    } else {
                        this.errorMessage = 'Username or password is invalid';
                    }
                    this.loading = false;
                },
                (error: any) => {
                    
                    if (error.status === 401) {
                        this.errorMessage =
                            'Your account is inactive. A confirmation will be sent to you once it has been activated.';
                    } else if (error.status === 404) {
                        this.errorMessage = 'Username or password is invalid';
                    } else {
                        this.errorMessage = 'Username or password is invalid';
                    }
                    this.loading = false;
                }
            );
        } else {
            this.loading = false;
        }
    }

    showPassword() {
        this.show_button = !this.show_button;
        this.show_eye = !this.show_eye;
    }
    ngOnDestroy() {
        this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
    }
}
