import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareimageService {
  SharingData = new Subject();
  profilePageData= new Subject();
  notificationData = new Subject(); 
  userData = new Subject();
constructor() { }

  setImageUrl(url){
  this.SharingData.next(url);
  }
  getImageUrl():Observable<any>{
    return this.SharingData.asObservable();
  }

  setPageName(pagename){
    this.profilePageData.next(pagename);
  }
    getPageName():Observable<any>{
      return this.profilePageData.asObservable();
  }

  setnotification(data){
    this.notificationData.next(data);
  }
  getnotification():Observable<any>{
      return this.notificationData.asObservable();
  }

  setUserDropdown(data){
    this.userData.next(data);
  }
  getUserDropdown():Observable<any>{
      return this.userData.asObservable();
  }


}
