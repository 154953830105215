<div class="container siginpage-container">
  <div class="row">
    <div class="card signin-page">
      <div class="MartsUp-header">
        <img src="assets\svgimages\logoMartsup.svg" class="MU-logo" />
      </div>
      <div class="header-border"></div>
      <div class="card-body pt-2">
        <div class="signin-header mb-1">
          <span class="signin">{{ "register.title" | translate }}</span>
        </div>
        <div>
          <form
            [formGroup]="registerForm"
            (ngSubmit)="onSubmit()"
            class="sigin-form"
          >
            <div class="form-group form-validation">
              <img src="assets\svgimages\profile.svg" class="email-icon" />
              <label for="exampleInputname" class="email-text"
                >{{ "register.firstname.label" | translate
                }}<span class="required">*</span></label
              >
              <input
                formControlName="firstName"
                type="text"
                class="form-control"
                placeholder="{{ 'register.firstname.placeholder' | translate }}"
                maxlength="15"
                [focusMe]="true"
              />
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="f.firstName.errors?.pattern"
              >
                {{ "validation.firstname.pattern" | translate }}
              </div>

              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.firstName.errors?.required"
              >
                {{ "validation.firstname.required" | translate }}
              </div>
            </div>
            <div class="form-group form-validation">
              <img src="assets\svgimages\profile.svg" class="email-icon" />
              <label for="exampleInputname" class="email-text"
                >{{ "register.lastname.label" | translate
                }}<span class="required">*</span></label
              >
              <input
                formControlName="lastName"
                type="text"
                class="form-control"
                placeholder="{{ 'register.lastname.placeholder' | translate }}"
                maxlength="15"
              />
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="f.lastName.errors?.pattern"
              >
                {{ "validation.lastname.pattern" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.lastName.errors?.required"
              >
                {{ "validation.lastname.required" | translate }}
              </div>
            </div>
            <div class="form-group form-validation">
              <img src="assets\svgimages\mobile.svg" class="email-icon" />
              <label for="exampleInputname" class="email-text"
                >{{ "register.mobilenumber.label" | translate
                }}<span class="required">*</span></label
              >
              <input
                type="tel"
                id="phone"
                name="phone"
                formControlName="mobileNumber"
                class="form-control intl-tel-input"
                placeholder="{{
                  'register.mobilenumber.placeholder' | translate
                }}"
                (input)="onInput($event)"
                #phoneInput
                (keypress)="onInputKeyPress($event)"
              />
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted && f.mobileNumber.errors?.required"
              >
                {{ "validation.mobilenumber.required" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="f.mobileNumber.errors?.pattern"
              >
                {{ "validation.mobilenumber.pattern" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="isFormSubmitted"
              >
                <div *ngIf="validationerror === 'Invalid mobile number'">
                  {{ "validation.mobilenumber.invalid" | translate }}
                </div>
              </div>
              <div class="text-red text-12 font-weight-bold">
                <div
                  *ngIf="
                    duplicateMobileNumber === 'Mobile number already exists'
                  "
                >
                  {{ "validation.mobilenumber.duplicate" | translate }}
                </div>
              </div>
              <div class="text-red text-12 font-weight-bold">
                <div *ngIf="validationerror === 'Invalid number'">
                  {{ "validation.mobilenumber.invalid" | translate }}
                </div>
              </div>
            </div>
            <div class="form-group form-validation">
              <img src="assets\svgimages\Email.svg" class="email-icon" />
              <label for="exampleInputname" class="email-text">{{
                "register.email.label" | translate
              }}</label>
              <input
                type="email"
                class="form-control"
                formControlName="email"
                placeholder="{{ 'register.email.placeholder' | translate }}"
                maxlength="50"
              />
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="
                  isFormSubmitted &&
                  (f.email.errors?.email || f.email.errors?.pattern)
                "
              >
                {{ "validation.email.pattern" | translate }}
              </div>
              <div
                class="text-red text-12 font-weight-bold"
                *ngIf="registerForm.get('email').errors?.emailTaken"
              >
                {{ "validation.email.duplicate" | translate }}
              </div>
            </div>
            <div class="row text-1 mt-2">
              <div class="col-12 col-sm-12 mb-2 text-14 text-weight text-align">
                <input
                  type="checkbox"
                  id="flexCheckChecked"
                  formControlName="acceptTerm"
                  class="form-check-input"
                />
                {{ "register.termsandcondition" | translate }}
                <button
                  type="button"
                  class="terms-conditions-text"
                  data-toggle="modal"
                  data-target="#exampleModalLong"
                >
                  <b> {{ "register.termsandcondition.link" | translate }} </b>
                </button>
                <app-terms-conditions></app-terms-conditions>
                {{ "register.and" | translate }}
                <button
                  type="button"
                  class="terms-conditions-text"
                  data-toggle="modal"
                  data-target="#exampleModalLong1"
                >
                  <b>{{ "register.privacypolicy.link" | translate }} </b>
                </button>
                <app-privacy-policy></app-privacy-policy>
              </div>
            </div>
            <div
              class="text-red text-12 font-weight-bold"
              *ngIf="isFormSubmitted && !this.registerForm.value.acceptTerm"
            >
              {{ "validation.register.checkbox" | translate }}
            </div>
            <div>
              <button
                type="submit"
                class="btn btn-secondary btn-lg btn-block signin-btn mt-3"
              >
                <div>
                  {{ "register.button.name" | translate }}
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                </div>
              </button>
            </div>
            <div
              class="text-red text-12 font-weight-bold"
              *ngIf="isFormSubmitted"
            >
              <div *ngIf="loginError === 'Mobile number already exists'">
                {{ "validation.mobilenumber.duplicate" | translate }}
              </div>
              <div *ngIf="loginError === 'Email already exists'">
                {{ "validation.email.duplicate" | translate }}
              </div>
              <div *ngIf="loginError === 'Something went wrong'">
                {{ "validation.error" | translate }}
              </div>
            </div>
            <div class="row text-1 mt-2">
              <div
                class="col-12 col-sm-12 text-14 text-weight text-1 text-center"
              >
                <span> {{ "register.olduser" | translate }} </span>
                <a [routerLink]="['']" class="createaccount-text">
                  {{ "register.signin.link" | translate }}</a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
