import { ProductImage } from "../model/productImage";
import { ProductVideo } from "../model/productVideo";

export class ProductModel {
    id : number=0;
    storeId : number=0;
    categoryId : number = 0;
    name : string = '';
    description : string='';
    features : string='';
    costPrice : number=0;
    sellingPrice : number;
    showSellingPrice : boolean=true;
    discount : number;
    discountPrice : number;
    itemsLeft : number;
    coverImageId : string;
    coverImageFileName :string;
    canDisplayInTopRow : boolean=true;
    primaryColor : string='';
    brandName : string='';
    rating : number;
    isAvailable : boolean=true;
    isHomeDeliveryAvailable : boolean=true;
    favouriteNote : string='';
    active : boolean=true;
    createdOn : Date;
    createdBy : string='';
    updatedDate : Date;
    updatedBy : string='';
    productImagefiles : FormData[] = [];
    productVideofiles : FormData[] = [];
    imagesToBeDeleted:any[] = [];
    videosToBeDeleted:any[] = [];
    productImages : ProductImage[];
    productVideos : ProductVideo[];
}
