import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from '@/Model/product';
import { Store } from '@/Model/store';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '@/AddCategory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '@services/profile.service';
import { HelperService } from '@/utils/Helper';
import { ShareimageService } from '@services/shareimage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css'],
})
export class AddproductComponent implements OnInit {
 
  @ViewChild('inputVideoFile') inputVideoFile: ElementRef;

  ProductToSave: ProductModel = new ProductModel();
  StoreToSave: Store = new Store();
  productform!: FormGroup;
  categoryform: FormGroup;
  sourcesFiles: any[] = [];
  video: any[] = [];
  imageDeleteFrom: any;
  deletedImages: any[] = [];
  sourcesFile: any[] = [];
  id!: number;
  event: any;
  without_html: string;
  tabpcSuccess: boolean;
  tabpdSuccess:boolean;
  tabuiSuccess: boolean;
  tabuvSuccess: boolean
  addedImage: boolean = false;
  public isFormSubmitted: boolean = false;
  public ispdFormSubmitted: boolean = false;
  public ispcFormSubmitted: boolean = false;
  public isuiFormSubmitted: boolean = false;
  public isuvFormSubmitted: boolean = false;
  public isCategoryFormsubmitted: boolean = false;
  display: boolean = false;
  videos: any[] = [];
  deletedVideos: any[] = [];
  videoDeleteFrom: any;
  selectedCategory: any;
  productCategories: any[];
  isSuperAdmin: boolean;
  userId: any;
  step: any = 1;
  selectdTab: any;
  currentTab: any;
  users: any[];
  selectedUserId: any;
  public step1: boolean = false;
  public step2: boolean = false;
  lengthError: boolean = false;
  public isCoverImageFileExist: boolean = false;
  maxlengthError: string;
  languageName:any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '11rem',
    minHeight: '5rem',
    maxHeight: '15rem',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
   
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',

    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };
  constructor(
    public productService: ProductService,
    private categoryService: CategoryService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private profileservice: ProfileService,
    private helper: HelperService,
    private shareimageService: ShareimageService,
    private translate: TranslateService
  ) {this.translate.setDefaultLang('en');
    this.InitializeProductForm();
    this.InitializeCategoryForm();
  }

  ngOnInit(): void {
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
     }else{
      this.translate.use(this.languageName);
     }
    this.currentTab ='pd';
    document
      .getElementById('tempid_Product')
      ?.classList.add('highlight_sideMenu');
    document.getElementById('defaultOpen').click();
    this.shareimageService.setPageName('Products');
  
    this.ProductToSave.storeId = this.route.snapshot.params['id'];
   
    this.profileservice.getUserById().subscribe((data: any) => {
      this.isSuperAdmin = data.isSuperAdmin;
      this.userId = data.id;
     
      if (this.isSuperAdmin) {
        this.loadCategories();
       // this.loadCategoriesByStoreID(this.ProductToSave.storeId);
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
              localStorage.removeItem('notificationDataUserName');
        });
      } else {
        this.loadCategories();
      }
    },(err: any) => {
      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);
    
  });

    this.GetStoreBYStoreId(this.ProductToSave.storeId);
  }

  private InitializeProductForm() {
    this.productform = new FormGroup({
      productDetail: new FormGroup({
        name: new FormControl('', [
          Validators.required,
          Validators.maxLength(20),
        ]),
        description: new FormControl('', [
          Validators.required,
          this.editor()
        ]),
        sellingPrice: new FormControl('', [Validators.required]),
        showSellingPrice: new FormControl(true),
        discount: new FormControl('', [
          Validators.pattern('^[1-9][0-9]?$|^100$'),
          Validators.min(0),
        ]),
        brandName: new FormControl('', [
          Validators.maxLength(15),
        ]),
        active: new FormControl(true),
      }),
      productCategory: new FormGroup({
        categoryId: new FormControl(''),
        selectedCategory: new FormControl('', Validators.required),
      }),
      productImage: new FormGroup({
        coverImageFileName: new FormControl(''),
      }),
      productVideo: new FormGroup({
        productVideofiles: new FormControl(''),
      }),
    });
  }
  private InitializeCategoryForm() {
    this.categoryform = new FormGroup({
      userID: new FormControl(this.userId),
      categoryName: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
      ]),
      description: new FormControl(''),
      active: new FormControl(true),
    });
  }
  get categoryValue() {
    return this.categoryform.controls;
  }
  showDialog() {
    // this.isCategoryFormsubmitted = false;
    // this.InitializeCategoryForm();
    // this.display = true;
    this.router.navigateByUrl("/dashboard/addCategory");

  }


  AddCategory() {
    this.isCategoryFormsubmitted = true;
    this.SpinnerService.show();
    if (this.categoryform.invalid) {
      this.SpinnerService.hide();
      return;
    } else {
      const addCategory = new FormData();
      addCategory.append('categoryName', this.categoryform.value.categoryName);
      addCategory.append('description', this.categoryform.value.description);
      addCategory.append('active', this.categoryform.value.active);
      addCategory.append('language',this.languageName)
      if (this.isSuperAdmin == true) {
        this.selectedUserId = localStorage.getItem('selectedId');
        addCategory.append('userID', this.selectedUserId);
        this.SpinnerService.hide();
      } else {
        addCategory.append('userID', '0');
        this.SpinnerService.hide();
      }

      this.categoryService.create(addCategory).subscribe(
        (res: any) => {
          if (this.isSuperAdmin == true) {
            this.loadCategoriesByStoreID(this.ProductToSave.storeId);
          } else {
            this.loadCategories();
          }
          const translatedMessage = this.translate.instant('toastr.success.createcategory');
          this.toastr.success(translatedMessage);
          this.isCategoryFormsubmitted = false;
          this.display = false;
          this.SpinnerService.hide();
        },
        (error: any) => {
          const translatedMessage = this.translate.instant('toastr.error.duplicatecategory');
          this.toastr.error(translatedMessage);
          this.SpinnerService.hide();
        }
      ),
        (error: any) => {
          this.SpinnerService.hide();
          const translatedMessage = this.translate.instant('toastr.error');
          this.toastr.error(translatedMessage);
    
        };
    }
  }

  loadCategories() {
    this.SpinnerService.show();
    this.categoryService.getAllActiveCategory( this.languageName).subscribe((data: any) => {
      this.productCategories = data;
      this.SpinnerService.hide();
    }),
      (error: any) => {
   
        this.SpinnerService.hide();
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
       
      };
  }

  loadCategoriesByStoreID(storeId) {

    this.SpinnerService.show();
    this.categoryService
      .GetAllActiveCategoryByStoreId(storeId)
      .subscribe((data: any) => {
        this.productCategories = data;
        this.SpinnerService.hide();
      }),
      (error: any) => {
        this.SpinnerService.hide();
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
    
      };
  }

  ngOnDestroy(): void {
    document
      .getElementById('tempid_Product')
      .classList.remove('highlight_sideMenu');
  }

  Back(): void { }

  GetStoreBYStoreId(storeId: number) {
    this.productService
      .GetStoreByStoreId(this.ProductToSave.storeId)
      .subscribe((data: any) => {
        this.StoreToSave = data;
      });
  }

  validateSteps() {
  
    this.isFormSubmitted = true;
    
 
    if (this.productform.controls.productDetail.invalid && this.step == 1) {
      return;
    }
  
    if (this.isFormSubmitted && this.step == 1) {
      this.step1 = true;
    }
    if (this.isFormSubmitted && this.step == 2) {
      this.step1 = false;
      this.step2 = true;
    }
    if (this.isFormSubmitted && this.step == 3) {
      this.step2 = false;
    }
    if (this.productform.controls.productCategory.invalid && this.step == 2) {
      return;
    }
    
    if (this.sourcesFile.length === 0 && this.step === 3) return;

    var isvalid = this.validateCoverImageFile();
    if (isvalid == false) return;
    this.step = this.step < 4 ? this.step + 1 : this.step;
  }

  validateCoverImageFile() {
    if (this.sourcesFile.length > 0 && this.step === 3) {
      this.isCoverImageFileExist = false;
      for (let i = 0; i < this.sourcesFiles.length; i++) {
        if (
          this.productform.value.productImage.coverImageFileName ===
          this.sourcesFiles[i].name
        ) {
          this.isCoverImageFileExist = true;
          break;
        }
      }
      if (!this.isCoverImageFileExist) {

        this.isCoverImageFileExist = false;
        this.SpinnerService.hide();
        return false;
      }
      return true;
    }
  }
  onCancelClick() {
    this.redirectToProduct();
  }
  redirectToProduct() {
    var sid = this.ProductToSave.storeId;
    var uid = this.userId;
    this.router.navigate(['dashboard/product'], { queryParams: { sid, uid } });
  }

  onCategoryClick() {
    this.router.navigateByUrl('dashboard/category');
  }

  CreateProduct(isStepCall) {
    this.isFormSubmitted = true;
    if (isStepCall) {
      this.validateSteps();
      if (this.step != 4) return;
    } else {
      let without_html = this.productform.value.productDetail.description.replace(/<[^>]*>/g, '')
      without_html = without_html.replace('&#160;','')
      if(without_html.length>500){
      this.maxlengthError="Product description maxlength is 500";
       }
       if(without_html.length<500 || without_html.length===500)
      {
        this.maxlengthError="";
      }
      if(this.maxlengthError!=""){
        this.productform.controls.productdetail.status == 'INVALID'
      if (this.productform.controls.productDetail.invalid) {
        if (this.selectdTab !== 'pd') {
          const translatedMessage = this.translate.instant('toastr.error.productdetailrequired');
          this.toastr.error(translatedMessage);
         
        }

        return;
      }
    }
      if (this.productform.controls.productCategory.invalid) {
       
        if (this.selectdTab !== 'pc') {
          if(this.maxlengthError===""){
            const translatedMessage = this.translate.instant('toastr.error.productcategoryrequired');
            this.toastr.error(translatedMessage);
       
        }
        }
        return;
      }
      if (this.sourcesFiles.length === 0) {
        if (this.selectdTab !== 'ui') {
          const translatedMessage = this.translate.instant('toastr.error.productimagerequired');
          this.toastr.error(translatedMessage);
          
        }
        return;
      }
      this.step = 3;
      var isValid = this.validateCoverImageFile();

      if (isValid == false) {
        if (this.selectdTab !== 'ui') {
          const translatedMessage = this.translate.instant('toastr.error.coverimagerequired');
          this.toastr.error(translatedMessage);
       
        }

        return false;
      }

      if (this.productform.status == 'INVALID') {
        this.SpinnerService.hide();

        return false;
      }
    }

    this.SpinnerService.show();

    const product = new FormData();
    product.append('StoreId', `${this.ProductToSave.storeId}`);
    product.append(
      'CategoryId',
      `${this.productform.value.productCategory.selectedCategory}`
    );
    product.append('Name', `${this.productform.value.productDetail.name}`);
    product.append(
      'Description',
      `${this.productform.value.productDetail.description}`
    );
    product.append(
      'SellingPrice',
      `${this.productform.value.productDetail.sellingPrice}`
    );
    product.append(
      'ShowSellingPrice',
      `${this.productform.value.productDetail.showSellingPrice}`
    );
    product.append(
      'Discount',
      `${this.productform.value.productDetail.discount ?? 0}`
    );
    product.append(
      'coverImageFileName',
      `${this.productform.value.productImage.coverImageFileName}`
    );
    product.append(
      'BrandName',
      `${this.productform.value.productDetail.brandName}`
    );
    product.append('Active', `${this.productform.value.productDetail.active}`);

    for (let index = 0; index < this.sourcesFiles.length; index++) {
      product.append('ProductImagefiles', this.sourcesFiles[index]);
    }
    for (let index = 0; index < this.videos.length; index++) {
      if (this.videos[index].length >= 4404020) {
        const translatedMessage = this.translate.instant('toastr.error.videosize');
        this.toastr.error(translatedMessage);
 
        return false;
      }
      product.append('ProductVideofiles', this.videos[index]);
    }

    this.productService.CreateNewProduct(product).subscribe(
      (data: any) => {
        this.ProductToSave = data;
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
        const translatedMessage = this.translate.instant('toastr.success.productcreate');
        this.toastr.success(translatedMessage);
        this.redirectToProduct();
      },
      (error: any) => {
        this.SpinnerService.hide();

        if (
          error?.error?.errors !== undefined &&
          error.error?.errors?.CoverImageFileName
        ) {
          const translatedMessage = this.translate.instant('toastr.error.selectcoverimage');
          this.toastr.error(translatedMessage);
     
        } else if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error.productduplicate');
          this.toastr.error(translatedMessage);
      
        } else {
          const translatedMessage = this.translate.instant('toastr.error.productcreate');
          this.toastr.error(translatedMessage);
  
          this.SpinnerService.hide();
        }
      }
    );
  }
  AddProductDetails() {
    this.isFormSubmitted = true;
    this.tabpdSuccess = false;
    this.without_html = this.removeHTMLTags(this.removeHTMLEntities(this.removeHTMLNumbers(this.productform.value.productDetail.description)));
  
      if(this.without_html.length>500){
      this.maxlengthError="Product description maxlength is 500";
       }
       if(this.without_html.length<500 || this.without_html.length===500)
      {
        this.maxlengthError="";
      }
      if(this.maxlengthError!=""){
        this.productform.controls.productDetail.status == 'INVALID'
      if (this.productform.controls.productDetail.invalid) {
        if (this.selectdTab !== 'pd') {
          const translatedMessage = this.translate.instant('toastr.error.productdetailrequired');
          this.toastr.error(translatedMessage);
       
        }

        return false;
      }
    }

      if (this.productform.controls.productDetail.status == 'INVALID') {
        this.SpinnerService.hide();

        return false;
      }
    this.SpinnerService.show();

    const product = new FormData();
    
    product.append('Name', `${this.productform.value.productDetail.name}`);
    product.append(
      'Description',
      `${this.productform.value.productDetail.description}`
    );
    product.append(
      'SellingPrice',
      `${this.productform.value.productDetail.sellingPrice}`
    );
    product.append(
      'ShowSellingPrice',
      `${this.productform.value.productDetail.showSellingPrice}`
    );
    product.append(
      'Discount',
      `${this.productform.value.productDetail.discount ?? 0}`
    );
    product.append(
      'BrandName',
      `${this.productform.value.productDetail.brandName}`
    );
    product.append('Active', `${this.productform.value.productDetail.active}`);
if(this.id){
  product.append('Id', `${this.id}`);
  product.append('StoreId', `${this.route.snapshot.params['id']}`);
  this.productService.UpdateProductDetails(product).subscribe(
    (data: any) => {
      this.tabpdSuccess = true;
 
      this.SpinnerService.hide();
      this.ispdFormSubmitted = false;
      this.activeTab(this.event, this.selectdTab);
 
      
    },
    (error: any) => {
      this.selectdTab = 'pd';
      this.SpinnerService.hide();
      if (error.status === 400) {
        const translatedMessage = this.translate.instant('toastr.error.productduplicate');
        this.toastr.error(translatedMessage);
     
      } else {
        const translatedMessage = this.translate.instant('toastr.error.productcreate');
        this.toastr.error(translatedMessage);
   
        this.SpinnerService.hide();
        this.tabpdSuccess = false;
        
      }
      return
    }
   );
}else{
  product.append('StoreId', `${this.ProductToSave.storeId}`);
  this.productService.AddNewProduct(product).subscribe(
    (data: any) => {
      this.tabpdSuccess = true;

      this.SpinnerService.hide();
      this.ispdFormSubmitted = false;

      this.id = data;
      this.activeTab(this.event, this.selectdTab);
    },
    (error: any) => {
      this.selectdTab = 'pd';
      this.SpinnerService.hide();
      if (error.status === 400) {
        const translatedMessage = this.translate.instant('toastr.error.productduplicate');
        this.toastr.error(translatedMessage);
    
      } else {
        const translatedMessage = this.translate.instant('toastr.error.productcreate');
        this.toastr.error(translatedMessage);
     
        this.SpinnerService.hide();
        this.tabpdSuccess = false;
        
      }
      return
    }
   );
}
    
    


  }
  AddProductCategory(){
    this.tabpcSuccess = false;
    if (this.productform.controls.productCategory.invalid) {
       
      if (this.selectdTab !== 'pc') {
        if(this.maxlengthError===""){
          const translatedMessage = this.translate.instant('toastr.error.productcategoryrequired');
          this.toastr.error(translatedMessage);
   
      }
      }
      return;
    }
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append(
      'CategoryId',
      `${this.productform.value.productCategory.selectedCategory}`
    );

    this.productService.AddNewProductCategory(product).subscribe(
      (data: any) => {
   
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
     
        this.tabpcSuccess = true;
        this.activeTab(this.event, this.selectdTab);
      },
      (error: any) => {
        this.SpinnerService.hide();
        if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error');
          this.toastr.error(translatedMessage);
        
        } else {
          const translatedMessage = this.translate.instant('toastr.error.productcreate');
          this.toastr.error(translatedMessage);
     
          this.SpinnerService.hide();
          this.tabpcSuccess = false;
        }
        this.selectdTab = 'pc';
        return
      }
    );

  }
  AddProductImages(){
    this.tabuiSuccess = false;
    if (this.productform.controls.productCategory.invalid) {
       
      if (this.sourcesFiles.length === 0) {
        if (this.selectdTab !== 'ui') {
          const translatedMessage = this.translate.instant('toastr.error.productimagerequired');
          this.toastr.error(translatedMessage);
     
        }
        return;
      }
      this.step = 3;
      var isValid = this.validateCoverImageFile();

      if (isValid == false) {
        if (this.selectdTab !== 'ui') {
          const translatedMessage = this.translate.instant('toastr.error.coverimagerequired');
          this.toastr.error(translatedMessage);
    
        }

        return false;
      }
      
      if (this.productform.status == 'INVALID') {
        this.SpinnerService.hide();

        return false;
      }
    }
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append('StoreId', `${this.route.snapshot.params['id']}`);
if(this.addedImage === true){

  product.append(
    'coverImageFileName',
    `${this.productform.value.productImage.coverImageFileName}`
  );
  for (let index = 0; index < this.sourcesFiles.length; index++) {
    product.append('ProductImagefiles', this.sourcesFiles[index]);
  }
  this.productService.UpdateProductImagesOnAdd(product).subscribe(
    (data: any) => {
      this.SpinnerService.hide();
      this.isFormSubmitted = false;
   
         this.activeTab(this.event, this.selectdTab);
         this.selectdTab = 'uv'
         this.tabuiSuccess = true;
    },
    (error) => {
      if (error.status === 400) {
        const translatedMessage = this.translate.instant('toastr.error.productduplicate');
        this.toastr.error(translatedMessage);

        this.SpinnerService.hide();
      } else {
        const translatedMessage = this.translate.instant('toastr.error.productupdate');
        this.toastr.error(translatedMessage);

        this.SpinnerService.hide();
      }
      this.selectdTab = 'ui'
    }
  );
}else{

  product.append(
    'coverImageFileName',
    `${this.productform.value.productImage.coverImageFileName}`
  );
  for (let index = 0; index < this.sourcesFiles.length; index++) {
    product.append('ProductImagefiles', this.sourcesFiles[index]);
  }
  this.productService.AddNewProductImages(product).subscribe(
    (data: any) => {
     
      this.SpinnerService.hide();
      this.isFormSubmitted = false;
      this.addedImage = true;
   
      this.tabuiSuccess = true;
      this.activeTab(this.event, this.selectdTab);
      this.selectdTab = 'uv'
    },
    (error: any) => {
      this.SpinnerService.hide();
      if (error.status === 400) {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
     
      } else {
        const translatedMessage = this.translate.instant('toastr.error.productcreate');
        this.toastr.error(translatedMessage);
   
        this.SpinnerService.hide();
        this.tabuiSuccess = false;
      }
      this.selectdTab = 'ui';
    }
  );
}


  }
  AddProductVideo(){
    this.tabuvSuccess = false;
    if (this.productform.controls.productCategory.invalid) {

      if (this.productform.status == 'INVALID') {
        this.SpinnerService.hide();

        return false;
      }
    }
    this.SpinnerService.show();

    const product = new FormData();
    product.append('Id', `${this.id}`);
    product.append('StoreId', `${this.route.snapshot.params['id']}`);
    for (let index = 0; index < this.videos.length; index++) {
      if (this.videos[index].length >= 4404020) {
        const translatedMessage = this.translate.instant('toastr.error.videosize');
        this.toastr.error(translatedMessage);
     
        return false;
      }
      product.append('ProductVideofiles', this.videos[index]);
    }
    this.productService.AddNewProductVideo(product).subscribe(
      (data: any) => {
  
        this.SpinnerService.hide();
        this.isFormSubmitted = false;
        const translatedMessage = this.translate.instant('toastr.success.productcreate');
        this.toastr.success(translatedMessage);
  
        this.tabuvSuccess = true;

        this.redirectToProduct();
      },
      (error: any) => {
        this.SpinnerService.hide();
        if (error.status === 400) {
          const translatedMessage = this.translate.instant('toastr.error');
          this.toastr.error(translatedMessage);

        } else {
          const translatedMessage = this.translate.instant('toastr.error.productcreate');
          this.toastr.error(translatedMessage);

          this.SpinnerService.hide();
          this.tabuvSuccess = false;
        }
        this.selectdTab = 'uv';
      }
    );

  }

  get f() {
    return this.productform.controls;
  }
  get productDetail() {
    return this.productform.controls['productDetail']['controls'];
  }
  get productCategory() {
    return this.productform.controls['productCategory']['controls'];
  }
  get productImage() {
    return this.productform.controls['productImage']['controls'];
  }

  removeImageEdit(i: any, image: any) {
    this.imageDeleteFrom.value.id = i;
    this.imageDeleteFrom.value.Image = image;
  }
  removeImage(i: any) {
    this.sourcesFile.splice(i, 1);
    this.sourcesFiles.splice(i, 1);
 
  }
  removeImageMobile(i: any,j:any) {

    var deletedFilename = i.fileinfo.name;
    if(deletedFilename == this.productform.value.productImage.coverImageFileName || this.ProductToSave.coverImageFileName == deletedFilename){
      this.productform.value.productImage.coverImageFileName = null;
      this.productform.value.productImage.coverImageFileName = false;
      this.productform.get('productImage.coverImageFileName').setValue(false);
      this.ProductToSave.coverImageFileName = null;
    }
    this.sourcesFile.splice(j, 1);
    this.sourcesFiles.splice(j, 1);
 
  }
  removeHTMLNumbers(str: string): string {
    return str.replace(/&#?\w+;/g, '');
  }

  removeHTMLEntities(str: string): string {
    const element = document.createElement('div');
    element.innerHTML = str;
    return element.textContent || element.innerText || '';
  }

  removeHTMLTags(str: string): string {
    return str.replace(/<[^>]*>/g, '');
  }
  onSelectedImage(event: any) {
    var existingFileCnt =
      this.ProductToSave.productImages == undefined
        ? 0
        : this.ProductToSave.productImages.length;
    var newFileCnt =
      this.sourcesFile == undefined ? 0 : this.sourcesFile.length;
    var currentFileCnt =
      event.target.files == undefined ? 0 : event.target.files.length;
    var totalFileCnt = newFileCnt + currentFileCnt;
    if (totalFileCnt > 4) {
      const translatedMessage = this.translate.instant('toastr.error.imagecount');
      this.toastr.error(translatedMessage);
   
      return;
    }

    let allImages: Array<string> = ['image/png', 'image/jpg', 'image/jpeg'];
    if (event.target.files.length > 0) {
      var filesCount = event.target.files.length;
      for (let i = 0; i < filesCount; i++) {
        if (event.target.files[i].size > 2097152) {
          const translatedMessage = this.translate.instant('toastr.error.imagesize');
          this.toastr.error(translatedMessage);


          return false;
        } else if (!this.validateFile(event.target.files[i].name)) {
          const translatedMessage = this.translate.instant('toastr.error.imageformat');
          this.toastr.error(translatedMessage);
     

          return false;
        }else if(this.sourcesFiles.length > 0 && this.duplicateFileName(event.target.files[i].name) === false ){
          const translatedMessage = this.translate.instant('toastr.error.duplicatefile');
          this.toastr.error(translatedMessage);
         
          return false;
        } else {
          var reader = new FileReader();
          reader.onload = (ee: any) => {
            var fileData = {
              fileinfo: event.target.files[i],
              fileResult: ee.target.result,
            };
            this.sourcesFile.push(fileData);
          };


          reader.readAsDataURL(event.target.files[i]);
          this.sourcesFiles.push(event.target.files[i]);
        }
        const fileInput = event.target;

        fileInput.addEventListener('click', () => {
          fileInput.value = null;
        });
      }
      
    }
  }
  duplicateFileName(name: String){
  
    for(let j = 0; j < this.sourcesFiles.length; j++){
      if(name == this.sourcesFiles[j].name){
          return false;
        }
    }
  }
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg'
    ) {
      return true;
    } else {
      return false;
    }
  }

  removeVideoEdit(i: any, video: any) {
    this.videoDeleteFrom.value.id = i;
    this.videoDeleteFrom.value.video = video;
  }
  removevideo(i: number) {
    this.videos.splice(i, 1);
    this.video.splice(i, 1);
    this.inputVideoFile.nativeElement.value = null;
  }
  removeEditvideo(i: number, videoId: number) {
    this.deletedVideos.push(videoId);
    this.ProductToSave.videosToBeDeleted = this.deletedVideos;
    this.ProductToSave.productVideos.splice(i, 1);
  }
  onSelectVideo(event: any) {
    var existingFileCnt =
      this.ProductToSave.productVideos == undefined
        ? 0
        : this.ProductToSave.productVideos.length;
    var newFileCnt = this.videos == undefined ? 0 : this.videos.length;
    var currentFileCnt =
      event.target.files == undefined ? 0 : event.target.files.length;
    var totalFileCnt = existingFileCnt + newFileCnt + currentFileCnt;
    if (totalFileCnt > 1) {
      const translatedMessage = this.translate.instant('toastr.error.videocount');
      this.toastr.error(translatedMessage);

      return;
    }
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size <= 4404020) {
        var filesCount = event.target.files.length;
        for (let i = 0; i < filesCount; i++) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.video.push(event.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
        this.videos.push(event.target.files[0]);
      } else {
        const translatedMessage = this.translate.instant('toastr.error.videosize');
        this.toastr.error(translatedMessage);
   

        return;
      }
    }
  }

  onCategoryAdd() {
    this.router.navigateByUrl('/dashboard/category');
  }

  next() {
    this.step = this.step + 1;
  }
  previous() {
    this.step = this.step - 1;
  }

  openTab(evt: any, tab) {
    
    this.selectdTab = tab;
      if(this.currentTab =='pd' && this.selectdTab=='pc'){
      this.ispdFormSubmitted=true;
      if (this.tabpdSuccess) {
        this.currentTab = this.selectdTab;
      }
      this.selectdTab = this.currentTab;
           return;
    }
    if(this.currentTab =='pd' && this.selectdTab=='ui'){
      this.ispdFormSubmitted=true;
      if(this.tabpcSuccess && this.tabpdSuccess){
        this.currentTab = this.selectdTab;
      }
      this.selectdTab = this.currentTab;
      return;
    }
    if(this.currentTab =='pd'&& this.selectdTab=='uv'){
      this.ispdFormSubmitted=true;
      if(this.tabpdSuccess && this.tabpcSuccess && this.tabuiSuccess){
        this.currentTab = this.selectdTab;
      
      }
      this.selectdTab = this.currentTab;
      return;
    }
     if(this.currentTab =='pc'&& this.selectdTab=='ui'){
      this.ispcFormSubmitted=true;
      if (this.tabpcSuccess) {
        this.currentTab = this.selectdTab;
      }
      this.selectdTab = this.currentTab;
      return;
    }
     if(this.currentTab =='pc'&& this.selectdTab=='uv'){
      this.ispcFormSubmitted=true;
      if (this.tabpcSuccess && this.tabuiSuccess) {
        this.currentTab = this.selectdTab;
    }
      this.selectdTab = this.currentTab;
      return;
    }
      if(this.currentTab =='ui'&& this.selectdTab=='uv'){
      
      this.isuiFormSubmitted=true;
      if (this.tabuiSuccess)
      {
       
      this.currentTab = this.selectdTab;
    }
    this.isuiFormSubmitted=false;
    this.isCoverImageFileExist = true;
    this.selectdTab = this.currentTab;
    return;
    }
 
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    document.getElementById(tab).style.display = 'block';
    evt.currentTarget.className += ' active';
     }

  openNextTab(evt: any, tab) {
    this.selectdTab = tab;
    this.event = evt;

    if(this.selectdTab=='pc'){
      this.ispdFormSubmitted=true;
      this.without_html = this.removeHTMLTags(this.removeHTMLEntities(this.removeHTMLNumbers(this.productform.value.productDetail.description)));
      if(this.without_html.length > 500){
        this.maxlengthError="Product description maxlength is 500";
      }
      if (this.productform.controls.productDetail.invalid || this.without_html.length>500) {
        this.selectdTab='pd';
        this.currentTab = this.selectdTab;
           return;
      }
      this.AddProductDetails()

    }
    if(this.selectdTab=='ui'){
      this.ispcFormSubmitted=true;
      if (this.productform.controls.productCategory.invalid) {
        this.selectdTab='pc';
        this.currentTab = this.selectdTab;
              return;
      }
     this.AddProductCategory();
   
    }
    if(this.selectdTab=='uv'){
      this.isuiFormSubmitted=true;
      if (this.sourcesFiles.length == 0)
      {this.selectdTab='ui'; 
      this.currentTab = this.selectdTab;
      return;
      
    }
    this.step = 3;
      var isvalid = this.validateCoverImageFile();
      if (isvalid == false){
        this.selectdTab='ui';
        this.currentTab = this.selectdTab;
         return;
      }
      this.isCoverImageFileExist = true;
        this.AddProductImages()
    }

  }
  
  activeTab(evt: any, tab){
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    document.getElementById(tab).style.display = 'block';
    if(tab=='pd'){tablinks[0].className = 'tablinks active'}
    if(tab=='pc'){tablinks[1].className = 'tablinks active'}
    if(tab=='ui'){tablinks[2].className = 'tablinks active'}
    if(tab=='uv'){tablinks[3].className = 'tablinks active'}

  }
  onInput(event: any) {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.data);
    if (!pattern.test(inputChar)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }
  limitInputLength(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 8) {
      input.value = input.value.slice(0, 8);
    }
  }
  preventExceedingMaxLength(event: any) {
    const input = event.target as HTMLInputElement;
    const maxLength = 8;
    const value = input.value;

    if (
      value.length >= maxLength &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
      event.preventDefault();
    }
  }
  decimalFilter(event: any) {

    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
        event.preventDefault();
    }
 }
 pastepreventnegativevalue(event: any) {

  const into = event.clipboardData.getData('text/plain')

  const substring = '-';

  into.includes(substring)

  if (into.includes(substring)) {

    event.preventDefault();

  }

}

   editor(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      var value = control.value;
      let without_html = value.replace(
        /<(?:.|\n)*?>/gm,
        ' '
      );
      if (without_html==="   ") {
        return { lengthError: true };
      } else {
        return null;
      }
    };
  }
  pastepreventExceedingMax(event: any) {
    const into = event.clipboardData.getData('text/plain');
    let without_html = this.productform.value.productDetail.description.replace(
      /<(?:.|\n)*?>/gm,
      ' '
    );

    without_html = without_html + into;

    if (without_html.length > 500) {
      event.prevehntDefault();
    }
    
  }

  preventExceedingMax(event: any) {
    const input = event.target as HTMLInputElement;
    if (
      input.innerText.length > 499 &&
      event.key !== 'Backspace' &&
      event.key !== 'Delete'
    ) {
     event.preventDefault();
    }
  
  }
  
  descriptionCharCount(event) {
    const count = event.target.innerText.length; 
    if (count > 500) {
      event.preventDefault();
      this.productform.patchValue({ description: '' });
    }
  }

  preventExceedingMaxlengthForMobile(event:any){
    
    const inputElement = event.target as HTMLInputElement;
    if (
      inputElement.innerText.length > 499    &&  
      event.key !== 'Backspace' &&
      event.key !== 'Delete'

    ) {
     event.preventDefault();
    }
  }
  restrictInputMobile(event: any) {

    this.maxlengthError="";
  }
  getPlaceholderText() {
    return this.translate.instant('addproduct.editor.placeholder');
  }
}
