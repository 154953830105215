<div class="container-fluid main-content">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-9 edit-user">
      <span class="font-weight-600 text-23 text-16 edit-user-font"
        >{{ "edituser.title" | translate }}</span
      >
    </div>
  </div>
  <div class="mobile-view-border">
    <form #form="ngForm" [formGroup]="userlistForm" (ngSubmit)="onSubmit()">
      <div class="contact-form">
        <div class="container">
          <div class="profile-form">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label for="exampleInputname" class="control-label text-14"
                  >{{ "edituser.firstname.label" | translate }}<span class="required">*</span></label
                >
                <input
                  formControlName="firstName"
                  type="text"
                  class="form-control"
                  placeholder=	"{{ 'edituser.firstname.placeholder' | translate }}"
                  maxlength="15"
                  [focusMe]="true"
                />
                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="isFormSubmitted && f.firstName.errors?.required"
                >
                {{'validation.firstname.required' | translate }}
                </div>
                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="f.firstName.errors?.pattern"
                >
                {{'validation.firstname.pattern' | translate }}
                </div>
              </div>
              <br />
              <div class="col-md-1"></div>
              <div class="col-md-4 margin-email">
                <label class="control-label text-14"
                  >{{ "edituser.lastname.label" | translate }}<span class="required">*</span></label
                >
                <div class="">
                  <input
                    formControlName="lastName"
                    type="text"
                    class="form-control"
                    placeholder="{{ 'edituser.lastname.placeholder' | translate }}"
                    maxlength="15"
                  />
                  <div
                    class="text-red text-12 font-weight-bold mt-1"
                    *ngIf="f.lastName.errors?.pattern"
                  >
                  {{'validation.lastname.pattern' | translate }}
                  </div>

                  <div
                    class="text-red text-12 font-weight-bold mt-1"
                    *ngIf="isFormSubmitted && f.lastName.errors?.required"
                  >
                  {{'validation.lastname.required' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label class="control-label text-14">{{ "edituser.email.label" | translate }}</label
                ><input
                  formControlName="email"
                  type="email"
                  class="form-control"
                  placeholder="{{ 'edituser.email.placeholder' | translate }}"
                  maxlength="50"
                />

                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="isFormSubmitted && f.email.errors?.pattern"
                >
                {{'validation.email.pattern' | translate }}
                </div>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-4 margin-email">
                <label class="control-label text-14">
                  {{ "edituser.mobilenumber.label" | translate }}<span class="required">*</span></label
                >
                <input
                  formControlName="mobileNumber"
                  type="text"
                  class="form-control"

                  disabled="true"
                />
                <div></div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4 mb-3">
                <label class="control-label text-14">{{ "edituser.username.label" | translate }}</label>
                <input
                  formControlName="username"
                  type="text"
                  class="form-control"
             
                  disabled="true"
                />
              </div>
              <div class="col-md-1"></div>
            </div>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4 mb-3">
                <input
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked
                  formControlName="active"
                />&nbsp;
                <label
                  class="form-check-label text-14 active-font-weight"
                  for="flexCheckChecked"
                >
                {{ "edituser.active" | translate }}
                </label>
              </div>
              <div class="col-md-1"></div>
              <div
                class="col-md-4 mb-3"
                *ngIf="this.profileobj.profileImagePath"
              >
                <img
                  [src]="this.profileobj.profileImagePath"
                  alt="Paris"
                  style="width: 150px"
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="mb-4 mt-3">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-11 mobile-button-padding desktop-button-padding">
        <button
          type="cancel"
          class="btn btn-colour-cancel mobile-btn-save-cancel"
          (click)="onCancelClick()"
        >
        {{ "edituser.button.cancel" | translate }}
        </button>
        <button
          type="submit"
          class="btn btn-colour mobile-btn-save"
          (click)="onSubmit()"
        >
        {{ "edituser.button.submit" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  class="store-list-popup"
  [baseZIndex]="10000"
></p-confirmDialog>
<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
