import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreAPIService } from '../storeapiservice.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@/Model/store';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareimageService } from '@services/shareimage.service';
import { MobileNumberValidator } from '../mobilrnumber.validator';
import { ProfileService } from '@services/profile.service';
import intlTelInputObject from 'intl-tel-input';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-editStore',
  templateUrl: './editStore.component.html',
  styleUrls: ['./editStore.component.css']
})
export class EditStoreComponent implements OnInit {
  @ViewChild('phoneInput') phoneInput: ElementRef;
  @ViewChild('phoneInput1') phoneInput1: ElementRef;
  loading = false;
  id!: number;
  post!: Store;
  form!: FormGroup;
  storeform!: FormGroup;
  imageSrc: any;
  sourcesFile: any[] = [];
  UserId: string;
  public isFormSubmitted: boolean = false;
  selectedUserId: any;
  whatsappNumberError: string;
  contactNumberError: string;
  languageName: any;
  iti: intlTelInput.Plugin;
  iti1: intlTelInput.Plugin;
  options: intlTelInput.Options = { separateDialCode: true, autoInsertDialCode: true, utilsScript: "./assets/js/intl-tel-input/utils.js", nationalMode: false, formatOnDisplay: false, autoPlaceholder: 'off' }
  constructor(
    public storeService: StoreAPIService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private shareimageService: ShareimageService,
    private formbuilder: FormBuilder,
    private profileservice: ProfileService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang('en');
    this.InitializeFormControls();
  }
  ngOnInit(): void {

    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    this.profileservice.getUserById().subscribe((data: any) => {
      if (data.isSuperAdmin == true) {
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
        });
      }
    }, (err: any) => {

      if (err.status !== 404) {
        return;
      }
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      this.router.navigate(['/login']);

    });
    document.getElementById("tempid_Store")?.classList.add("highlight_sideMenu");
    this.shareimageService.setPageName("Stores");
    this.UserId = "00";
    this.InitializeFormControls();
    this.id = this.route.snapshot.params['postId'];
    this.SpinnerService.show();
    this.storeService.getStoreById(this.id).subscribe((data: Store) => {

      this.post = data;
      this.selectedUserId = data.userId;
      this.storeform.controls['storeId'].setValue(this.post.storeId);
      this.storeform.controls['name'].setValue(this.post.name);
      this.storeform.controls['description'].setValue(this.post.description);
      if ((this.post.contactNumber ?? '') !== '') {
        const conatctNumberArray = this.post.contactNumber.split(" ");
        if (conatctNumberArray.length > 1) {
          this.storeform.controls['contactNumber'].setValue(conatctNumberArray[1]);
          this.iti1.setNumber(this.post.contactNumber);
        } else {
          this.storeform.controls['contactNumber'].setValue(this.post.contactNumber);
        }

      } else {
        this.post.contactNumber = '';
      }
      const mobilenumberArray = this.post.whatsAppNumber.split(" ");
      if (mobilenumberArray.length > 1) {

        this.storeform.controls['whatsAppNumber'].setValue(mobilenumberArray[1]);
        this.iti.setNumber(this.post.whatsAppNumber);
      } else {
        this.storeform.controls['whatsAppNumber'].setValue(this.post.whatsAppNumber);
      }


      this.storeform.controls['email'].setValue(this.post.email);
      this.post.address == 'null' ? '' : this.storeform.controls['address'].setValue(this.post.address);
      this.post.facebookUrl == 'null' ? '' : this.storeform.controls['facebookUrl'].setValue(this.post.facebookUrl);
      this.post.instagramUrl == 'null' ? '' : this.storeform.controls['instagramUrl'].setValue(this.post.instagramUrl);
      this.post.gstNo == 'null' ? '' : this.storeform.controls['GSTNo'].setValue(this.post.gstNo);
      this.storeform.controls['active'].setValue(this.post.active);
      this.storeform.controls['storeUrl'].setValue(this.post.storeURL);
      this.SpinnerService.hide();
    });

  }
  ngAfterViewInit() {

    this.iti = intlTelInputObject(this.phoneInput.nativeElement, this.options);
    this.iti1 = intlTelInputObject(this.phoneInput1.nativeElement, this.options);
    this.iti.setCountry('in');
    this.iti1.setCountry('in');
  }
  onInputWhatsappNumber(event: any) {
    this.whatsappNumberError = "";
   
    if (/(\d)\1{5,}/.test(event.target.value)) {
      this.whatsappNumberError = "Invalid number"
    }


  }
  onInputContactNumber(event: any) {
    this.contactNumberError = "";

    if (/(\d)\1{5,}/.test(event.target.value)) {
      this.contactNumberError = "Invalid"
    }
  }
  private InitializeFormControls() {
    const reg = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    this.storeform = this.formbuilder.group({
      UserId: new FormControl(this.UserId),
      storeId: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      contactNumber: new FormControl('', [Validators.pattern(/^\S+$/)]),
      whatsAppNumber: new FormControl('', [Validators.required, Validators.pattern(/^\S+$/)]),
      email: new FormControl('', [Validators.maxLength(60), Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      address: new FormControl(' '),
      facebookUrl: new FormControl(' ', Validators.pattern(reg)),
      instagramUrl: new FormControl(' ', Validators.pattern(reg)),
      GSTNo: new FormControl(' ', Validators.pattern("^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})+$")),
      active: new FormControl(true),
      storeUrl: new FormControl('')
    }, {
      validator: MobileNumberValidator("whatsAppNumber", "contactNumber")
    }
    );
  }
  onInput(event: any) {

    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.data);
    if (!pattern.test(inputChar)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  }
  submit() {

 if(this.whatsappNumberError!= "" && this.whatsappNumberError!= undefined){
  return
}
if( this.contactNumberError != "" && this.contactNumberError != undefined){
return
}
    this.SpinnerService.show();
    this.isFormSubmitted = true;
    this.whatsappNumberError="";
    this.contactNumberError="";
    if (this.storeform.status == 'INVALID') {
      this.loading = false;
      this.SpinnerService.hide();
      return
    }
    if (!this.iti.isValidNumber()) {
      this.whatsappNumberError = this.getWhatsappNumberErrorMessage(this.iti.getValidationError());
      this.SpinnerService.hide();
    } 
    if (this.storeform.value.contactNumber != "") 
    {
      if (!this.iti1.isValidNumber()) {
        this.contactNumberError = this.getContactNumberErrorMessage(this.iti1.getValidationError());
        this.SpinnerService.hide();
      }
    }
  
    if (this.whatsappNumberError === ""  && this.contactNumberError === "") {
    const addStore = new FormData();
    addStore.append("storeId", this.storeform.value.storeId);
    addStore.append("UserId", this.storeform.value.UserId);
    addStore.append("name", this.storeform.value.name);
    addStore.append("description", this.storeform.value.description);

    if (this.storeform.value.contactNumber != '') {
      addStore.append("contactNumber", '+' + this.iti1.getSelectedCountryData().dialCode + ' ' + this.storeform.value.contactNumber);
    }

    var whatsAppNumberArray = this.storeform.value.whatsAppNumber.split(" ");
    if (whatsAppNumberArray.length > 1) {
      addStore.append("whatsAppNumber", '+' + this.iti.getSelectedCountryData().dialCode + ' ' + whatsAppNumberArray[0]);
    } else {
      addStore.append("whatsAppNumber", '+' + this.iti.getSelectedCountryData().dialCode + ' ' + this.storeform.value.whatsAppNumber);
    }

    addStore.append("email", this.storeform.value.email);
    addStore.append("address", this.storeform.value.address);
    addStore.append("facebookUrl", this.storeform.value.facebookUrl);
    addStore.append("instagramUrl", this.storeform.value.instagramUrl);
    addStore.append("GSTNo", this.storeform.value.GSTNo);
    addStore.append("active", this.storeform.value.active);

    this.storeService.update(addStore).subscribe((data: Store) => {
      if (data.success === false) {
        const translatedMessage = this.translate.instant('toastr.error.duplicatestore');
        this.toastr.error(translatedMessage);
      }
      else {
        const translatedMessage = this.translate.instant('toastr.success.updatestore');
        this.toastr.success(translatedMessage);
        this.redirectToStore();
      }

      this.SpinnerService.hide();

    }, (error: any) => {
      this.loading = false;
      this.SpinnerService.hide();
    })
  }
  }
  onCancelClick() {

    this.redirectToStore();
  }
  redirectToStore() {
    var uid = this.selectedUserId;
    this.router.navigate(
      ['dashboard/store'],
      { queryParams: { uid } }
    );
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.storeform.patchValue({
          fileSource: reader.result
        });
      };
      this.sourcesFile.push(file);
    }
  }
  get f() {
    return this.storeform.controls;
  }
  onInputKeyPress = (event: KeyboardEvent) => {
    const allowedChars = /[0-9\+\-\ ]/;
    if (
      !allowedChars.test(event.key) 
    ) {
      event.preventDefault();
    }
  };
  getWhatsappNumberErrorMessage(errorCode) {
    switch (errorCode) {
      case window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return "Invalid whatsapp number";
      case window.intlTelInputUtils.validationError.TOO_SHORT:
        return "Invalid whatsapp number";
      case window.intlTelInputUtils.validationError.TOO_LONG:
        return "Invalid whatsapp number";
      case window.intlTelInputUtils.validationError.NOT_A_NUMBER:
        return "Invalid whatsapp number";
      default:
        return "Invalid whatsapp number";

    }
  }
  getContactNumberErrorMessage(errorCode) {
    switch (errorCode) {
      case window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return "Invalid contact number";
      case window.intlTelInputUtils.validationError.TOO_SHORT:
        return "Invalid contact number";
      case window.intlTelInputUtils.validationError.TOO_LONG:
        return "Invalid contact number";
      case window.intlTelInputUtils.validationError.NOT_A_NUMBER:
        return "Invalid contact number";
      default:
        return "Invalid contact number";

    }
  }
  ngOnDestroy(): void {
    document.getElementById("tempid_Store").classList.remove("highlight_sideMenu");
    this.iti.destroy();
    this.iti1.destroy();
  }
}
