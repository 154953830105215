<div id="content">
  <div class="container-fluid main-content d-none d-md-block d-lg-block">
    <div class="">
      <form  #form="ngForm" [formGroup]="reportForm" class="report_form">
        <div class="row mb-3" style="width: 100%">
          <div class="col-3" *ngIf="isSuperAdmin === true" >
            <label>{{ "report.selectusername.label" | translate }} <span class="required">*</span></label>
            <ng-select
            *ngIf="isSuperAdmin === true"
              [items]="users"
              autofocus
              bindLabel="name"
              bindValue="id"
              placeholder="{{'report.select.placeholder'| translate }}"
              name="id"
              formControlName="name"
              (change)="onUsernameDropdownChange($event)"
              [(ngModel)]="selectedUser"
              [clearable]="false"
            >
            </ng-select>
            <div
              class="text-red text-12 font-weight-bold mt-1"
              *ngIf="isSuperAdmin && isFormSubmitted && this.selectedUser === undefined"
            >
            {{ "validation.store.username.required" | translate }} 
            </div>
          </div>
          <div class="col-2"  style="text-align:left;">
            <label>{{ "report.selectstartdate.label" | translate }}<span class="required">*</span></label>
            <div class="p-field" >
              <p-calendar
                [showIcon]="true"
                inputId="icon"
                formControlName="startDate"
                dataType="string"
                dateFormat="dd/mm/yy"
                placeholder="{{'report.startdate.placeholder'| translate }}"
                [maxDate]="maxAllowedDate"
                [ngClass]="{
                  'is-invalid': isFormSubmitted && f.startDate.errors
                }"
              ></p-calendar>
              <div
                *ngIf="isFormSubmitted && f.startDate.errors"
                class="invalid-feedback"
              >
                <div class="text-red text-12 font-weight-bold mt-1">
                  {{ "validation.startdate.required" | translate }} 
                </div>
              </div>
              <div class="text-red text-12 font-weight-bold mt-1">
                <div *ngIf="errorMessage=='Start date should not be greater than end date'">
                  {{"validation.datecomparetype" | translate  }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-2" style="text-align:left;" >
            <label>{{ "report.selectenddate.label" | translate }}<span class="required">*</span></label>
            <div class="p-field">
              <p-calendar

                [showIcon]="true"
                inputId="icon"
                formControlName="endDate"
                dataType="string"
                dateFormat="dd/mm/yy"
                placeholder="{{'report.enddate.placeholder'| translate }}"
                [maxDate]="maxAllowedDate"
              ></p-calendar>
              <div
                class="text-red text-12 font-weight-bold mt-1"
                *ngIf="isFormSubmitted && f.endDate.errors?.required"
              >
              {{ "validation.enddate.required" | translate }} 
              </div>
            </div>
          </div>
          <div [ngClass]="isSuperAdmin === false ? 'col-8' : 'col-5'"  class="report-button-style">
            <div class="row">
              <div style="width:50%; text-align: left;">
                <button
                class="btn btn-lg btn-secondary btn_space"
                  type="submit"
                  (click)="searchReport()"
                > <img src="assets/svgimages/Search.svg" class="h-14" alt="" />
                {{ "report.button.search" | translate }}
                </button>
                &nbsp;&nbsp;
              </div>
              <div style="width:50%; text-align: right;">
                <button
            class="btn btn-primary button-css btn_space"
              type="submit"
              (click)="exportCSVFile()"
            ><img src="assets/svgimages/exportcsv.svg" class="h-14" alt="" />
            {{ "report.button.export" | translate }}
            </button>
              </div>
            </div>


          </div>
        </div>
      </form>
      <div class="card">
        <p-table
          [value]="reportResult"
          sortField="storeName"
          sortMode="single"
          dataKey="storeName"
          rowGroupMode="subheader"
          groupRowsBy="storeName"
          [rows]="10"
          [paginator]="true"
          class="table-padding table-width"
          [rowsPerPageOptions]="[5, 10, 25, 50]"
          class="table-padding table-width"
          [scrollable]="true"
          scrollDirection="horizontal" 
          scrollHeight="700px"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "report.storename" | translate }}</th>
              <th>{{ "report.categoryname" | translate }}</th>
              <th>{{ "report.productname" | translate }}</th>
              <th>{{ "report.usagedate" | translate }}</th>
              <th>{{ "report.usagecount" | translate }}</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="groupheader"
            let-report
            let-rowIndex="rowIndex"
            let-expanded="expanded"
          >
            <tr>
              <td colspan="5">
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="report"
                  class="p-button-text p-button-rounded p-button-plain p-mr-2"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                ></button
                >&nbsp;&nbsp;
                <span class="p-text-bold p-ml-2 text-transform-capitalize"
                  >{{ report.storeName }} ({{
                    productscount(report.products)
                  }} product)</span
                >
              </td>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="rowexpansion"
            let-report
            let-rowIndex="rowIndex"
          >
            <tr *ngFor="let p of report.products">
              <td></td>
              <td class="text-transform-capitalize">{{ p.categoryName }}</td>
              <td class="text-transform-capitalize">
                {{ p.productName }}
              </td>
              <td>
                {{ p.productUsageDate | date: "dd/MM/yyyy" }} (<b>{{p.dayDifference}}</b>)
              </td>
              <td>
                {{ p.totalProductUsageCount }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6" style="text-align: left">{{ "report.text" | translate }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <!-- Mobile view HTML -->
  <div class="d-block d-md-none d-lg-none container-fluid">
        <div class="mt-3">
          <form  #form="ngForm" [formGroup]="reportForm" >
            <div class="mt-3">
              <ng-select
              *ngIf="isSuperAdmin"
              [items]="users"
              autofocus
              bindLabel="name"
              bindValue="id"
              placeholder="{{'report.select.placeholder'| translate }}"
              name="id"
              [(ngModel)]="selectedUser"
              (change)="onUsernameDropdownChange($event)"
              formControlName="name"
              [clearable]="false"
            >
            </ng-select>
            <div
            class="text-red text-12 font-weight-bold mt-1"
            *ngIf="isSuperAdmin && isFormSubmitted && this.selectedUser === undefined"
          >
          {{ "validation.store.username.required" | translate }} 
          </div>
      
            </div>
          <div class="mt-3 m-1">
                  <p-calendar
                    [showIcon]="true"
                    inputId="icon"
                    formControlName="startDate"
                    dataType="string"
                    dateFormat="dd/mm/yy"
                    placeholder="{{'report.startdate.placeholder'| translate }}"
                    [maxDate]="maxAllowedDate"
                    [ngClass]="{
                      'is-invalid': isFormSubmitted && f.startDate.errors
                    }"
                  ></p-calendar>
                  <div
                    *ngIf="isFormSubmitted && f.startDate.errors"
                    class="invalid-feedback"
                  >
                    <div class="text-red text-12 font-weight-bold mt-1">
                      {{ "validation.startdate.required" | translate }} 
                    </div>
                  </div>
                  <div class="text-red text-12 font-weight-bold mt-1">
                    <div *ngIf="errorMessage=='Start date should not be greater than end date'">
                      {{"validation.datecomparetype" | translate  }}
                    </div>
                  </div>
          </div>

          <div class="mt-3 m-1">
            <p-calendar
            [showIcon]="true"
            inputId="icon"
            formControlName="endDate"
            dataType="string"
            dateFormat="dd/mm/yy"
            placeholder="{{'report.enddate.placeholder'| translate }}"
            [maxDate]="maxAllowedDate"
            [ngClass]="{
              'is-invalid': isFormSubmitted && f.startDate.errors
            }"
            ></p-calendar>
            <div
              *ngIf="isFormSubmitted && f.endDate.errors"
              class="invalid-feedback"
            >
              <div class="text-red text-12 font-weight-bold mt-1">
                {{ "validation.enddate.required" | translate }} 
              </div>
            </div>

    </div>
      </form>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col-6">
        <button type="submit" class="refresh-button" (click)="exportCSVFile()">
          <img src="assets/svgimages/exportcsv.svg" alt="add" class="h-18" /> <span class="marginleft-10px Buttontextsize">{{'report.mobile.button.export'| translate }}</span>
        </button>
    </div>
    <div class="col-6">
   
      <button type="submit" class="add-store-btn" (click)="searchReport()">
        <img src="assets/svgimages/Search.svg" alt="add" class="h-18" /><span class="marginleft-10px Buttontextsize">{{'report.button.search'| translate }}</span>
      </button>
    </div>
    </div>

    <div class="mt-3" style="text-align: center;" *ngIf=" (reportResult === null || reportResult === undefined || reportResult.length===0)">{{'report.text'| translate }}</div>

    <div *ngFor="let obj of reportResult | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalItems } ;" >
      <p-accordion class="card" >
        <p-accordionTab class="text-14 text-blue-color font-weight-600 text-transform-capitalize" header="{{ obj.storeName }} ({{
          productscount(obj.products)
        }} product)">
        <div class="row text-blue-color align-items-end mt-2" id="{{obj.storeName}}" class="padding-data">
          <div *ngFor="let p of obj.products let i = index" class="col-md-12 col-12 lh-22 " >
            <div class="row">
              <span class="text-9 font-weight-600 "
              >{{ "report.mobile.productname" | translate }}
              <span class="text-blue-color text-13 font-weight-600 text-transform-capitalize">{{ p.productName }}</span></span>
            </div>
            <div class="row">
              <span class="text-9 font-weight-600"
              >
              {{ "report.mobile.usagedate" | translate }}
              <span class="text-blue-color text-13 font-weight-600">{{ p.productUsageDate | date: "dd/MM/yyyy" }} (<b>{{p.dayDifference}}</b>)</span></span>

            </div>
            <div class="row" style="display: flex;">
              <div style="width:50%;text-align: left;">
                <span class="text-9 font-weight-600"
                > {{ "report.mobile.categoryname" | translate }}
                <span class="text-blue-color text-13 font-weight-600 text-transform-capitalize">{{ p.categoryName }}</span></span>
              </div>
              <div  style="width:50%; text-align: right;">
                <span class="text-9 font-weight-600"
                >
                {{ "report.mobile.usagecount" | translate }}
                <span class="text-blue-color text-13 font-weight-600">{{ p.totalProductUsageCount }}</span></span>
              </div>
            </div>
              <div [ngClass]="obj.products.length > 1  &&  obj.products.length > i+1 ? 'row separator' : ''"></div>

          </div>

        </div>
        
      </p-accordionTab>

    </p-accordion>
  </div>
  <div class="pagination-container">
  <pagination-controls (pageChange)="pageChanged($event)" [maxSize]="10"  previousLabel="{{ 'pagination.previous' | translate }}"
  nextLabel="{{ 'pagination.next' | translate }}"
   [directionLinks]="true"></pagination-controls>
  </div>
</div>
<ngx-spinner
bdColor="rgba(202,202,202,0.8)"
size="medium"
color="#e72744"
type="timer"
>
<p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>

