import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ProfileService {

constructor(private router: Router, private http: HttpClient) { }
updateuser(user: any) {

  return this.http.put(`${environment.userApiUrl}/User/update`,user,{responseType: 'text'})
}

getUserById() {
  return this.http.get<any[]>(`${environment.userApiUrl}/User/GetUserById`);
}

ChangePassword(chngPwd: any) {

  return this.http.post(`${environment.userApiUrl}/User/ChangePassword`,chngPwd,{responseType: 'text'})
}

GetUsersForDropdown(){
  return this.http.get<any[]>(environment.userApiUrl + '/User/getusersfordropdown')
}
GetAllUserList(){
   return  this.http.get<any[]>(`${environment.userApiUrl}/User/GetAll`);
}
getUserByUserId(UserId:number) {
  return this.http.get<any[]>(`${environment.userApiUrl}/User/GetUser/${UserId}`);
}
getNotification(){
  return this.http.get<any[]>(`${environment.storeApiUrl}/Store/GetNotification`);
}
// getUserByEmail(Email:any){
//   return this.http.get<any[]>(`${environment.userApiUrl}/User/GetUserByEmail/${Email}`)
// }
}
