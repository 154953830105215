<a
  (click)="handleMainMenuAction()"
  class="nav-link"
  id="tempid_{{ menuItem.name }}"
  [ngClass]="{ active: isMainActive || isOneOfChildrenActive }"
>
  <div *ngIf="menuItem.name == 'Dashboard'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.dashboard' | translate }}"
    />

    <p
      *ngIf="menuItem.name == 'Dashboard'"
      title="{{ 'sidebar.dashboard' | translate }}"
    >
      {{ "sidebar.dashboard" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
  <div *ngIf="menuItem.name == 'Store'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.store' | translate }}"
    />
    <p
      *ngIf="menuItem.name == 'Store'"
      title="{{ 'sidebar.store' | translate }}"
    >
      {{ "sidebar.store" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
  <div *ngIf="menuItem.name == 'Category'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.category' | translate }}"
    />
    <p
      *ngIf="menuItem.name == 'Category'"
      title="{{ 'sidebar.category' | translate }}"
    >
      {{ "sidebar.category" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
  <div *ngIf="menuItem.name == 'Product'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.product' | translate }}"
    />
    <p
      *ngIf="menuItem.name == 'Product'"
      title="{{ 'sidebar.product' | translate }}"
    >
      {{ "sidebar.product" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
  <div *ngIf="menuItem.name == 'User'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.user' | translate }}"
    />
    <p *ngIf="menuItem.name == 'User'" title="{{ 'sidebar.user' | translate }}">
      {{ "sidebar.user" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
  <div *ngIf="menuItem.name == 'Report'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.report' | translate }}"
    />
    <p
      *ngIf="menuItem.name == 'Report'"
      title="{{ 'sidebar.report' | translate }}"
    >
      {{ "sidebar.report" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
  <div *ngIf="menuItem.name == 'Change Password'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.changepassword' | translate }}"
    />
    <p
      *ngIf="menuItem.name == 'Change Password'"
      title="{{ 'sidebar.changepassword' | translate }}"
    >
      {{ "sidebar.changepassword" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
  <div *ngIf="menuItem.name == 'Logout'">
    <img
      [src]="menuItem.iconImagePath"
      title="{{ 'sidebar.logout' | translate }}"
    />
    <p
      *ngIf="menuItem.name == 'Logout'"
      title="{{ 'sidebar.logout' | translate }}"
    >
      {{ "sidebar.logout" | translate }}
      <i
        *ngIf="isExpandable && menuItem.children.length > 0"
        class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"
        >&nbsp;</i
      >
    </p>
  </div>
</a>
