
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

constructor(private router: Router, private http: HttpClient) { }

getReport(report:any){

  return this.http.get<any[]>(`${environment.reportApiUrl}/Report/GetData?username=${report.username}&startdate=${report.startDate}&endDate=${report.endDate}`)
}
getReportFor30Days(username:any){

  return this.http.get<any[]>(`${environment.reportApiUrl}/Report/GetDataFor30Days?userName=${username}`)

}
public exportToCSV(report:any){
  return this.http.get(`${environment.reportApiUrl}/Report/ExportCsvFile?userName=${report.username}&startdate=${report.startDate}&endDate=${report.endDate}`,
 {observe:'response', responseType:'blob'}

)
}
}
