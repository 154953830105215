import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginModel } from '@/Model/login';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

constructor(private router:Router,private http:HttpClient) { }
login(login: LoginModel) {

  return this.http.post(`${environment.userApiUrl}/user/Login`, login);
}
IsLoggedIn(){
  return localStorage.getItem('token') != null ;
}

}
