import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from './../../services/profile.service';
import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostBinding
} from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Userprofile } from '@/Model/userprofile';
import { ChangePassword } from '@/Model/ChangePassword';
import { ShareimageService } from '@services/shareimage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css']
})
export class UserprofileComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'register-box';
  public userprofile: FormGroup;
  public changePasswordForm: FormGroup;
  public isAuthLoading = false;
  profileobj: Userprofile = new Userprofile();
  changeobj: ChangePassword = new ChangePassword;
  public FormSubmitted: boolean = false;
  userId: number = 0;
  id: any;
  imageSrc: any;
  sourcesFile: any[] = [];
  profileImage: any;
  profileImageData: any;
  initialsletters: any;
  Userprofileimg: any;
  profileImageVisible: boolean = false;
  public isFormSubmitted: boolean = false;
  letters: any;
  imgdata: any;
  isImageChange: boolean = false;
  loading = false;
  loadingbutton = false;
  languageName: string;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private router: Router,
    private profileservice: ProfileService,
    private shareimageService: ShareimageService,
    private SpinnerService: NgxSpinnerService,
    private translate: TranslateService
  ) { this.translate.setDefaultLang('en'); }

  ngOnInit() {
    this.shareimageService.setPageName("Profile Settings");
    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.translate.use('en');
    } else {
      this.translate.use(this.languageName);
    }
    this.userprofile = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      mobileNumber: new FormControl(''),
      email: new FormControl('', [Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]),
      username: new FormControl(''),
      profileImage: new FormControl(''),
      profileImagePath: new FormControl(''),
      id: new FormControl(''),
      createdBy: new FormControl(''),
      createdDate: new FormControl(''),
      password: new FormControl('',),
      updatedBy: new FormControl(''),
      updatedDate: new FormControl(''),
    });

    this.id = localStorage.getItem('userId')
    this.SpinnerService.show();
    this.profileservice.getUserById().subscribe((data: any) => {
      if (data.isSuperAdmin == true) {
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
          localStorage.removeItem('notificationDataUserName');
        });
      }

      this.profileobj = data;
      this.userprofile.controls['id'].setValue(this.profileobj.id);
      this.userprofile.controls['firstName'].setValue(this.profileobj.firstName);
      this.userprofile.controls['lastName'].setValue(this.profileobj.lastName);
      this.userprofile.controls['mobileNumber'].setValue(this.profileobj.mobileNumber);
      this.userprofile.controls['email'].setValue(this.profileobj.email);
      this.userprofile.controls['username'].setValue(this.profileobj.username);
      this.userprofile.controls['password'].setValue(this.profileobj.password);
      this.userprofile.controls['createdBy'].setValue(this.profileobj.createdBy);
      this.userprofile.controls['createdDate'].setValue(this.profileobj.createdDate);
      this.userprofile.controls['updatedBy'].setValue(this.profileobj.updatedBy);
      this.userprofile.controls['updatedDate'].setValue(this.profileobj.updatedDate);
      this.userprofile.controls['profileImage'].setValue(this.profileobj.profileImage);
      this.imageSrc = `${this.profileobj.profileImagePath}`


      if (this.profileobj.profileImagePath == null || this.profileobj.profileImagePath == "") {
        this.profileImageVisible = false;
        this.initialsletters = this.getShortName(this.profileobj.firstName, this.profileobj.lastName);
        this.letters = this.initialsletters.split('').join(' ');
      }
      else {
        this.profileImageVisible = true;
        this.imageSrc = `${this.profileobj.profileImagePath}`;
      }
      this.SpinnerService.hide();
    });
  }
  getShortName(firstname, lastname) {
    const initials = firstname.charAt(0) + lastname.charAt(0);
    return initials.toUpperCase();
  }
  get f() {
    return this.userprofile.controls;
  }
  get funchangePasswordForm() {
    return this.changePasswordForm.controls;
  }
  onSubmit() {

    this.isFormSubmitted = true;
    this.loading = true;
    if (this.userprofile.value.email == null) {
      this.userprofile.value.email = '';
    }
    if (this.userprofile.invalid) {
      this.loading = false;
      return false;

    } else {
      const updateuser = new FormData();
      updateuser.append("id", this.userprofile.value.id);
      updateuser.append("firstName", this.userprofile.value.firstName);
      updateuser.append("lastName", this.userprofile.value.lastName);
      updateuser.append("mobileNumber", this.userprofile.value.mobileNumber);
      updateuser.append("email", this.userprofile.value.email);
      updateuser.append("profileImage", this.profileImageData);

      this.SpinnerService.show();
      this.profileservice.updateuser(updateuser).subscribe((data: any) => {

        let resData = JSON.parse(data);
        if (this.isImageChange == true) {
          this.shareimageService.setImageUrl({
            imagePath: `${resData['profileImagePath']}`,
            data: resData, isImageChange: this.isImageChange
          });
        } else {
          this.shareimageService.setImageUrl({
            data: resData, isImageChange: false
          });
        }
        const translatedMessage = this.translate.instant('toastr.success.profileupdate');
        this.toastr.success(translatedMessage);
        this.SpinnerService.hide();
        localStorage.setItem('lastName', data.lastName);
        this.loading = false;
      }, (error: any) => {
        const translatedMessage = this.translate.instant('toastr.error');
        this.toastr.error(translatedMessage);
        this.loading = false;
      }
      );
    }
  }
  onFileChange(event: any) {

    event.preventDefault();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (!this.validateFile(file.name)) {
        const translatedMessage = this.translate.instant('toastr.error.profileimage');
        this.toastr.error(translatedMessage);
      }
      else if (event.target.files[0].size > 4404020) {
        const translatedMessage = this.translate.instant('toastr.error.profileimagesize');
        this.toastr.error(translatedMessage);
        return false;
      }
      else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
          this.profileImageVisible = true;
          this.userprofile.patchValue({
            fileSource: reader.result
          });
        };
        this.sourcesFile.push(file);
        this.profileImageData = file;
        this.isImageChange = true;
      }
    }
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (
      ext.toLowerCase() == 'png' ||
      ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg'
    ) {
      return true;
    } else {
      return false;
    }
  }
  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
  }
  onCancelClick() {
    this.router.navigateByUrl("dashboard");
  }
  onImageClick(event) {
    event.preventDefault();
  }

}
