import { SetpasswordOTPverifyService } from './../../services/setpasswordOTPverify.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  HostBinding,
  OnInit,
  Renderer2
} from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-setpasswordverifyOTP',
  templateUrl: './setpasswordverifyOTP.component.html',
  styleUrls: ['./setpasswordverifyOTP.component.css']
})
export class SetpasswordverifyOTPComponent implements OnInit {

  @HostBinding('class') class = 'login-box';
  OTPForm: FormGroup;
  loading: boolean = false;
  submitted = false;
  UserId: any;
  errorMessage: string;
  errorMessage1: string;
  public isFormSubmitted: boolean = false;
  selectedLanguage: string;
  languageName: string;
  languages = [{ id: 'en', language: 'English' },
  { id: 'mr', language: 'मराठी' },
  { id: 'hi', language: 'हिंदी' }]
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private SetpasswordOTPverifyService: SetpasswordOTPverifyService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { this.translate.setDefaultLang('en'); }

  ngOnInit(): void {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );

    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.selectedLanguage = 'en';
      this.translate.use(this.selectedLanguage);
    }
    else {
      this.selectedLanguage = this.languageName;
      this.translate.use(this.selectedLanguage);
    }
    this.UserId = this.route.snapshot.params['id'];

    this.OTPForm = new FormGroup({
      OTP: new FormControl('', [Validators.required]),
    });

    this.OTPForm.controls['OTP'].valueChanges.subscribe(() => {
      // Clear all error messages
      this.errorMessage = '';
    });
  }

  get f() {
    return this.OTPForm.controls;
  }
  onInput(event: any) {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.data);
    if (!pattern.test(inputChar)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  }
  useLanguage(event: any) {

    localStorage.setItem('language', event.id);
    this.translate.use(event.id);
  }
  onSubmit() {

    this.isFormSubmitted = true;
    this.submitted = true;
    this.loading = true;
    if (this.OTPForm.invalid) {
      this.loading = false;
    }
    else {
      this.SetpasswordOTPverifyService.setpasswordverifyOTP(this.OTPForm.value.OTP).subscribe((data: any) => {
        data = JSON.parse(data);
        localStorage.setItem('mobileNumber', data.mobileNumber);
        this.router.navigateByUrl("/set-password");
        this.loading = false;
      }, (error: any) => {
        this.errorMessage = error;
        this.loading = false;
      }
      );

    }
  }
  resendOTP() {
    this.SetpasswordOTPverifyService.resendOTP(this.UserId).subscribe((data: any) => {
      this.errorMessage = data.message;
      this.loading = false;
    }, (error) => {
      this.errorMessage = error;
      this.loading = false;
    })
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }

}



