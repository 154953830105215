import { CategoryService } from '@/AddCategory/category.service';
import { StoreService } from '@/AddStore/store.service';
import { StoreAPIService } from '@/AddStore/storeapiservice.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

constructor(private httpClient: HttpClient, private categoryService : CategoryService, private storeService: StoreAPIService) { }
GetAllStores() {
  return this.storeService.GetStoreCount();
}
GetAllCategory() {
  return this.categoryService.getCategoryCount();
}
GetAllProduct() {
  return this.httpClient.get<any[]>(environment.prodApiUrl + '/Product/GetCount')
}
GetAllUser() {
  return this.httpClient.get<any[]>(environment.userApiUrl + '/User/GetAll')
}
GetDataForLineChartByUserId() {
  return this.httpClient.get<any[]>(environment.reportApiUrl + '/Report/GetDataForLineChart')
}
GetDataForPieChartByUserId() {
  return this.httpClient.get<any[]>(environment.reportApiUrl + '/Report/GetDataForPieChart')
}
GetDataForStoreBarChartByUserId() {
  return this.httpClient.get<any[]>(environment.reportApiUrl + '/Report/GetDataForStoreBarChart')
}
GetDataForBarChartByUserId() {
  return this.httpClient.get<any[]>(environment.reportApiUrl + '/Report/GetDataForBarChart')
}
}
