<div id="content ">
  <div class="container-fluid main-content d-none d-md-block d-lg-block">
    <div class="row product-list-table mt-3">
      <p-table
        #dt
        [value]="data"
        (onFilter)="isEmpty($event)"
        [rows]="10"
        [paginator]="true"
        class="table-padding table-width"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        styleClass="p-datatable-customers p-datatable-striped datatable-border-0"
        [globalFilterFields]="['name', 'email', 'whatsAppNumber']"
        [rowHover]="true"
        dataKey="id"
        [showCurrentPageReport]="true"
        sortField="StoreId"
        sortOrder="-1"
        [scrollable]="true"
        scrollDirection="horizontal"
        scrollHeight="700px"
      >
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-6">
              <span class="p-input-icon-left" style="width: 100%">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  class="search_bar"
                  (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="{{ 'storelist.search.placeholder' | translate }}"
                  #customSearch
                  [focusMe]="true"
                />
              </span>
            </div>
            <div class="col-3" *ngIf="isSuperAdmin === true">
              <ng-select
                *ngIf="isSuperAdmin === true"
                [items]="users"
                autofocus
                bindLabel="name"
                bindValue="id"
                placeholder="{{ 'storelist.dropdown.placeholder' | translate }}"
                name="id"
                [(ngModel)]="selectedUser"
                (change)="onUsenameDropdownChange($event)"
                [clearable]="false"
                #select
              >
              </ng-select>
            </div>
            <div
              class="col-3"
              style="text-align: right"
              [ngClass]="{ 'col-6': isSuperAdmin === false }"
            >
              <button
                class="btn btn-lg btn-secondary btn_space"
                type="submit"
                (click)="onAddClick()"
                style="margin-right: 10px"
              >
                <img src="assets/svgimages/plus.svg" class="h-14" alt="" />
                {{ "storelist.button.addstore" | translate }}
              </button>
              <button
                class="btn btn-primary btn_space"
                type="submit"
                (click)="onRefresh(dt)"
                title="{{ 'storelist.title.refresh' | translate }}"
              >
                <img src="assets/svgimages/Refresh.svg" class="h-14" alt="" />
              </button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" class="store-list-border">
          <br />
          <tr>
            <th pSortableColumn="name">
              {{ "storelist.storename" | translate
              }}<p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="email">
              {{ "storelist.email" | translate }}
              <p-sortIcon field="email"></p-sortIcon>
            </th>
            <th pSortableColumn="whatsAppNumber">
              {{ "storelist.contactnumber" | translate }}
              <p-sortIcon field="whatsAppNumber"></p-sortIcon>
            </th>
            <th pSortableColumn="storeURL">
              {{ "storelist.storeurl" | translate }}
              <p-sortIcon field="storeURL"></p-sortIcon>
            </th>
            <th pSortableColumn="active">
              {{ "storelist.status" | translate
              }}<p-sortIcon field="active"></p-sortIcon>
            </th>
            <th pSortableColumn="publishStatus">
              {{ "storelist.publishstatus" | translate }}
              <p-sortIcon field="publishStatus"></p-sortIcon>
            </th>
            <th>{{ "storelist.action" | translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr class="p-table">
            <td class="email-id">{{ data.name }}</td>
            <td class="email-id">{{ data.email }}</td>
            <td>{{ data.whatsAppNumber }}</td>
            <td>
              <a
                *ngIf="data.storeURL && data.publishStatus"
                [href]="data.storeURL"
                target="_blank"
                style="color: #007bff"
                >Visit Store</a
              >
            </td>
            <td>
              <div *ngIf="data.active == true">
                <span class="status text-success">•</span
                >{{ "storelist.active" | translate }}
              </div>
              <div *ngIf="data.active == false || data.active == null">
                <span class="status text-warning">•</span
                >{{ "storelist.inactive" | translate }}
              </div>
            </td>
            <td>
              <div *ngIf="data.publishStatus === 'Approved'">
                <span class="status text-success">•</span
                >{{ data.publishStatus }}
              </div>
              <div *ngIf="data.publishStatus === 'Pending'">
                <span class="status text-warning">•</span
                >{{ data.publishStatus }}
              </div>
              <div *ngIf="data.publishStatus === 'Rejected'">
                <span class="status text-danger">•</span
                >{{ data.publishStatus }}
              </div>
            </td>
            <td class="store-list-icons">
              <button
                type="button"
                class="btn btn-rounded-hash mr-1"
                title="{{ 'storelist.title.QR' | translate }}"
                (click)="showDialog(data)"
              >
                <img
                  src="assets/svgimages/QRCode.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
              <button
                class="btn btn-rounded-hash mr-1"
                (click)="oneditClick(data.storeId)"
                title="{{ 'storelist.title.edit' | translate }}"
              >
                <img
                  src="assets/svgimages/editred.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
              <button
                class="btn btn-rounded-hash mr-1"
                (click)="onDeleteClick(data)"
                title="{{ 'storelist.title.delete' | translate }}"
              >
                <img
                  src="assets/svgimages/delete.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
              <button
                class="btn btn-rounded-hash"
                (click)="publishClick(data)"
                *ngIf="
                  data.active &&
                  isSuperAdmin &&
                  data.publishStatus === 'Pending'
                "
                title="{{ 'storelist.title.publish' | translate }}"
              >
                <img
                  src="assets/svgimages/publish.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
              <button
                class="btn btn-rounded-hash ml-1"
                (click)="rejectClick(data)"
                *ngIf="
                  data.active &&
                  isSuperAdmin &&
                  data.publishStatus === 'Pending'
                "
                title="{{ 'storelist.title.reject' | translate }}"
              >
                <img
                  src="assets/svgimages/Cancel.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
              <button
                class="btn btn-rounded-hash"
                (click)="sendPublishRequest(data)"
                *ngIf="data.active && isSuperAdmin == false"
                title="{{ 'storelist.title.sendpublishrequest' | translate }}"
              >
                <img
                  src="assets/svgimages/send.svg"
                  alt=""
                  class="edit-button"
                />
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="12" style="text-align: left">
              {{ "storelist.text" | translate }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-dialog
    [(visible)]="confirm"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
  >
    <ng-template pTemplate="header">
      <h5>
        <b>{{ "rejectConfirmationDialog.title" | translate }}</b>
      </h5>
    </ng-template>
    <div class="row">
      <div class="col-12">
        {{ "rejectConfirmationDialog.message" | translate }}
      </div>
      <div class="col-12">
        <label class="control-label col-sm-4 text-14"
          >{{ "rejectConfirmationDialog.reason" | translate
          }}<span class="required">*</span></label
        >
      </div>
      <div class="col-sm-12">
        <textarea
          class="c-popup"
          rows="2"
          [(ngModel)]="reason"
          placeholder="{{ 'rejectConfirmationDialog.placeholder' | translate }}"
          maxlength="200"
        ></textarea>
      </div>
      <div class="col-sm-12" *ngIf="confirmClick">
        <div class="text-red text-12 font-weight-bold mt-1 margin-error">
          {{ errormsgReason }}
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button
        type="submit"
        class="add-store-btn confirm-btn mobile-confirm-btn"
        (click)="oncancelClick()"
      >
        {{ "rejectConfirmationDialog.cancel" | translate }}
      </button>
      <button
        type="submit"
        class="add-store-btn confirm-btn mobile-confirm-btn"
        (click)="onConfirmClick()"
      >
        {{ "rejectConfirmationDialog.submit" | translate }}
      </button>
    </ng-template>
  </p-dialog>
  <p-dialog
    [(visible)]="display"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
  >
    <ng-template pTemplate="header">
      <h5>
        <b> {{ attributeDataHeader }}</b>
      </h5>
    </ng-template>
    <a *ngIf="attributeData" [href]="attributeData">
      <qr-code
        [value]="attributeData"
        class="d-flex justify-content-center qr-code-img"
        size="280"
      ></qr-code>
    </a>
    <ng-template pTemplate="footer">
      <div class="row">
        <div class="col-4">
          <img src="assets\svgimages\logo icon.svg" class="logo-M" />
          <img src="assets\svgimages\logo.svg" class="M-logo" />
        </div>
        <div class="col-2"></div>
        <div class="col-6">
          <span class="QRcode-text">Powered by arieotech</span>
        </div>
      </div>
    </ng-template>
  </p-dialog>

  <p-confirmDialog
    class="store-list-popup"
    [baseZIndex]="10000"
  ></p-confirmDialog>
  <ngx-spinner
    bdColor="rgba(202,202,202,0.8)"
    size="medium"
    color="#e72744"
    type="timer"
  >
    <p style="font-size: 20px; color: #e72744">Please wait...</p>
  </ngx-spinner>

  <!-- Mobile view HTML -->

  <div class="d-block d-md-none d-lg-none container-fluid">
    <div class="mt-3">
      <div class="has-search position-relative">
        <i class="fa fa-search form-control-feedback"></i>
        <input
          type="text"
          class="form-control mobSearch"
          placeholder="{{ 'storelist.mobile.search' | translate }}"
          [(ngModel)]="searchText"
          autocomplete="off"
          (input)="onEdit(searchText)"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <button
          type="submit"
          class="refresh-button"
          (click)="onRefreshmobile()"
        >
          <img src="assets/svgimages/Refresh.svg" alt="add" class="h-18" />
          <span class="marginleft-10px Buttontextsize">{{
            "storelist.mobile.refresh" | translate
          }}</span>
        </button>
      </div>
      <div class="col-6">
        <button type="submit" class="add-store-btn" (click)="onAddClick()">
          <img src="assets/svgimages/plus.svg" alt="add" class="h-18" /><span
            class="marginleft-10px Buttontextsize"
            >{{ "storelist.button.addstore" | translate }}</span
          >
        </button>
      </div>
    </div>
    <div class="mt-3">
      <ng-select
        *ngIf="isSuperAdmin"
        [items]="users"
        autofocus
        bindLabel="name"
        bindValue="id"
        placeholder="{{ 'storelist.dropdown.placeholder' | translate }}"
        name="id"
        [(ngModel)]="selectedUser"
        (change)="onUsenameDropdownChange($event)"
        [clearable]="false"
      >
      </ng-select>
    </div>
    <div
      class="mt-3"
      style="text-align: center"
      *ngIf="data === null || data === undefined || data.length === 0"
    >
      {{ "storelist.text" | translate }}
    </div>
    <div *ngIf="showPaginationControls">
      <div
        *ngFor="
          let obj of data
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: currentPage,
                  totalItems: totalItems
                }
            | storeFilter: searchText
        "
      >
        <div class="card store-card mt-3">
          <div class="card-body padding-rem-75">
            <div class="row">
              <div class="col-md-7 col-7">
                <div class="d-flex align-items-center">
                  <ngx-avatar
                    size="40"
                    name="{{ obj.name.substring(0, 15) }}"
                  ></ngx-avatar>
                  <div class="ml-1 lh-18">
                    <span
                      class="text-14 text-blue-color font-weight-600 text-transform-capitalize ml-2"
                      >{{ obj.name }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-5" style="text-align: right">
                <span class="text-13 font-weight-600">
                  <div *ngIf="obj.active == true">
                    <span class="status text-success mr-1 ml-1">•</span
                    >{{ "storelist.active" | translate }}
                  </div>
                  <div *ngIf="obj.active == false || obj.active == null">
                    <span class="status text-warning">•</span
                    >{{ "storelist.inactive" | translate }}
                  </div>
                </span>
              </div>
            </div>
            <div class="row text-blue-color align-items-end mt-2">
              <div class="col-md-12 col-12 lh-22">
                <span class="text-9 font-weight-600">
                  {{ "storelist.mobile.email" | translate }}
                  <span class="text-blue-color text-12">{{
                    obj.email
                  }}</span></span
                >
              </div>
              <div class="col-md-12 col-12 lh-22">
                <span class="text-9 font-weight-600">
                  {{ "storelist.mobile.whatsappnumber" | translate }}
                  <span
                    href="#"
                    class="text-blue-color text-13 font-weight-600"
                    >{{ obj.whatsAppNumber }}</span
                  ></span
                >
              </div>
              <div class="col-md-7 col-7 lh-22">
                <span class="text-9 d-block font-weight-600">
                  {{ "storelist.mobile.storeurl" | translate }} &nbsp;<span
                    *ngIf="!obj.storeURL"
                    >Not Available</span
                  >
                  <a
                    class="text-blue-color text-13 font-weight-600"
                    *ngIf="obj.storeURL"
                    [href]="obj.storeURL"
                    target="_blank"
                    style="color: #007bff"
                    > {{ "storelist.visitstore" | translate }}</a
                  ></span
                >
                <span class="text-9 d-block font-weight-600">
                  {{ "storelist.mobile.publishstatus" | translate }}
                  <span class="text-blue-color text-13 font-weight-600">{{
                    obj.publishStatus
                  }}</span></span
                >
              </div>
              <div class="col-md-5 col-5">
                <div class="d-flex button-store justify-content-end">
                  <button
                    type="button"
                    class="btn btn-rounded-hash mr-1"
                    title="View QR code"
                  >
                    <img
                      src="assets/svgimages/QRCode.svg"
                      alt=""
                      (click)="showDialog(obj)"
                      class="edit-button"
                    />
                  </button>

                  <button
                    class="btn btn-rounded-hash mr-1"
                    (click)="oneditClick(obj.storeId)"
                    title="Edit"
                  >
                    <img
                      src="assets/svgimages/editred.svg"
                      alt=""
                      class="edit-button"
                    />
                  </button>
                  <button
                    class="btn btn-rounded-hash mr-1"
                    (click)="onDeleteClick(obj)"
                    title="Delete"
                  >
                    <img
                      src="assets/svgimages/delete.svg"
                      alt=""
                      class="edit-button"
                    />
                  </button>
                  <button
                    class="btn btn-rounded-hash"
                    (click)="publishClick(obj)"
                    *ngIf="
                      obj.active &&
                      isSuperAdmin &&
                      obj.publishStatus === 'Pending'
                    "
                    title="Publish"
                  >
                    <img
                      src="assets/svgimages/publish.svg"
                      alt=""
                      class="edit-button"
                    />
                  </button>
                  <button
                    class="btn btn-rounded-hash ml-1"
                    (click)="rejectClick(obj)"
                    *ngIf="
                      obj.active &&
                      isSuperAdmin &&
                      obj.publishStatus === 'Pending'
                    "
                    title="Reject"
                  >
                    <img
                      src="assets/svgimages/Cancel.svg"
                      alt=""
                      class="edit-button"
                    />
                  </button>
                  <button
                    class="btn btn-rounded-hash"
                    (click)="sendPublishRequest(obj)"
                    *ngIf="obj.active && isSuperAdmin == false"
                    title="Send Publish Request"
                  >
                    <img
                      src="assets/svgimages/send.svg"
                      alt=""
                      class="edit-button"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-container">
      <pagination-controls
        (pageChange)="pageChanged($event)"
        [maxSize]="10"
        [directionLinks]="true"
        previousLabel="{{ 'pagination.previous' | translate }}"
        nextLabel="{{ 'pagination.next' | translate }}"
      ></pagination-controls></div>
    </div>
  </div>
</div>
