import { Router } from '@angular/router';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPasswordValidator } from '@modules/confirmpassword.validator';
import { SetpasswordService } from '@services/setpassword.service';
import { setPassword } from '@/Model/setpassword';
import { UsernameValidator } from '@/validators/usernameValidator';
import { TranslateService } from '@ngx-translate/core';
import { notOnlyNumbersValidator } from '@modules/notonlynumbervalidator';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css'],
})
export class SetpasswordComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';

  public setPasswordForm: FormGroup;
  public loading = false;
  EmailId: string;
  User: any;
  value: boolean = false;
  obj: any;
  passwordmatch: setPassword = new setPassword();
  show_password: Boolean = false;
  show_eyepassword: Boolean = false;
  show_confirmpassword: Boolean = false;
  show_eyeconfirmpassword: Boolean = false;
  submitted: boolean = false;
  public isFormSubmitted: boolean = false;
  usernameError: string = '';
  messageUsername: string = '';
  setPasswordError: string = '';
  languageName: any;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private router: Router,
    private setpasswordservice: SetpasswordService,
    private formBuilder: FormBuilder,
    private usernameValidator: UsernameValidator,
    private translate: TranslateService
  ) { this.translate.setDefaultLang('en'); }

  ngOnInit(): void {

    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.User = JSON.parse(localStorage.getItem('user'));
    this.languageName = localStorage.getItem('language');
    this.translate.use(this.languageName);

    this.setPasswordForm = this.formBuilder.group(
      {
        username: new FormControl('', [Validators.required, notOnlyNumbersValidator()], [this.usernameValidator.checkUsername.bind(this.usernameValidator)]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      {
        validator: ConfirmPasswordValidator('password', 'confirmPassword'),
      }
    );

  }
  get f() {
    return this.setPasswordForm.controls;
  }
  usernamechange(event: any) {
    this.messageUsername = '';
    this.isFormSubmitted = false;
  }
  validateUsername(fieldname: string) {
    const inputValue = this.setPasswordForm.get(fieldname).value;
    if (inputValue === '' || inputValue === null) {
      this.usernameError = '';
    } else {
      this.setpasswordservice
        .checkDuplicateUsername(inputValue)
        .subscribe((data: any) => {
          if (data.success === true) {
            this.usernameError = '';
          } else {
            this.usernameError = 'Username already exists';
          }
        });
    }
  }
  setPassword() {

    this.loading = true;
    this.isFormSubmitted = true;
    this.setPasswordError = "";
    if (this.setPasswordForm.valid) {
      if (this.usernameError === '') {
        this.passwordmatch.password = this.f.password.value;
        this.passwordmatch.confirmPassword = this.f.confirmPassword.value;
        if (this.passwordmatch.password == this.passwordmatch.confirmPassword) {
          this.User = localStorage.getItem('mobileNumber');

          if (!this.User) {
            this.User = {
              firstName: '',
              lastName: '',
              mobileNumber: '',
              email: '',
            };
          }

          const setpassworddata = new FormData();
          setpassworddata.append('mobileNumber', this.User);
          setpassworddata.append(
            'username',
            this.setPasswordForm.value.username
          );
          setpassworddata.append(
            'password',
            this.setPasswordForm.value.password
          );
          this.setpasswordservice.setpassword(setpassworddata).subscribe(
            (data: any) => {
              var user = JSON.parse(data);
              if (user.success === false) {
                this.messageUsername = "Username already exists";
                this.loading = false;
              }
              else {
                this.messageUsername = '';
                this.loading = false;
              }
              if (user.active === false) {
                const translatedMessage = this.translate.instant('toastr.info.passwordset');
                this.toastr.info(translatedMessage, '', {
                  timeOut: 9000,

                });

                this.router.navigateByUrl('/login');
                this.loading = false;
              }
              else {
                this.router.navigateByUrl('/login');
                this.loading = false;
              }

            }, (error: any) => {

              if (error.status === 400) {
                this.setPasswordError = "Please enter valid username";
                this.loading = false;
              }
              else {
                this.setPasswordError = "";
                const translatedMessage = this.translate.instant('toastr.error');
                this.toastr.error(translatedMessage);
                this.loading = false;
              }

            }

          );
        } else {
          const translatedMessage = this.translate.instant('toastr.error');
          this.toastr.error(translatedMessage);
          this.loading = false;
        }
      }
    } else {
      this.loading = false;
    }
  }
  showPassword() {
    this.show_password = !this.show_password;
    this.show_eyepassword = !this.show_eyepassword;
  }

  showConfirmPassword() {
    this.show_confirmpassword = !this.show_confirmpassword;
    this.show_eyeconfirmpassword = !this.show_eyeconfirmpassword;
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
