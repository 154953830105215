import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecoverpasswordService {

  constructor(private router: Router, private http: HttpClient) { }
  resetpassword(ResetPassword: any) {

    return this.http.put(`${environment.userApiUrl}/User/Reset`,ResetPassword,{responseType: 'text'}).pipe(
      catchError(this.handleError)
    )
  }
  private handleError(error: HttpErrorResponse) {
    var errorMessage =JSON.parse(error.error);
    return throwError( errorMessage.message);
  }
}
