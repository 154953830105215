import { ToastrService } from 'ngx-toastr';
import { ChangePassword } from '@/Model/ChangePassword';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '@services/profile.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareimageService } from '@services/shareimage.service';
import { PasswordValidator } from './change-password.validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  changeobj: ChangePassword = new ChangePassword();
  public changePasswordForm: FormGroup;
  public loading = false;
  id: any;
  show_password: boolean = false;
  show_eyepassword: boolean = false;
  public FormSubmitted: boolean = false;
  show_newpassword: boolean = false;
  show_eyeNewpassword: boolean = false;
  show_confirmpassword: Boolean = false;
  show_eyeconfirmpassword: Boolean = false;
  languageName:any;
  constructor(
    private toastr: ToastrService,
    private profileservice: ProfileService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private shareimageService: ShareimageService,
    private formBuilder: FormBuilder,
    private translate:TranslateService
  ) {
    this.translate.setDefaultLang('en')
  }

  ngOnInit() {
    this.shareimageService.setPageName('Change Password');
    this.languageName = localStorage.getItem('language');
    if( this.languageName===null || this.languageName==='' || this.languageName===undefined ){
      localStorage.setItem('language', 'en');
      this.translate.use('en');
     }else{
      this.translate.use(this.languageName);
     }
    this.profileservice.getUserById().subscribe((data: any) => {
      if(data.isSuperAdmin==true){
        this.profileservice.getNotification().subscribe((data: any) => {
          this.shareimageService.setnotification(data)
          localStorage.removeItem('notificationDataUserId');
              localStorage.removeItem('notificationDataUserName');
        });
      }
    });

    this.changePasswordForm=this.formBuilder.group({
      oldpassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
        newpassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirmpassword: new FormControl('', [
          Validators.required,
        ]),
    },{
      validator :PasswordValidator("oldpassword","newpassword","confirmpassword")
    })
  }

  get funchangePasswordForm() {
    return this.changePasswordForm.controls;
  }
  showPassword() {
    this.show_password = !this.show_password;
    this.show_eyepassword = !this.show_eyepassword;
  }

  showNewPassword() {
    this.show_newpassword = !this.show_newpassword;
    this.show_eyeNewpassword = !this.show_eyeNewpassword;
  }

  showConfirmPassword() {
    this.show_confirmpassword = !this.show_confirmpassword;
    this.show_eyeconfirmpassword = !this.show_eyeconfirmpassword;
  }

  onCancelClick() {
    this.router.navigateByUrl('/dashboard');
  }
  onChangePassword() {
    this.FormSubmitted = true;
    this.SpinnerService.show();

    if (this.changePasswordForm.invalid) {
      this.SpinnerService.hide();
      return false;
    } else {
      this.id = localStorage.getItem('userId');
      this.changeobj.UserId = this.id;
      this.changeobj.oldpassword = this.funchangePasswordForm.oldpassword.value;
      this.changeobj.newpassword = this.funchangePasswordForm.newpassword.value;
      this.changeobj.confirmpassword =
        this.funchangePasswordForm.confirmpassword.value;

      if (this.changeobj.newpassword == this.changeobj.confirmpassword) {
        this.profileservice.ChangePassword(this.changeobj).subscribe(
          (data: any) => {
            setTimeout(() => {
              const translatedMessage = this.translate.instant('toastr.success.newpassword');
              this.toastr.success(translatedMessage);
              this.SpinnerService.show();
              localStorage.clear();
              this.router.navigateByUrl('');
              this.SpinnerService.hide();
            }, 1000);
          },
          (error: any) => {
            if(error.error==='Old password is incorrect'){
              const translatedMessage = this.translate.instant('toastr.error.oldpassword');
              this.toastr.error(translatedMessage);
            }else{
              const translatedMessage = this.translate.instant('toastr.error.error');
              this.toastr.error(translatedMessage);
            }
            this.SpinnerService.hide();
          }
        );
      } else {
        const translatedMessage = this.translate.instant('toastr.error.passwordmatch');
        this.toastr.error(translatedMessage);
        this.SpinnerService.hide();
      }
    }
  }
}
