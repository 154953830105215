<div class="container-fluid main-content">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-9 add-user">
      <span class="font-weight-600 text-23 text-16 add-user-font">{{
        "adduser.title" | translate
      }}</span>
    </div>
  </div>
  <div class="mobile-view-border">
    <form #form="ngForm" [formGroup]="userlistForm" (ngSubmit)="onSubmit()">
      <div class="contact-form">
        <div class="container">
          <div class="profile-form">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label for="exampleInputname" class="control-label text-14"
                  >{{ "adduser.firstname.label" | translate
                  }}<span class="required">*</span></label
                >
                <input
                  formControlName="firstName"
                  type="text"
                  class="form-control"
                  placeholder="{{
                    'adduser.firstname.placeholder' | translate
                  }}"
                  maxlength="15"
                  [focusMe]="true"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="f.firstName.errors?.pattern"
                >
                  {{ "validation.firstname.pattern" | translate }}
                </div>
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f.firstName.errors?.required"
                >
                  {{ "validation.firstname.required" | translate }}
                </div>
              </div>
              <br />
              <div class="col-md-1"></div>
              <div class="col-md-4 margin-email">
                <label class="control-label text-14"
                  >{{ "adduser.lastname.label" | translate
                  }}<span class="required">*</span></label
                >
                <div class="">
                  <input
                    formControlName="lastName"
                    type="text"
                    class="form-control"
                    placeholder="{{
                      'adduser.lastname.placeholder' | translate
                    }}"
                    maxlength="15"
                  />
                  <div
                    class="text-red text-12 font-weight-bold mt-1"
                    *ngIf="f.lastName.errors?.pattern"
                  >
                    {{ "validation.lastname.pattern" | translate }}
                  </div>

                  <div
                    class="text-red text-12 font-weight-bold mt-1"
                    *ngIf="isFormSubmitted && f.lastName.errors?.required"
                  >
                    {{ "validation.lastname.required" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4">
                <label class="control-label text-14">{{
                  "adduser.email.label" | translate
                }}</label
                ><input
                  formControlName="email"
                  type="email"
                  class="form-control"
                  placeholder="{{ 'adduser.email.placeholder' | translate }}"
                  maxlength="50"
                />
                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="isFormSubmitted && f.email.errors?.pattern"
                >
                  {{ "validation.email.pattern" | translate }}
                </div>
                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="userlistForm.get('email').errors?.emailTaken"
                >
                  {{ "validation.email.duplicate" | translate }}
                </div>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-4 margin-email">
                <label class="control-label text-14">
                  {{ "adduser.mobilenumber.label" | translate
                  }}<span class="required">*</span></label
                >

                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  formControlName="mobileNumber"
                  class="form-control"
                  placeholder="{{
                    'adduser.mobilenumber.placeholder' | translate
                  }}"
                  (keypress)="onInputKeyPress($event)"
                  #phoneInput
                  (input)="onInput($event)"
                />
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted && f.mobileNumber.errors?.required"
                >
                  {{ "validation.mobilenumber.required" | translate }}
                </div>
                <div
                  class="text-red text-12 font-weight-bold mt-1"
                  *ngIf="isFormSubmitted"
                >
                  <div *ngIf="validationerror === 'Invalid mobile number'">
                    {{ "validation.mobilenumber.pattern" | translate }}
                  </div>
                </div>
                <div class="text-red text-12 font-weight-bold" *ngIf="validationerror === 'Invalid number'">
                  {{ "validation.mobilenumber.pattern" | translate }}
                </div>
                <div class="text-red text-12 font-weight-bold">
                  {{ duplicateMobileNumber }}
                </div>
                <div
                  class="text-red text-12 font-weight-bold"
                  *ngIf="f.mobileNumber.errors?.pattern"
                >
                  {{ "validation.mobilenumber.pattern" | translate }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-4 checkbox-margin mb-3">
                <input
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  checked
                  formControlName="active"
                />&nbsp;
                <label
                  class="form-check-label text-14 active-font-weight"
                  for="flexCheckChecked"
                >
                  {{ "adduser.active" | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="mb-4 mt-3">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-11 mobile-button-padding desktop-button-padding">
        <button
          type="cancel"
          class="btn btn-colour-cancel mobile-btn-save-cancel"
          (click)="onCancelClick()"
        >
          {{ "adduser.button.cancel" | translate }}
        </button>
        <button
          type="submit"
          class="btn btn-colour mobile-btn-save"
          (click)="onSubmit()"
        >
          {{ "adduser.button.submit" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  class="store-list-popup"
  [baseZIndex]="10000"
></p-confirmDialog>
<ngx-spinner
  bdColor="rgba(202,202,202,0.8)"
  size="medium"
  color="#e72744"
  type="timer"
>
  <p style="font-size: 20px; color: #e72744">Please wait...</p>
</ngx-spinner>
