import { VerifyOTPService } from './../../services/verifyOTP.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verifyOTP',
  templateUrl: './verifyOTP.component.html',
  styleUrls: ['./verifyOTP.component.css']
})
export class VerifyOTPComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  OTPForm: FormGroup;
  public loading = false;
  submitted = false;
  UserId: any;
  errorMessage: string;
  public isFormSubmitted: boolean = false;
  languageName: string;
  selectedLanguage: any;
  languages = [{ id: 'en', language: 'English' },
  { id: 'mr', language: 'मराठी' },
  { id: 'hi', language: 'हिंदी' }]
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private verifyOTPService: VerifyOTPService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { this.translate.setDefaultLang('en'); }

  ngOnInit(): void {

    this.languageName = localStorage.getItem('language');
    if (this.languageName === null || this.languageName === '' || this.languageName === undefined) {
      localStorage.setItem('language', 'en');
      this.selectedLanguage = 'en';
      this.translate.use(this.selectedLanguage);
    }
    else {
      this.selectedLanguage = this.languageName;
    }
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );
    this.UserId = this.route.snapshot.params['id'];
    this.OTPForm = new FormGroup({
      OTP: new FormControl('', [Validators.required]),
    });

    this.OTPForm.controls['OTP'].valueChanges.subscribe(() => {
      // Clear all error messages
      this.errorMessage = '';
    });
  }
  get f() {

    return this.OTPForm.controls;
  }
  useLanguage(event: any) {

    localStorage.setItem('language', event.id);
    this.translate.use(event.id);
  }
  onInput(event: any) {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.data);
    if (!pattern.test(inputChar)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  }
  onSubmit() {

    this.isFormSubmitted = true;
    this.loading = true;
    this.submitted = true;
    if (this.OTPForm.invalid) {

      this.loading = false;
    }
    else {
      this.verifyOTPService.verifyOTP(this.OTPForm.value.OTP).subscribe((data: any) => {
        data = JSON.parse(data);
        localStorage.setItem('username', JSON.stringify(data.username));
        this.router.navigateByUrl("/reset-password");
        this.loading = false;
      }, (error: any) => {
        this.errorMessage = error;
        this.loading = false;
      }
      )

    }
  }
  resendOTP() {
    this.verifyOTPService.resendOTPForgotPassword(this.UserId).subscribe((data: any) => {
      this.errorMessage = data.message;
      this.loading = false;

    }, (error: any) => {
      this.errorMessage = error;
      this.loading = false;
    })
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }
}
