<div class="wrapper wrapper-H sidebar-fixed">
  <nav id="sidebar">
    <a
      class="brand-link"
      style="cursor: pointer; margin-top: -16px"
      [routerLink]="['/dashboard']"
    >
      <img src="assets\svgimages\logo icon.svg" class="MU-logo" />
      <img src="assets\svgimages\logo.svg" class="martsup-logo" />
    </a>

    <div class="sidebar menu-sidebar sidebar-menu">
      <nav class="mt-2 menu-hover" style="overflow-y: hidden">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <app-menu-item
            *ngFor="let item of menu"
            [menuItem]="item"
        
          ></app-menu-item>
        </ul>
        <ul
          class="nav nav-pills nav-sidebar flex-column sidebar-bottom-position"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <app-menu-item
            *ngFor="let item of menu2"
            [menuItem]="item"
           
          ></app-menu-item>
        </ul>
      </nav>
    </div>
  </nav>
</div>
